import produce from 'immer';
import { uniq } from 'lodash';
import toast from 'react-hot-toast';
import { atomFamily, useRecoilState } from 'recoil';
import { recoilPersist } from 'recoil-persist';
import { useCreateBlockMutation } from '../../../generated/graphql';
import { TComment, TPost } from '../../../types/gql-enhanced-types';
import useAuth from '../../auth/use-auth';

const { persistAtom } = recoilPersist();

const BlockState = atomFamily<
  {
    blockedUserIds?: string[];
    blockedPostIds?: string[];
    blockedCommentIds?: string[];
  },
  string | undefined | null
>({
  key: 'BlockState',
  default: {},
  effects: [persistAtom],
});

export default function useBlockState() {
  const [{ userId }] = useAuth();
  const [blockState, setBlockState] = useRecoilState(BlockState(userId));
  const { blockedCommentIds, blockedPostIds, blockedUserIds } = blockState;

  function filterBlockedPost(post: TPost | null) {
    const postId = post?.id;
    const creatorId = post?.creator?.data?.id;

    if (postId == null) return true;
    if (blockedPostIds?.includes(postId)) return false;
    if (creatorId == null) return true;
    if (blockedUserIds?.includes(creatorId)) return false;

    return true;
  }

  function filterBlockedComment(comment: TComment | null) {
    const postId = comment?.id;
    const creatorId = comment?.creator?.data?.id;

    if (postId == null) return true;
    if (blockedCommentIds?.includes(postId)) return false;
    if (creatorId == null) return true;
    if (blockedUserIds?.includes(creatorId)) return false;

    return true;
  }

  function blockPostId(id?: null | string) {
    if (id == null) return;

    setBlockState((st) =>
      produce(st, (dr) => {
        dr.blockedPostIds = uniq([...(dr.blockedPostIds || []), id]);
      }),
    );

    toast.success('차단되었습니다.');
  }

  const [createBlock] = useCreateBlockMutation();

  function blockUserId(id?: null | string) {
    if (id == null) return;

    setBlockState((st) =>
      produce(st, (dr) => {
        dr.blockedUserIds = uniq([...(dr.blockedUserIds || []), id]);
      }),
    );

    toast.success('차단되었습니다.');
    createBlock({
      variables: {
        data: {
          creator: userId,
          blockingUser: id,
        },
      },
    });
  }

  return [
    { blockedPostIds, blockedUserIds, blockState },
    {
      blockUserId,
      blockPostId,
      filterBlockedComment,
      filterBlockedPost,
      setBlockState,
    },
  ] as const;
}
