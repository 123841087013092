import classNames from 'classnames';
import { HTMLAttributes, memo } from 'react';

function Card({
  children,
  className,
  ...props
}: {
  img?: string;
  indiLabel?: string;
} & HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      className={classNames('card bg-base-100 flex p-3', className)}
      {...props}
    >
      {children}
    </div>
  );
}

export default memo(Card);
