import { useEffect } from 'react';
import { useBlocker } from 'react-router';

export function useNavBlocker({
  block,
  onBack,
}: {
  block?: boolean;
  onBack?: () => void;
}) {
  const shouldBlock = !!block && !!onBack;
  const blocker = useBlocker(shouldBlock);

  useEffect(() => {
    if (shouldBlock === true && blocker.state === 'blocked') {
      onBack?.();
    }

    blocker?.reset?.();
  }, [blocker.state, shouldBlock]);

  return { blocker } as const;
}
