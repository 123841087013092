import { memo } from 'react';

import { notNull } from '../../../utils/array';
import { isNotEmpty } from '../../../utils/boolean';
import { colorsOptions } from '../models/colors';
import Color from './Color';

function Colors(props: {
  colors?: string[];
  highlightColors?: string[];
  onDelete?: (key: string) => void;
  onSelect?: (key: string) => void;
  withoutContainer?: boolean;
}) {
  const {
    withoutContainer,
    highlightColors,
    colors: selectedColors,
    onDelete,
    onSelect,
  } = props;

  const hasHighlights = isNotEmpty(highlightColors);
  const list = colorsOptions
    .map((colorOption) => {
      if (
        selectedColors != null &&
        !selectedColors?.includes(colorOption.color)
      )
        return null;

      const highlighted = highlightColors?.includes(colorOption.color);

      return (
        <Color
          key={colorOption.color}
          color={colorOption.color}
          deleting={!!onDelete}
          highlighted={highlighted}
          blur={hasHighlights && !highlighted}
          onClick={() => {
            if (onDelete) {
              onDelete(colorOption.color);
            }
            if (onSelect) onSelect(colorOption.color);
          }}
        />
      );
    })
    .filter(notNull);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  if (withoutContainer) return <>{list}</>;

  return <div className='flex gap-3 flex-wrap'>{list}</div>;
}

export default memo(Colors);
