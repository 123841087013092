import { memo } from 'react';
import { useParams } from 'react-router';

import LoadingIcon from '../../components/loading/LoadingIcon';
import { useUserQuery } from '../../generated/graphql';
import ProfileView from './ProfileView';
import { consolidateUser } from './type';

function ProfileByIdPage() {
  const { username } = useParams();

  const { data: gqlData, loading } = useUserQuery({
    skip: !username,
    variables: {
      username: username!,
    },
  });

  const obj = gqlData?.userByUsername?.data;

  if (loading) {
    return (
      <div className='flex-center p-5'>
        <LoadingIcon />
      </div>
    );
  }

  return <ProfileView user={consolidateUser(obj)} />;
}

export default memo(ProfileByIdPage);
