import toast from 'react-hot-toast';
import useCopyClipboard from 'react-use-clipboard';

export function useShare() {
  const url = window.location.href;
  const [, setLinkCopied] = useCopyClipboard(url);

  async function shareLink() {
    if (navigator.canShare && navigator.share) {
      const shareData = {
        url: window.location.href,
      };
      const share = navigator.canShare(shareData);

      if (share) {
        await navigator.share(shareData);
      }
    }

    toast('주소가 복사되었습니다.');
  }
  return {
    url,
    setLinkCopied,
    shareLink,
  } as const;
}
