import { useEffect, useState } from 'react';

const defaultTop = 10;

const useScrollReactor = (
  { topY }: { topY: number } = { topY: defaultTop },
) => {
  const [isScrollingUp, setIsScrollingUp] = useState(false);
  const [isScrollingDown, setIsScrollingDown] = useState(false);
  const [isScrollAtTop, setIsScrollAtTop] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      const isTop = scrollTop < (topY || defaultTop);

      setIsScrollAtTop(isTop);

      if (scrollTop > 0) {
        setIsScrollingUp(scrollTop < (window.scrollY || 0));
        setIsScrollingDown(scrollTop > (window.scrollY || 0));
        window.scrollY = scrollTop;
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return { isScrollingUp, isScrollingDown, isScrollAtTop };
};

export default useScrollReactor;
