import classNames from 'classnames';
import { memo, useEffect, useRef, useState } from 'react';
import { SingleValue } from 'react-select';
import { useDebouncedCallback } from 'use-debounce';
import { useGlobalKeydown } from '../../common/hooks/use-global-keydown';
import { useAquaticsLazyQuery } from '../../generated/graphql';
import { getAqNeedValidationCondition } from '../../modules/aquatics/aquatics-query-params';
import useAuth from '../../modules/auth/use-auth';
import { notEmpty, notNull } from '../../utils/array';
import Loading from '../loading/Loading';
import { SelectOption } from './selector-types';

function AquaticsSearchInput({
  value,
  onChange,
  placeholder,
  onEnter,
}: {
  value?: SelectOption | null;
  onChange: (o: SingleValue<SelectOption>) => void;
  onEnter?: (v?: string | null) => void;
} & {
  placeholder?: string;
}) {
  const [{ userId }] = useAuth();

  const [fetchAquatics, { data, loading }] = useAquaticsLazyQuery({
    fetchPolicy: 'cache-first',
  });

  const [input, setInput] = useState(value?.label || '');
  const [open, setOpen] = useState(false);
  const [typing, setTyping] = useState(false);

  // when keyword changes in parent component
  useEffect(() => {
    if (value?.label !== input) {
      setInput(value?.label || '');
    }
  }, [value?.label]);

  const loadOptions = async (inputValue: string) => {
    if (!open) return;

    await fetchAquatics({
      variables: {
        filters: {
          name: {
            containsi: inputValue,
          },
          and: [
            {
              deleted: {
                eq: false,
              },
            },
            getAqNeedValidationCondition({ userId }),
          ],
        },
        pagination: {
          pageSize: 300,
        },
      },
    });

    setTyping(false);
  };

  const list =
    data?.aquatics?.data
      .map((tr) => {
        const name = tr.attributes?.name;
        const label = `${name}`;

        const nValue = tr.id;
        if (!nValue || !label) return null;
        if (label) {
          return {
            value: nValue,
            label,
          };
        }
        return null;
      })
      .filter(notNull) || [];

  const loadSuggestions = useDebouncedCallback(loadOptions, 700);

  useEffect(() => {
    if (!open) return;

    setTyping(true);
    loadSuggestions(input);
  }, [open, input]);

  const ref = useRef(null);
  useGlobalKeydown(
    {
      ref,
      onClickOutside: () => {
        setOpen(false);
      },
    },
    [],
  );

  const typingOrLoading = typing || loading;

  return (
    <div ref={ref} className='w-full relative'>
      <input
        type='text'
        className={classNames(
          'input w-full border rounded-full',
          typingOrLoading && 'pr-6',
        )}
        value={input || ''}
        onChange={(e) => {
          const nValue = e.target.value;
          onChange({ value: nValue, label: nValue });

          setInput(e.target.value);
        }}
        onFocus={() => {
          setOpen(true);
        }}
        placeholder={placeholder}
        onKeyDown={(e) => {
          if (e.code === 'Enter' || e.keyCode === 13) {
            // @ts-ignore
            const nValue = e.target?.value || '';
            setInput(nValue);
            setOpen(false);

            onEnter?.(nValue);
          }
        }}
      />
      {typingOrLoading && (
        <div className='absolute h-0 w-0 top-4 right-8'>
          <Loading />
        </div>
      )}

      <div className='absolute top-[3.1rem] z-10 w-full'>
        {open && notEmpty(list) && (
          <div
            className={classNames(
              'menu bg-base-200 w-full rounded-box',
              'max-h-[22rem] overflow-y-auto flex-nowrap',
            )}
          >
            {list.map((i) => {
              return (
                <div
                  key={i.value}
                  className='block p-2 cursor-pointer'
                  onClick={() => {
                    setOpen(false);
                    setInput(i.label);
                    onChange(i);
                  }}
                >
                  {i.label}
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}

export default memo(AquaticsSearchInput);
