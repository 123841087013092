import { ApolloCache } from '@apollo/client';
import produce from 'immer';
import { CommentFragment, CommentFragmentDoc } from '../../generated/graphql';
import { Id } from '../users/type';

export function increaseCommentCount({
  commentId,
  cache,
}: {
  commentId: Id;
  // eslint-disable-next-line
  cache: ApolloCache<any>;
}) {
  const frag = cache.readFragment<CommentFragment>({
    id: `CommentEntity:${commentId}`,
    fragment: CommentFragmentDoc,
    fragmentName: 'Comment',
  });

  const newFrag = produce(frag, (draft) => {
    if (draft?.attributes?.commentCount != null) {
      draft.attributes.commentCount++;
    }
  });

  cache.writeFragment({
    id: `CommentEntity:${commentId}`,
    data: newFrag,
    fragment: CommentFragmentDoc,
    fragmentName: 'Comment',
  });
}
