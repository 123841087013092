export type RegionOption = { list: string[]; title?: string };
export const RegionOptions: RegionOption[] = [
  {
    list: ['전국'],
  },
  {
    title: '서울 한강 남쪽',
    list: [
      '강남구',
      '송파구',
      '서초구',
      '강동구',
      '성동구',
      '마포구',
      '서대문구',
      '중구',
      '종로구',
      '용산구',
      '동작구',
    ],
  },
  {
    title: '서울 한강 북쪽',
    list: [
      '강북구',
      '성북구',
      '도봉구',
      '노원구',
      '은평구',
      '양천구',
      '강서구',
      '구로구',
      '금천구',
      '관악구',
      '영등포구',
      '중랑구',
      '광진구',
      '동대문구',
    ],
  },
  {
    title: '경기',
    list: [
      '인천시',
      '수원시',
      '성남시',
      '고양시',
      '용인시',
      '부천시',
      '안산시',
      '안양시',
      '평택시',
      '시흥시',
      '광명시',
      '광주시',
      '의정부시',
      '하남시',
      '오산시',
      '구리시',
      '남양주시',
      '안성시',
      '포천시',
      '의왕시',
      '이천시',
      '양주시',
      '여주시',
      '연천군',
      '가평군',
      '양평군',
    ],
  },
  {
    title: '영남권',
    list: ['부산', '대구', '울산', '경상북도', '경상남도'],
  },
  {
    title: '호남권',
    list: ['광주', '전라북도', '전라남도'],
  },
  {
    title: '중부권',
    list: ['대전', '세종'],
  },
  {
    title: '기타',
    list: ['제주'],
  },
];
