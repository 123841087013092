/* eslint-disable @typescript-eslint/no-explicit-any */
import { first, size, trim } from 'lodash';
import appConfig from '../../app/appConfig';
import { isNotEmpty } from '../../utils/boolean';
import { toValidNumber } from '../../utils/number';

const { pageSize } = appConfig.post;

export function getTradeQueryParams({
  creatorId,
  keyword,
  ownerId,
  regions,
}: {
  creatorId?: string | null;
  ownerId?: string | null;
  keyword?: string | null;
  regions?: string[] | null;
} = {}) {
  const variables = {
    sort: ['createdAt:DESC', 'id:DESC'],
    pagination: {
      page: 1,
      pageSize,
    },
    filters: {
      and: [{ deleted: { eq: false } }, { trades: { id: { gt: 0 } } }],
    } as any,
  };

  if (
    isNotEmpty(regions) &&
    // omit '전국' when regions has more than one element
    !(size(regions) === 1 && first(regions) === '전국')
  ) {
    variables.filters.and.push({
      or: [...regions, '전국'].map((r) => ({
        trades: {
          regionsJsonStr: {
            contains: JSON.stringify(r),
          },
        },
      })),
    });
  }

  // user can see their own posting when it is not validated
  if (creatorId) {
    variables.filters.aquatic = {
      and: [
        {
          or: [
            {
              and: [
                { needValidation: { ne: false } },
                { creator: { id: { eq: creatorId } } },
              ],
            },
            {
              needValidation: { eq: false },
            },
          ],
        },
      ],
    };
  } else {
    variables.filters.aquatic = {
      and: [
        {
          needValidation: { eq: false },
        },
      ],
    };
  }

  if (ownerId) {
    variables.filters.creator = {
      id: {
        eq: ownerId,
      },
    };
  }

  if (isNotEmpty(keyword)) {
    variables.filters.or = [];

    variables.filters.or.push({
      aquatic: {
        name: {
          contains: trim(keyword),
        },
      },
    });

    const keywordIsNum = toValidNumber(keyword);
    if (keywordIsNum && keywordIsNum > 0) {
      variables.filters.or.push({
        price: {
          eq: keywordIsNum,
        },
      });
    }
  }

  return variables;
}
