import classNames from 'classnames';
import produce from 'immer';
import { uniq } from 'lodash';
import { memo, useEffect } from 'react';
import { MdChevronLeft } from 'react-icons/md';
import { useNavigate } from 'react-router';
import { useInterval } from 'usehooks-ts';
import appConfig from '../../app/appConfig';
import useAppConfig from '../../app/use-app-configs';
import { useAppRouter } from '../../common/hooks/use-app-router';
import useSearchState from '../../common/hooks/use-search-state';
import {
  Enum_Post_Category,
  usePartnerPostsQuery,
} from '../../generated/graphql';
import { notEmpty } from '../../utils/array';
import { usePartnerPostState } from './hooks/use-partner-post-state';
import PostList from './PostList';

function PostListPage({ category }: { category?: Enum_Post_Category }) {
  const nav = useNavigate();

  // fetch partner posts
  const { tryRefetchPP, resetRandomSample } = usePartnerPostState();
  useEffect(() => {
    tryRefetchPP();
  }, []);

  // randomize PP
  useInterval(() => {
    resetRandomSample();
  }, appConfig.post.ppInterval);

  usePartnerPostsQuery({
    fetchPolicy: 'cache-first',
  });

  const [{ navBoardConfigs, boardConfigs, paramBoardConfig }] = useAppConfig();
  const {
    isPostHome,
    params: { boardType },
  } = useAppRouter();

  const homeTags = boardConfigs.reduce((acc, cur) => {
    return uniq([...acc, ...(cur.tags || [])]);
  }, [] as string[]);
  const currPageTags = isPostHome ? homeTags : paramBoardConfig?.tags || [];

  const [
    {
      searchState: { openTags },
      selectedTag,
    },
    { setSearchState },
  ] = useSearchState({ boardConfig: paramBoardConfig });

  return (
    <div className='flex-1 flex-col'>
      <div
        className={classNames(
          'bg-white px-4 py-3 flex gap-3 overflow-x-auto flex-nowrap ',
          'shadow-lg',
        )}
      >
        {navBoardConfigs.map((boardConfig) => {
          const selected =
            (isPostHome && boardConfig.id === 'all') ||
            boardType === boardConfig.id;
          return (
            <div
              key={boardConfig.id}
              className={classNames(
                'flex-center flex-col gap-1 cursor-pointer',
                selected ? 'opacity-100 font-bold' : 'opacity-60',
              )}
              onClick={() => {
                nav(boardConfig.navTo || `/posts/${boardConfig.id}`);
              }}
            >
              {/* <StringIcons name={boardConfig.icon} /> */}
              <div className='whitespace-nowrap text-sm'>
                {boardConfig.name}
              </div>
            </div>
          );
        })}
      </div>
      <div className='h-2'></div>

      <div className='bg-white pt-3 flex-col'>
        {notEmpty(currPageTags) && (
          <>
            <div className='flex px-3'>
              <span className='font-bold text-lg'>
                #{selectedTag || '전체'}
              </span>
              <span
                className='flex-center cursor-pointer'
                onClick={() => {
                  setSearchState((st) =>
                    produce(st, (dr) => {
                      dr.openTags = !dr.openTags;
                    }),
                  );
                }}
              >
                <MdChevronLeft
                  className={classNames(openTags ? 'rotate-90' : '-rotate-90')}
                  size={27}
                />
              </span>
            </div>
            {openTags && (
              <div className='overflow-x-auto'>
                <div className='overflow-x-auto px-3 no-scrollbar py-2 w-full max-w-[43rem]'>
                  {currPageTags.map((tag) => {
                    const selected = tag === selectedTag;
                    return (
                      <span
                        key={tag}
                        className={classNames(
                          'badge badge-lg cursor-pointer font-semibold whitespace-nowrap mb-2 mr-2',
                          selected
                            ? 'badge-primary'
                            : 'bg-primary bg-opacity-10',
                        )}
                        onClick={() => {
                          setSearchState((st) =>
                            produce(st, (dr) => {
                              if (!dr.boardTagMap) dr.boardTagMap = {};

                              if (selected) {
                                dr.boardTagMap[paramBoardConfig?.id || 'all'] =
                                  {
                                    tags: [],
                                  };
                                return;
                              }

                              dr.boardTagMap[paramBoardConfig?.id || 'all'] = {
                                tags: [tag],
                              };
                            }),
                          );
                        }}
                      >
                        {tag}
                      </span>
                    );
                  })}
                </div>
              </div>
            )}
          </>
        )}
      </div>

      <PostList category={category} />

      <div className='h-36'></div>
    </div>
  );
}

export default memo(PostListPage);
