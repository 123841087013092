import appConfig from '../../app/appConfig';
import { CommentsQueryVariables } from '../../generated/graphql';
import { TPost } from '../../types/gql-enhanced-types';

const { pageSize } = appConfig.comment;
const { pageSize: replyPageSize } = appConfig.reply;

export type CommentTarget = 'post' | 'qna' | 'trade' | 'opinion';

export function getTargetId({
  target,
  post,
}: {
  target: CommentTarget;
  post?: TPost;
}) {
  if (!post?.id) return '';

  return `${target}-${post?.id}`;
}

export function getTopCommentQueryParams({
  targetId,
}: {
  targetId?: string | null;
} = {}) {
  return {
    filters: {
      targetId: {
        eq: targetId,
      },
      commentTo: {
        id: {
          null: true,
        },
      },
      deleted: {
        eq: false,
      },
    },
    sort: ['likeCount:DESC', 'createdAt:ASC'],
    pagination: {
      page: 1,
      pageSize: pageSize + 1,
    },
  };
}

export function getRepliesQueryParams({
  commentId,
}: {
  commentId?: string | null;
  sort?: CommentsQueryVariables['sort'];
  pagination?: CommentsQueryVariables['pagination'];
} = {}) {
  return {
    filters: {
      commentTo: {
        id: {
          eq: commentId,
        },
      },
    },
    sort: ['createdAt:ASC', 'id:DESC'],
    pagination: {
      page: 1,
      pageSize: replyPageSize + 1,
    },
  };
}
