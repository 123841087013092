import { values } from 'lodash';
import { FieldError, Merge } from 'react-hook-form';
import toast from 'react-hot-toast';

export const toastNested = (
  errors: (FieldError | Merge<FieldError, (FieldError | undefined)[]>)[],
) => {
  errors.forEach((msg) => {
    if (typeof msg === 'object') {
      if (msg.message) {
        toast.error(msg.message);
      }
      values(msg).forEach((msg2) => {
        const mm = msg2 as { message?: string };
        if (mm.message) {
          toast.error(mm.message);
        }
        values(mm).forEach((msg3) => {
          const mmm = msg3 as { message?: string };
          if (mmm.message) {
            toast.error(mmm.message);
          }
        });
      });
    }
  });
};
