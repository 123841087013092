import { useState } from 'react';
import ImgAvatar from '../../components/ImgAvatar';
import useAuth from '../auth/use-auth';
import SignInModal from './SignInModal';

function Auth() {
  const [{ isLoggedIn, user }, { nav }] = useAuth();
  const [open, setOpen] = useState(false);

  return (
    <>
      {/* mobile gnb */}
      {isLoggedIn ? (
        <div className='flex-auto flex gap-4 w-full justify-end items-start py-2 px-2 pointer-events-auto bg-base-100'>
          <ImgAvatar
            key={user?.profileUrl}
            img={user?.profileUrl}
            className='w-9 h-9'
            onClick={() => {
              nav('/profile');
            }}
          />
        </div>
      ) : (
        <div className='flex justify-center p-3 gap-2'>
          <button
            // id='join-beta'
            className='btn btn-primary'
            onClick={() => {
              setOpen(true);
            }}
          >
            로그인
          </button>
        </div>
      )}

      {open && <SignInModal setOpen={setOpen} />}
    </>
  );
}

export default Auth;
