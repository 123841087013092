import produce from 'immer';
import { isEmpty } from 'lodash';
import { useEffect } from 'react';
import { useParams } from 'react-router';
import { atom, useRecoilState } from 'recoil';
import { useAppRouter } from '../common/hooks/use-app-router';
import { Enum_Post_Category } from '../generated/graphql';

type PostCategory = `${Enum_Post_Category}` | 'all';

export type BoardConfig = {
  id: PostCategory;
  type?: string;
  name: string;
  icon?: string;
  tags?: string[];
  minImageCount?: number;
  enabled?: boolean;
  nav?: boolean;
  navTo?: string;
};

export type BoardConfigs = BoardConfig[];

const boardConfigs: BoardConfigs = [
  {
    id: 'qna',
    name: '물어보기',
    tags: [],
  },
  {
    id: 'main',
    name: '자유게시판',
    tags: [],
  },
  {
    id: 'event',
    name: '이벤트',
    tags: [],
  },
];

const AppConfigState = atom<{
  authLoading?: boolean;
  authInit?: boolean;
  modals: string[];
  boardConfigs: BoardConfigs;
}>({
  key: 'AppConfigState',
  default: {
    authInit: false,
    authLoading: false,
    modals: [],
    boardConfigs,
  },
});

export default function useAppConfig({
  boardConfigId,
}: { boardConfigId?: PostCategory } = {}) {
  const [appConfig, setAppConfig] = useRecoilState(AppConfigState);
  const { isPostHome } = useAppRouter();

  function closeModalWithKey(key: string) {
    setAppConfig(
      produce(appConfig, (draft) => {
        draft.modals = draft.modals.filter((k) => k !== key);
      }),
    );
  }

  function openModalWithKey(key: string) {
    setAppConfig(
      produce(appConfig, (draft) => {
        draft.modals.push(key);
      }),
    );
  }

  function switchScroll(on: boolean) {
    if (on) {
      document.body.classList.add('h-[2000rem]');
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('h-[2000rem]');
      document.body.classList.remove('overflow-hidden');
    }
  }
  // prevent scrolling when needed
  useEffect(() => {
    const hasOpenModal = !isEmpty(appConfig.modals);

    switchScroll(hasOpenModal);
  }, [appConfig.modals]);

  const { boardType: tBoardType } = useParams();
  const boardType = isPostHome ? 'all' : tBoardType;

  function findBoardConfig(id: PostCategory) {
    return appConfig?.boardConfigs?.find((c) => c.id === id);
  }

  const paramBoardConfig = appConfig.boardConfigs?.find(
    (c) => c.id === boardType,
  );

  return [
    {
      boardConfigs: appConfig.boardConfigs,
      enabledBoardConfigs: appConfig.boardConfigs?.filter((c) => c.enabled),
      navBoardConfigs: appConfig.boardConfigs?.filter((c) => c.nav),
      appConfig,
      paramBoardType: boardType,
      paramBoardConfig,
      // manual selection first and fallback to url param
      boardConfig: boardConfigId
        ? findBoardConfig(boardConfigId)
        : paramBoardConfig,
    },
    {
      setAppConfig,
      openModalWithKey,
      closeModalWithKey,
      findBoardConfig,
    },
  ] as const;
}
