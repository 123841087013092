import { useEffect, useState } from 'react';

export function getScreenNameByWidth(width: number) {
  if (width >= 1536) {
    return '2xl';
  }
  if (width >= 1280) {
    return 'xl';
  }
  if (width >= 1024) {
    return 'lg';
  }
  if (width >= 768) {
    return 'md';
  }
  return 'sm';
}

export function useUiVars() {
  const [screen, setScreen] = useState('');

  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    setScreen(getScreenNameByWidth(window.innerWidth));
  }, [width]);

  return {
    screen,
    isMobile: screen === 'sm',
  };
}
