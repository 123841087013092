import produce from 'immer';
import { first } from 'lodash';
import {
  PostFragment,
  PostFragmentDoc,
  useCreateLikeMutation,
  useUpdateLikeMutation,
} from '../../generated/graphql';
import { TPost } from '../../types/gql-enhanced-types';

export function usePostLike({ post }: { post: TPost | null }) {
  const [updateLike, { loading: updating }] = useUpdateLikeMutation({
    update(cache, { data: mutData }) {
      const frag = cache.readFragment<PostFragment>({
        id: `PostEntity:${post?.id}`,
        fragment: PostFragmentDoc,
        fragmentName: 'Post',
      });

      const addingLike = !!mutData?.updateLike?.data?.attributes?.liked;

      const newFrag = produce(frag, (draft) => {
        if (draft?.attributes?.MyLikes?.data && mutData?.updateLike?.data) {
          draft.attributes.MyLikes.data = [mutData?.updateLike?.data];
        }

        if (draft?.attributes?.likeCount != null) {
          if (addingLike) {
            draft.attributes.likeCount++;
          } else {
            draft.attributes.likeCount--;
          }
        }
      });

      cache.writeFragment({
        id: `PostEntity:${post?.id}`,
        data: newFrag,
        fragment: PostFragmentDoc,
        fragmentName: 'Post',
      });
    },
  });

  const [createLike, { loading: creating }] = useCreateLikeMutation({
    update(cache, { data: mutData }) {
      const frag = cache.readFragment<PostFragment>({
        id: `PostEntity:${post?.id}`,
        fragment: PostFragmentDoc,
        fragmentName: 'Post',
      });

      const addingLike = !!mutData?.createLike?.data?.attributes?.liked;

      const newFrag = produce(frag, (draft) => {
        if (draft?.attributes?.MyLikes?.data && mutData?.createLike?.data) {
          draft.attributes.MyLikes.data = [mutData?.createLike?.data];
        }

        if (draft?.attributes?.likeCount != null) {
          if (addingLike) {
            draft.attributes.likeCount++;
          } else {
            draft.attributes.likeCount--;
          }
        }
      });

      cache.writeFragment({
        id: `PostEntity:${post?.id}`,
        data: newFrag,
        fragment: PostFragmentDoc,
        fragmentName: 'Post',
      });
    },
  });

  const likeLoading = creating || updating;

  const myLikeData = first(post?.MyLikes?.data);
  const likeId = myLikeData?.id;
  const liked = !!likeId && !!myLikeData?.attributes?.liked;

  return [
    { myLikeData, likeLoading, liked, likeId },
    { updateLike, createLike },
  ] as const;
}
