import classNames from 'classnames';
import produce from 'immer';
import { first, isEmpty, size } from 'lodash';
import { memo } from 'react';
import { MdClose } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { Waypoint } from 'react-waypoint';
import appConfig from '../../app/appConfig';
import smallFish from '../../assets/aquatics/small-fish.png';
import useSearchState from '../../common/hooks/use-search-state';
import LoadingIcon from '../../components/loading/LoadingIcon';
import SkelPostList from '../../components/skeleton/SkelPostList';
import { useAquaticsQuery } from '../../generated/graphql';
import { usePageLoadMore } from '../../graphql/use-page-load-more';
import { FormatsEnum } from '../../types';
import { convAquatic } from '../../types/gql-enhanced-types';
import { strToArr } from '../../utils/array';
import { isNotEmpty } from '../../utils/boolean';
import { getSizedMediaLink } from '../../utils/image';
import useAuth from '../auth/use-auth';
import { getAquaticsQueryParams } from './aquatics-query-params';
import Color from './components/Color';
import Colors from './components/Colors';
import { sizeOptions } from './models/size';

const { pageSize } = appConfig.aquatic;

function AquaticsListPage() {
  const [{ userId }] = useAuth();
  const [{ searchState }, { setSearchState }] = useSearchState();

  const pMap = searchState.searchMap;

  const searchKeys = isNotEmpty(searchState.searchMap?.keywords)
    ? searchState.searchMap?.keywords
    : [];

  const { color, size: pSize } = pMap;

  const variables = getAquaticsQueryParams({
    size: pSize,
    color,
    keywords: searchKeys,
    userId,
  });

  const { data, loading, fetchMore } = useAquaticsQuery({
    variables,
  });

  const items = data?.aquatics?.data?.map(convAquatic) || [];

  const [{ hasMore, loadingMore }, { loadMore }] = usePageLoadMore({
    fetchMore,
    loading,
    pageSize,
    listLength: size(items),
  });

  return (
    <div className='flex-1 flex-col'>
      <div className='px-3'>
        {(!isEmpty(pSize) || !isEmpty(color)) && (
          <div className='flex gap-2 py-2 flex-wrap pb-4 justify-end'>
            <Colors
              withoutContainer
              colors={color ? [color] : []}
              onDelete={() => {
                setSearchState((st) =>
                  produce(st, (dr) => {
                    delete dr.searchMap.color;
                  }),
                );
              }}
            />

            {sizeOptions.map((so) => {
              if (so.value !== pSize) return null;
              return (
                <span
                  key={so.value}
                  className={classNames(
                    'font-bold flex-center rounded-full px-3 h-9 border border-black shadow-md cursor-pointer',
                  )}
                  onClick={() => {
                    setSearchState((st) =>
                      produce(st, (dr) => {
                        delete dr.searchMap.size;
                      }),
                    );
                  }}
                >
                  {so.label}
                  <MdClose size={18} />
                </span>
              );
            })}
          </div>
        )}
      </div>

      {loading && <SkelPostList />}

      {!loading && isEmpty(items) && (
        <div className='flex-center h-full w-full min-h-[10rem]'>결과 없음</div>
      )}

      <ul className='pb-6 flex-col'>
        {!loading &&
          items.map((aq) => {
            const item = aq;

            const firstImgFormat = first(item?.images?.data);
            const thumbImage = getSizedMediaLink({
              upload: firstImgFormat,
              size: FormatsEnum.thumbnail,
            });

            const otherNames = strToArr(item?.otherNames);

            return (
              <Link key={aq?.id} to={`/pet/aquatics/${item?.id}`}>
                <li className='p-2 flex gap-2 bg-base-100 shadow border-t'>
                  <div className='flex-none'>
                    <div className='avatar'>
                      <div className='w-16 mask mask-squircle'>
                        {thumbImage ? (
                          <img src={thumbImage} className='' />
                        ) : (
                          <img
                            src={smallFish}
                            className='opacity-40 bg-primary bg-opacity-40 p-4'
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className='flex-1'>
                    <div className='flex justify-between'>
                      <span>{item?.name}</span>
                      <span className='flex flex-nowrap gap-1'>
                        <Color
                          color={first(item?.colors)}
                          noLabel
                          className='w-5 h-5 border-[1px] border-gray-300'
                        />
                      </span>
                    </div>
                    <div className='flex gap-5 justify-between'>
                      <div className='opacity-40 font-semibold'>
                        {otherNames.join(', ')}
                      </div>
                      <div className='flex-1 flex justify-end flex-nowrap whitespace-nowrap'>
                        {item?.sizeCm != null && item.sizeCm > 0 && (
                          <span className='flex-nowrap whitespace-nowrap'>
                            {item?.sizeCm} cm
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </li>
              </Link>
            );
          })}

        {!isEmpty(items) && (loading || loadingMore) && (
          <div className='flex-center-x p-10'>
            <LoadingIcon />
          </div>
        )}

        {hasMore && (
          <Waypoint
            scrollableAncestor='window'
            onEnter={() => {
              loadMore();
            }}
          ></Waypoint>
        )}
      </ul>

      <div className='h-36'></div>
    </div>
  );
}

export default memo(AquaticsListPage);
