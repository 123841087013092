import { memo } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useHomePageQuery } from '../../generated/graphql';
import {
  convAquatic,
  convList,
  convToRender,
} from '../../types/gql-enhanced-types';

import { startsWith } from 'lodash';
import { useNavigate } from 'react-router';
import 'swiper/css';
import 'swiper/css/pagination';
import { FormatsEnum } from '../../types';
import { notEmpty } from '../../utils/array';
import { isNotEmpty } from '../../utils/boolean';
import { getSizedMediaLink } from '../../utils/image';
import InlineGallery from '../post/InlineGallery';
import PostListItem from '../post/PostListItem';
import useBlockState from '../report/hooks/use-block-state';
import './home-page.scss';

function HomePage() {
  const nav = useNavigate();
  const { data: homePageData, loading: loadingHome } = useHomePageQuery({
    fetchPolicy: 'cache-first',
  });

  const [, { filterBlockedPost }] = useBlockState();

  const homePage = convToRender(homePageData?.homePage?.data);
  const posts = convList(homePage?.trendingPosts?.data)
    .filter(filterBlockedPost)
    .filter((i) => !i.deleted);

  const aquatic = convAquatic(homePage?.aquatics?.data?.[0]);

  const ads = convList(homePageData?.liveAds.data);

  if (loadingHome) {
    return (
      <div className='z-0 flex-col flex-1 gap-4 px-5 py-5'>
        <Skeleton count={1} width={'40%'} />

        <div className='flex gap-5'>
          <div className='flex-1'>
            <Skeleton className='flex-1' width={'100%'} height={'130px'} />
          </div>
          <div className='flex-1'>
            <Skeleton className='flex-1' width={'100%'} height={'130px'} />
          </div>
        </div>
        <div className='flex-col gap-6'>
          <div className=''>
            <Skeleton count={3} />
          </div>
          <div className=''>
            <Skeleton count={4} />
          </div>
          <div className=''>
            <Skeleton count={1} />
          </div>
          <div className=''>
            <Skeleton count={2} />
          </div>
          <div className=''>
            <Skeleton count={4} />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className='flex-col flex-1 gap-5 py-5'>
      {aquatic && (
        <>
          <div className='flex-col gap-4 px-5'>
            <div
              className='flex-col'
              onClick={() => {
                nav(`/pet/aquatics/${aquatic?.id}`);
              }}
            >
              <span className='flex gap-2 text-sm'>
                🐠 <span>{' 오늘의 물고기 '}</span>
              </span>
              <span className='flex-1 text-xl font-semibold'>
                {aquatic.name}
              </span>
            </div>
            <div className='overflow-hidden rounded-xl'>
              {aquatic.images?.data && (
                <InlineGallery
                  images={aquatic.images.data}
                  autoHeight
                  onClick={() => {
                    nav(`/pet/aquatics/${aquatic?.id}`);
                  }}
                />
              )}
            </div>
          </div>
          <hr />
        </>
      )}

      {/* TRENDING POSTS */}
      {notEmpty(posts) && (
        <div className='flex-col gap-2'>
          <div className='flex items-end px-4 text-2xl'>
            <span className='flex-1 text-xl font-semibold'>트렌딩</span>
            <span className='flex gap-2'></span>
          </div>

          <div className='px-2'>
            {posts.map((post) => {
              return <PostListItem key={post.id} post={post} />;
            })}
          </div>
        </div>
      )}

      {/* BANNERS */}
      {isNotEmpty(ads) && (
        <div className='flex-col gap-5 px-5 '>
          {/* <div className='flex items-end text-2xl'>
            <span className='flex-1 text-xl font-semibold'>협력 업체</span>
            <span className='flex gap-2'></span>
          </div> */}

          <Swiper
            slidesPerView={1.1}
            spaceBetween={12}
            loop
            pagination={{
              clickable: true,
            }}
            modules={[Pagination, Navigation]}
            className='banner-ad-home'
            autoplay={{
              delay: 6000,
              disableOnInteraction: true,
              pauseOnMouseEnter: true,
            }}
          >
            {ads.map((ad) => {
              const img = getSizedMediaLink({
                upload: ad.banner1?.data,
                size: FormatsEnum.medium,
              });
              if (!img) return null;

              return (
                <SwiperSlide key={ad.id}>
                  <img
                    src={img}
                    className='cursor-pointer'
                    onClick={() => {
                      if (ad.link1) {
                        if (startsWith(ad.link1, 'http')) {
                          window.open(ad.link1, '_blank');
                        } else {
                          nav(ad.link1);
                        }
                      }
                    }}
                  />
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      )}
      <div className='h-36'></div>
    </div>
  );
}

export default memo(HomePage);
