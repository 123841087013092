/* eslint-disable no-nested-ternary */
import classNames from 'classnames';
import { endsWith, isEmpty, values } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import Dropzone from 'react-dropzone';
import { FaPlus } from 'react-icons/fa';
import { MdArrowBack } from 'react-icons/md';

import { useFieldArray, useForm } from 'react-hook-form';
import { IoMdCloseCircle } from 'react-icons/io';
import { toast } from 'react-toastify';
import { useModalManage } from '../../common/hooks/use-modal-manage';
import Textarea from '../../components/form/Textarea';
import Modal from '../../components/Modal';
import AquaticsSelector from '../../components/selector/AquaticsSelector';
import RegionSelector from '../../components/selector/RegionSelector';
import {
  Enum_Post_Category,
  useCreateTradePostMutation,
  useUpdateTradePostMutation,
  useUploadMutation,
} from '../../generated/graphql';
import { convToRender, TTrade } from '../../types/gql-enhanced-types';
import { notEmpty, notNull } from '../../utils/array';
import { getMediaUrl } from '../../utils/image';
import { toValidNumber } from '../../utils/number';
import useAuth from '../auth/use-auth';

type FormData = {
  postData: {
    id?: null | string | undefined;
    desc: string | null | undefined;
    images: (File | string)[];
    previews: string[];
    category?: Enum_Post_Category;
  };
  trade: {
    regionsChecked?: boolean;
    regionsJsonStr?: string | null;
  };
  trades: {
    aquatic?: null | string | undefined;
    name?: string | null;
    price?: string;
    quantity?: string;
  }[];
};

function ModalTradePostForm({
  trade,
  onClose,
  onSuccess,
  category,
}: {
  trade?: TTrade;
  onClose: () => void;
  onSuccess?: () => void;
  category?: Enum_Post_Category;
}) {
  const [{ userId: creatorId }] = useAuth();

  const post = convToRender(trade?.post?.data);
  const isEdit = !!trade;

  useModalManage({
    modalKey: 'trade-create',
    onBlocked: () => {
      onClose();
    },
  });

  const initData: FormData = useMemo(() => {
    if (!post?.id)
      return {
        postData: {
          id: '',
          desc: '',
          images: [],
          previews: [],
          category: category || Enum_Post_Category.Trade,
        },
        trade: {
          regionsJsonStr: '',
          regionsChecked: false,
        },
        trades: [],
      };

    const imagesIds = post?.images?.data.map((d) => d.id).filter(notNull) || [];
    const imagesData = post?.images?.data || [];
    const previews = imagesData
      .map((d) => getMediaUrl(d.attributes?.url))
      .filter(notNull);

    const trades = trade?.aquatic?.data?.id
      ? [
          {
            name: trade.aquatic?.data?.attributes?.name,
            aquatic: trade.aquatic?.data?.id,
            price: `${trade.price || ''}`,
            quantity: `${trade.quantity || ''}`,
          },
        ]
      : [];

    return {
      postData: {
        id: post.id || '',
        desc: post?.desc || '',
        images: imagesIds,
        previews,
      },
      trade: {
        regionsJsonStr: trade?.regionsJsonStr || '',
        regionsChecked: trade?.regionsChecked || false,
      },
      trades,
    };
  }, [trade]);

  const { handleSubmit, setValue, control, register, watch } =
    useForm<FormData>({
      defaultValues: initData,
    });

  const regionsJsonStr = watch('trade.regionsJsonStr');
  const regionsChecked = watch('trade.regionsChecked');

  const regions = useMemo<string[]>(() => {
    try {
      return JSON.parse(regionsJsonStr || '[]');
    } catch (error) {
      //
    }
    return [];
  }, [regionsJsonStr]);

  const { append, remove } = useFieldArray({
    control,
    name: 'trades',
    rules: {
      required: {
        value: true,
        message: '판매 어종을 선택해주세요.',
      },
    },
  });

  const postData = watch('postData');
  const trades = watch('trades');
  const { previews } = postData;

  const [saving, setSaving] = useState(false);
  const [upload] = useUploadMutation({
    onError() {
      toast.error('업로드가 실패했어요. 나중에 다시 시도해주세요.');
      setSaving(false);
    },
  });

  const [createTradePost] = useCreateTradePostMutation({
    refetchQueries: ['Posts', 'Trades'],
    onCompleted: () => {
      toast.success('등록완료');

      setSaving(false);

      if (onSuccess) {
        onSuccess?.();
      } else {
        onClose();
      }
    },
    onError() {
      toast.error('글 동록 실패. 나중에 다시 시도해주세요.');
      setSaving(false);
    },
  });

  const [updateTradePost] = useUpdateTradePostMutation({
    onCompleted: () => {
      toast.success('등록완료');
      setSaving(false);

      if (onSuccess) {
        onSuccess?.();
      } else {
        onClose();
      }
    },
    onError() {
      setSaving(false);
    },
  });

  const onDrop = useCallback(
    async (files: File[]) => {
      if (saving) return;

      const firstFile = files?.[0];

      if (firstFile) {
        if (endsWith(firstFile.name, 'gif')) {
          return;
        }

        setValue('postData.images', [firstFile]);
        setValue('postData.previews', [URL.createObjectURL(firstFile)]);
      }
    },
    [saving, setValue],
  );

  async function handleSave() {
    if (saving) return;

    const firstFile = postData.images?.[0];
    if (!firstFile) {
      toast.warn('이미지 없이는 올릴 수 없어요 ㅠ');
      return;
    }

    setSaving(true);

    let uploadId: string | undefined;

    if (typeof firstFile === 'object') {
      const { data: uploadData } = await upload({
        variables: {
          file: firstFile,
        },
      });

      uploadId = uploadData?.upload.data?.id as string;

      if (!uploadId) {
        toast.error('업로드 에러');
        setSaving(true);

        return;
      }

      // CACHE THE IMAGE UPLOADED
      if (uploadId != null) {
        setValue('postData.images', [uploadId]);
      }
    } else if (typeof firstFile === 'string') {
      uploadId = firstFile;
    }

    // updating
    if (postData.id) {
      await updateTradePost({
        variables: {
          id: postData.id,
          data: {
            images: uploadId ? [uploadId] : [],
            desc: postData?.desc,
            creator: creatorId,
          },
          trades: trades.map((trItem) => ({
            aquatic: trItem.aquatic,
            creator: creatorId,
            price: toValidNumber(trItem.price),
            quantity: toValidNumber(trItem.quantity),
            regionsChecked,
            regionsJsonStr,
          })),
        },
      });
      return;
    }

    // CREATING
    // create post with image id
    await createTradePost({
      variables: {
        data: {
          images: uploadId ? [uploadId] : [],
          desc: postData.desc,
          creator: creatorId,
          category: postData.category,
        },
        trades: trades.map((trItem) => ({
          aquatic: trItem.aquatic,
          price: toValidNumber(trItem.price),
          quantity: toValidNumber(trItem.quantity),
          regionsChecked,
          regionsJsonStr,
        })),
      },
    });
  }

  const imgSelected = !isEmpty(postData.images);

  return (
    <Modal open>
      <div className='flex py-2 flex-center-y'>
        <div className='flex-none w-0 overflow-visible z-40'>
          <button
            className='btn btn-ghost'
            onClick={() => {
              const yes = window.confirm(
                '작성중인 내용이 사라집니다. 나가시겠습니까?',
              );
              if (!yes) return;

              onClose();
            }}
          >
            <MdArrowBack size={32} />
          </button>
        </div>

        <div className='flex-1 text-center text-modal-title'>분양 글</div>

        <div className='flex-none w-0 overflow-visible z-40'>
          <div className='relative bg-red-300 h-[40px]'>
            <div className='absolute right-0 -top-1'>
              <button
                className='btn btn-ghost whitespace-nowrap btn-lg flex flex-col'
                onClick={() => {
                  handleSubmit(handleSave, (e) => {
                    values(e).forEach((msg) => {
                      if (typeof msg === 'object') {
                        if (msg.message) {
                          toast.error(msg.message);
                        }

                        values(msg).forEach((msg2) => {
                          const mm = msg2 as { message?: string };
                          if (mm.message) {
                            toast.error(mm.message);
                          }

                          values(mm).forEach((msg3) => {
                            const mmm = msg3 as { message?: string };
                            if (mmm.message) {
                              toast.error(mmm.message);
                            }
                          });
                        });
                      }
                    });
                  })();
                }}
              >
                {saving ? (
                  <span className='loading loading-spinner'></span>
                ) : isEdit ? (
                  '수정'
                ) : (
                  '동록'
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className='flex-1 px-4 flex-col gap-9 overflow-auto'>
        <div className='flex-col gap-3'>
          <label htmlFor=''>생물 이름 *</label>
          <div className='flex-col gap-2 text-sm'>
            {isEdit ? (
              <div className='opacity-60'>
                * 어종 수정은 불가합니다. 수량, 가격, 설명 만 가능.
              </div>
            ) : (
              <div className='opacity-60'>
                <span>
                  * 한 종류의 생물만 선택/등록 하세요. 여러 이름을 한 종류로
                  등록 시 제재 대상이 됩니다. <br />* 구매자들에게 알림도
                  보내지지 않습니다. (예: 디스커스와 코리도라스)
                </span>
              </div>
            )}

            <div className='flex-col gap-2'>
              {trades.map((trItem, idx) => {
                const total =
                  (toValidNumber(trItem.quantity) || 1) *
                  (toValidNumber(trItem.price) || 1);

                return (
                  <div
                    key={trItem.aquatic}
                    className='p-3 border-2 rounded flex bg-primary bg-opacity-10'
                  >
                    <div className='flex-1 flex-col gap-2'>
                      <div className='font-bold text-xl'>{trItem.name}</div>
                      <div className='flex-1 flex gap-2'>
                        <div className='flex-1'>
                          <div className='form-control w-full max-w-xs'>
                            <label className='label'>
                              <span className='label-text'>단가</span>
                            </label>
                            <div className='join w-full'>
                              <input
                                type='number'
                                className='input w-full join-item '
                                placeholder='단가'
                                onFocus={(e) => {
                                  if (e.target.value === '5000') {
                                    e.target.select();
                                  }
                                }}
                                {...register(`trades.${idx}.price`, {
                                  required: {
                                    value: true,
                                    message: '가격은 필수입니다.',
                                  },
                                })}
                              />
                              <label className='join-item rounded-r p-2 flex-center-y bg-white border-t border-b border-r border-black border-opacity-20'>
                                원
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className='flex items-end pb-3'>X</div>
                        <div className='w-1/3'>
                          <label className='label'>
                            <span className='label-text'>수량</span>
                          </label>

                          <input
                            type='number'
                            placeholder='수량'
                            className='input w-full'
                            onFocus={(e) => {
                              if (e.target.value === '1') {
                                e.target.select();
                              }
                            }}
                            {...register(`trades.${idx}.quantity`, {
                              required: {
                                value: true,
                                message: '수량은 필수입니다.',
                              },
                            })}
                          />
                        </div>
                      </div>
                      <label className='label'>
                        <span className='label-text'></span>
                        {total && trItem.price && trItem.quantity && (
                          <span className='label-text text-xl'>
                            총 {total.toLocaleString()} 원
                          </span>
                        )}
                      </label>
                    </div>
                    {!isEdit && (
                      <div className='flex-center-y flex-shrink-0'>
                        <button
                          className='btn btn-sm btn-ghost pr-1'
                          onClick={() => {
                            remove(idx);
                          }}
                        >
                          <IoMdCloseCircle size={23} className='opacity-50' />
                        </button>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>

            {!trades?.[0] && (
              <AquaticsSelector
                placeholder='생물을 검색. ex) 디스커스, 코리도라스'
                onChange={(e) => {
                  if (!e?.value) return;

                  append({
                    aquatic: e?.value,
                    name: e?.label,
                    quantity: '1',
                    price: '5000',
                  });
                }}
              />
            )}
          </div>
        </div>

        <div className='flex-col gap-3'>
          <label htmlFor=''>지역 *</label>
          <RegionSelector
            {...register('trade.regionsJsonStr', {
              required: {
                value: true,
                message: '지역은 필수입니다.',
              },
            })}
            value={regions}
            onChange={(e) => {
              if (notEmpty(e)) {
                const nValues = e.map((v) => v.value);
                setValue('trade.regionsJsonStr', JSON.stringify(nValues));
                return;
              }

              setValue('trade.regionsJsonStr', null);
            }}
          />
        </div>

        <div className='flex-col gap-3'>
          <label htmlFor=''>규정 동의 *</label>
          <label className='cursor-pointer flex gap-2 items-start'>
            <input
              type='checkbox'
              className='checkbox checkbox-primary '
              {...register('trade.regionsChecked', {
                required: {
                  value: true,
                  message: '규칙 확인은 필수입니다.',
                },
              })}
            />
            <span className='label-text text-left'>
              지역을 정확히 입력했습니다. (부정확하게 입력 시 계정이 영구
              차단됩니다.)
            </span>
          </label>
        </div>

        <div className='flex-col gap-3'>
          <label htmlFor=''>분양 상세 정보</label>
          <Textarea
            className='textarea-bordered min-h-[7rem]'
            placeholder='분양 정보를 입력하세요...'
            {...register('postData.desc', {
              required: {
                value: true,
                message: '분양 상세 정보는 필수입니다.',
              },
            })}
          />
        </div>

        <div className='py-3'>
          <label htmlFor=''>이미지 *</label>
          <div className='w-full flex'>
            {previews.map((preview, idx) => {
              return (
                <div key={`post-image-${idx}`} className='w-1/3 relative'>
                  <div className='w-0 h-0 overflow-visible absolute -top-3 right-4'>
                    <span
                      className='bg-white rounded-full block w-8 h-8'
                      onClick={() => {
                        setValue('postData.images', []);
                        setValue('postData.previews', []);
                      }}
                    >
                      <IoMdCloseCircle size={32} />
                    </span>
                  </div>
                  <img src={preview} />
                </div>
              );
            })}
          </div>
          <div>
            <Dropzone
              accept={{
                'image/*': [],
              }}
              multiple={false}
              onDrop={onDrop}
            >
              {({ getRootProps, getInputProps }) => (
                <div className={classNames('pt-4')} {...getRootProps()}>
                  <div className='flex-col gap-1'>
                    <button className='btn btn-outline gap-2'>
                      <FaPlus size={14} />{' '}
                      <span>{imgSelected ? '다시선택' : '이미지'}</span>
                    </button>

                    <div className='text-center opacity-50'>
                      <span>* 지금은 한 장만 받을게요 🐠 </span>
                    </div>
                  </div>
                  <input {...getInputProps()} />
                </div>
              )}
            </Dropzone>
          </div>
        </div>

        <div className='h-12'></div>
      </div>
    </Modal>
  );
}

export default ModalTradePostForm;
