import { memo } from 'react';
import Skeleton from 'react-loading-skeleton';

function SkelPostItem() {
  return (
    <div className='flex gap-3'>
      <div className='flex-1 flex gap-2'>
        <div className='w-1/3'>
          <Skeleton count={1} width={'100%'} height={'2rem'} />
        </div>
        <div className='w-full'>
          <Skeleton count={1} width={'100%'} height={'2rem'} />
        </div>
      </div>
    </div>
  );
}

export default memo(SkelPostItem);
