import { memo } from 'react';

import classNames from 'classnames';
import produce from 'immer';
import { MdChevronLeft, MdShare } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import useCopyClipboard from 'react-use-clipboard';
import appConfig from '../../app/appConfig';
import useAppConfig from '../../app/use-app-configs';
import usePersistAppConfig from '../../app/use-persist-app-state';
import useSearchState from '../../common/hooks/use-search-state';
import SkelPostView from '../../components/skeleton/SkelPostView';
import { H3 } from '../../components/typographic/Headers';
import { Enum_Post_Category, useAquaticQuery } from '../../generated/graphql';
import { convAquatic } from '../../types/gql-enhanced-types';
import { notEmpty } from '../../utils/array';
import InlineGallery from '../post/InlineGallery';
import PostList from '../post/PostList';
import StickyHeader from '../post/StickyHeader';
import AquaticActionMenu from './AquaticActionMenu';
import AquaticStats from './AquaticStats';
import { aquaticViewTabs } from './configs';

function AquaticViewPage() {
  const nav = useNavigate();

  const params = useParams<{ aqId?: string; gallery?: string }>();

  const [
    { aquaticTabSelected: selectedTab },
    { setAquaticTabSelected: setSelectedTab },
  ] = usePersistAppConfig();

  const [{ boardConfig }] = useAppConfig({
    boardConfigId: 'opinion',
  });
  const currPageTags = boardConfig?.tags || [];

  const [
    {
      searchState: { openTags },
      selectedTag,
    },
    { setSearchState },
  ] = useSearchState({ boardConfig });

  const [searchParams] = useSearchParams();
  const fullGallery = searchParams.has('gallery');

  const [, setLinkCopied] = useCopyClipboard(window.location.href, {
    successDuration: appConfig.post.showCopyMessageDuration,
  });

  const { data, loading } = useAquaticQuery({
    skip: !params.aqId,
    variables: { id: params.aqId || '' },
    fetchPolicy: 'cache-first',
  });

  const aquatic = data?.aquatic?.data;
  const aq = convAquatic(aquatic);

  const images = aq?.images?.data || [];

  if (loading) {
    return <SkelPostView />;
  }

  return (
    <>
      {/* HEADER */}
      <StickyHeader>
        <div
          className={classNames(
            'navbar border-b bg-base-100 max-w-2xl',
            'flex justify-between w-full p-0 border-b gap-2',
          )}
        >
          <span
            className='px-2 cursor-pointer'
            onClick={() => {
              nav(-1);
            }}
          >
            <MdChevronLeft className='' size={24} />
          </span>
          <H3
            className='flex-1 flex flex-center-y gap-3'
            onClick={() => {
              nav(-1);
            }}
          >
            {aq?.name}
          </H3>
          <div className='flex-none'>
            <button
              className='btn btn-ghost'
              onClick={async () => {
                setLinkCopied();

                if (navigator.canShare && navigator.share) {
                  const shareData = {
                    url: window.location.href,
                  };
                  const share = navigator.canShare(shareData);

                  if (share) {
                    await navigator.share(shareData);
                  }
                }

                toast.info('주소가 복사되었습니다.');
              }}
            >
              <MdShare size={22} />
            </button>
          </div>
        </div>
      </StickyHeader>

      <div
        className={classNames(
          'flex-col gap-5',
          fullGallery && 'pointer-events-none',
        )}
      >
        <InlineGallery images={images} showNoImagePlaceholder />

        {/**
           *
        !hasImages && (
          <div className='opacity-50 font-bold p-5 text-center bg-black bg-opacity-10 rounded-2xl'>
            으앗! 아직 이미지가 없어요!
          </div>
        )
           */}

        <div className='flex gap-2 overflow-x-auto flex-nowrap px-3 no-scrollbar py-2'>
          {aquaticViewTabs.map((tag) => {
            const selected = tag === selectedTab;
            return (
              <span
                key={tag}
                className={classNames(
                  'badge badge-lg cursor-pointer font-semibold whitespace-nowrap',
                  selected ? 'badge-primary' : 'bg-primary bg-opacity-10',
                )}
                onClick={() => {
                  //
                  setSelectedTab(tag);
                }}
              >
                {tag}
              </span>
            );
          })}
        </div>

        <div className=''>
          {selectedTab === '상세정보' && (
            <div className='flex-col gap-4 px-2'>
              <div className='font-bold text-lg'>생물 상세정보</div>

              {aquatic && <AquaticStats aquaticModel={aq} />}
            </div>
          )}

          {selectedTab === '하이라이트' && (
            <div className='px-2'>
              {aq?.content && (
                <div className=''>
                  <div className='font-bold text-lg'>생물 하이라이트</div>
                  <div
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{ __html: aq?.content }}
                  />
                </div>
              )}
            </div>
          )}

          {selectedTab === '생물게시판' && (
            <div className='flex-col gap-5'>
              <div className='font-bold text-lg px-2'>생물게시판</div>
              <div className='flex-col gap-4'>
                <div className='bg-white pt-3 flex-col'>
                  {notEmpty(currPageTags) && (
                    <>
                      <div className='flex px-3'>
                        <span className='font-bold text-lg'>
                          #{selectedTag || '전체'}
                        </span>
                        <span
                          className='flex-center cursor-pointer'
                          onClick={() => {
                            setSearchState((st) =>
                              produce(st, (dr) => {
                                dr.openTags = !dr.openTags;
                              }),
                            );
                          }}
                        >
                          <MdChevronLeft
                            className={classNames(
                              openTags ? 'rotate-90' : '-rotate-90',
                            )}
                            size={27}
                          />
                        </span>
                      </div>
                      {openTags && (
                        <div className='overflow-x-auto'>
                          <div className='overflow-x-auto px-3 no-scrollbar py-2 w-full max-w-[43rem]'>
                            {currPageTags.map((tag) => {
                              const selected = tag === selectedTag;
                              return (
                                <span
                                  key={tag}
                                  className={classNames(
                                    'badge badge-lg cursor-pointer font-semibold whitespace-nowrap mb-2 mr-2',
                                    selected
                                      ? 'badge-primary'
                                      : 'bg-primary bg-opacity-10',
                                  )}
                                  onClick={() => {
                                    setSearchState((st) =>
                                      produce(st, (dr) => {
                                        if (!dr.boardTagMap)
                                          dr.boardTagMap = {};

                                        if (selected) {
                                          dr.boardTagMap[
                                            boardConfig?.id || 'all'
                                          ] = {
                                            tags: [],
                                          };
                                          return;
                                        }

                                        dr.boardTagMap[
                                          boardConfig?.id || 'all'
                                        ] = {
                                          tags: [tag],
                                        };
                                      }),
                                    );
                                  }}
                                >
                                  {tag}
                                </span>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </div>

                <PostList
                  category={Enum_Post_Category.Opinion}
                  aquaticId={aquatic?.id}
                />
              </div>
            </div>
          )}
        </div>

        {aquatic?.id && <AquaticActionMenu aquaticId={aquatic?.id} />}

        <div className='h-20'></div>
      </div>
    </>
  );
}

export default memo(AquaticViewPage);
