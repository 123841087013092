import { atom } from 'recoil';
import { UsersPermissionsMe } from '../../generated/graphql';

export type AuthStateType = {
  jwt?: string | null;
  user?: UsersPermissionsMe | null;
} | null;

export const tempUser = {
  user: {
    email: 'zirhoo@gmail.com',
    displayName: 'youngjaeDisplay',
    username: 'youngjae',
    id: '1',
    profileUrl:
      'https://s3.amazonaws.com/profile_photos/1203011298905970.1203011298934366.G7brVFh3dWH5s3Zx2F48_huge.jpeg',
  },
  jwt: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiaWF0IjoxNjY1ODQ5ODYyLCJleHAiOjIxMzkyMTM4NjJ9.KKuVzy_RD6RGKmLDe_gP19709BEo54yOwYxCGDTstqM',
};

export const AuthState = atom<AuthStateType>({
  key: 'AuthState',
  // default: tempUser,
  default: null,
});
