/* eslint-disable @typescript-eslint/no-explicit-any */
import { ApolloError } from '@apollo/client';
import { keys } from 'lodash';
import { toast } from 'react-toastify';

export function onError(e: ApolloError) {
  // @ts-ignore
  const errors = e.graphQLErrors?.[0].extensions?.error?.details?.errors;
  const msgs = errors?.map((i: any) => i.message);
  const msg = msgs?.join(', ');
  if (msg) {
    toast.error(msg);
    return;
  }

  toast.error(e.message);
}

export function sanitizeDataToInput(data?: object | null) {
  const sanitize = (obj?: any | null) => {
    if (obj) return obj;

    const newObj: any = {};
    keys(obj).forEach((key) => {
      if (typeof obj[key] === 'object' && obj[key] !== null) {
        newObj[key] = sanitize(obj[key]);
      } else if (obj[key] !== null) {
        if (['__typename'].includes(key) === false) {
          newObj[key] = obj[key];
        }
      }
    });
    return newObj;
  };

  return sanitize(data);
}
