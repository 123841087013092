import produce from 'immer';
import { first, uniq } from 'lodash';
import { useEffect } from 'react';
import { atom, atomFamily, useRecoilState } from 'recoil';
import { recoilPersist } from 'recoil-persist';
import { BoardConfig } from '../../app/use-app-configs';
import useAuth from '../../modules/auth/use-auth';
import { isNotEmpty } from '../../utils/boolean';

const { persistAtom } = recoilPersist();

export const UserInputState = atom<string>({
  key: 'UserInputState',
  default: '',
});

export const SearchKeyState = atom<string>({
  key: 'SearchKeyState',
  default: '',
});

export type FilterMap = {
  keywords?: string[] | null;
  regions?: string[] | null;
  color?: string | null;
  size?: string | null;
};

const PersistSearchState = atomFamily<
  {
    searchMap: FilterMap;
    openTags?: boolean;
    keywordHistory?: string[];
    regions?: string[];
    boardTagMap?: Record<
      string,
      {
        tags?: string[];
      }
    >;
  },
  string | null | undefined
>({
  key: 'SearchState',
  default: {
    searchMap: {},
    boardTagMap: {},
    keywordHistory: [],
  },
  effects: [persistAtom],
});

const SearchModalState = atom<{
  openSearchModal: boolean;
  openTradeNotiConfigsModal?: boolean;
}>({
  key: 'SearchModalState',
  default: {
    openSearchModal: false,
    openTradeNotiConfigsModal: false,
  },
  effects: [persistAtom],
});

export default function useSearchState({
  boardConfig,
}: { boardConfig?: BoardConfig | null } = {}) {
  const [{ userId }] = useAuth();

  const [searchModalState, setSearchModalState] =
    useRecoilState(SearchModalState);
  const [searchState, setSearchState] = useRecoilState(
    PersistSearchState(userId),
  );

  const { searchMap, boardTagMap } = searchState;
  const hasSearchKey = isNotEmpty(searchMap.keywords);
  const hasSearchConditions = isNotEmpty(searchMap);

  const currentBoardTagMap = boardConfig?.id
    ? boardTagMap?.[boardConfig?.id]
    : { tags: [] };

  const selectedTag = currentBoardTagMap?.tags?.[0];

  useEffect(() => {
    const keywords = searchState.searchMap?.keywords;

    if (isNotEmpty(keywords)) {
      setSearchState((st) =>
        produce(st, (dr) => {
          const nHistory = uniq([...keywords, ...(dr.keywordHistory || [])]);
          dr.keywordHistory = nHistory.slice(0, 20);
        }),
      );
    }
  }, [`${searchState.searchMap?.keywords}`]);

  const { keywords } = searchState.searchMap;
  const keyword = first(keywords) || '';

  return [
    {
      keyword,
      selectedTag,
      searchModalState,
      searchState,
      hasSearchConditions,
      hasSearchKey,
      ...searchMap,
    },
    { setSearchModalState, setSearchState },
  ] as const;
}
