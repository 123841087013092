import classNames from 'classnames';
import { memo, useState } from 'react';
import { FaRegCircleCheck } from 'react-icons/fa6';
import { useNavigate } from 'react-router';
import Card from '../../components/Card';
import { FormatsEnum } from '../../types';
import { convToRender, TPost, TTrade } from '../../types/gql-enhanced-types';
import { dateDistance } from '../../utils/date';
import { getSizedMediaLink } from '../../utils/image';
import { formatNumber, formatPrice } from '../../utils/number';
import { displayRegions, parseRegions } from '../noti-configs/utils';
import ModalTradePostForm from '../post/ModalTradePostForm';
import TradeItemMenu from './TradeItemMenu';

function TradePostItem({ post }: { post: TPost }) {
  const nav = useNavigate();

  const [openAddTrade, setOpenAddTrade] = useState<TTrade | null>(null);

  const images = post?.images?.data || [];
  const items = images.map((upload) => {
    return {
      fullscreen:
        getSizedMediaLink({
          upload,
          size: FormatsEnum.large,
        }) || '',
      small:
        getSizedMediaLink({
          upload,
          size: FormatsEnum.thumbnail,
        }) || '',
    };
  });

  const trade = convToRender(post.trades?.data?.[0]);
  const aquatic = convToRender(trade?.aquatic?.data);
  const sold = trade?.sold;
  const firstImg = items?.[0];
  const regions = parseRegions(trade?.regionsJsonStr);

  const viewLink = `/posts/trade/${post.id}`;

  if (trade == null) {
    // TODO handle better
    return null;
  }

  return (
    <Card className='border-b rounded-none'>
      {openAddTrade && (
        <ModalTradePostForm
          trade={openAddTrade}
          onClose={() => {
            setOpenAddTrade(null);
          }}
        />
      )}
      <div className='flex gap-2 overflow-x-hidden'>
        {firstImg && (
          <div
            className='flex cursor-pointer avatar'
            onClick={() => {
              nav(viewLink);
            }}
          >
            <div className='w-24 h-16 rounded-xl'>
              <img alt='thumbnail' src={firstImg.small} />
            </div>
          </div>
        )}
        <div
          className='flex-col flex-1 overflow-x-hidden cursor-pointer'
          onClick={() => {
            nav(viewLink);
          }}
        >
          <div className='flex flex-1 gap-2 overflow-hidden'>
            <div className='flex-1'>{displayRegions({ regions })}</div>
            {sold ? (
              <span
                className={classNames(
                  'flex-center gap-1 flex-nowrap px-2 py-0 text-sm rounded-lg',
                  'whitespace-nowrap',
                  'bg-green-300 text-black font-bold',
                )}
              >
                <FaRegCircleCheck size={14} />
                분양완료
              </span>
            ) : (
              <span
                className={classNames(
                  'flex-center gap-1 flex-nowrap px-2 py-0 text-sm rounded-lg',
                  'whitespace-nowrap',
                  'bg-yellow-200 text-black font-bold',
                )}
              >
                <FaRegCircleCheck size={14} />
                분양중
              </span>
            )}
          </div>
          <div className='flex min-w-0 overflow-hidden'>
            <div className='flex flex-1 gap-1 overflow-hidden text-base flex-nowrap'>
              <div className='overflow-hidden flex-center-y'>
                <span className='overflow-hidden text-base text-ellipsis whitespace-nowrap'>
                  {aquatic?.name} [{trade.quantity}]
                </span>
              </div>
            </div>
            <div className='overflow-hidden text-lg text-ellipsis whitespace-nowrap'>
              <span className='font-semibold'>
                {formatPrice((trade.quantity || 1) * (trade.price || 1))}
              </span>
            </div>
          </div>
          <div className='flex flex-1 gap-2 overflow-hidden flex-center'>
            <div className='flex flex-1 overflow-hidden gap-x-3'>
              <div className='font-bold opacity-40 whitespace-nowrap'>
                {dateDistance(post.createdAt)}
              </div>

              <div className='flex-1 overflow-hidden whitespace-nowrap text-ellipsis'>
                {trade.creator?.data?.attributes?.displayName || ''}
              </div>
            </div>
            <div className='flex gap-2'>
              <div className='flex-1 gap-1 flex-center'>
                <span className=''>
                  {formatNumber(trade.quantity)} {' x '}{' '}
                  {formatPrice(trade.price)}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className='flex items-start py-1'>
          <TradeItemMenu trade={trade} post={post} sold={sold} />
        </div>
      </div>
    </Card>
  );
}

export default memo(TradePostItem);
