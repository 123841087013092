import { Route, Routes } from 'react-router';
import GoogleCallbackPage from '../modules/auth/google/GoogleCallbackPage';
import GoogleLoginPage from '../modules/auth/google/GoogleLoginPage';

function RootLayout() {
  return (
    <Routes>
      <Route path='google'>
        <Route path='login' element={<GoogleLoginPage />} />
        <Route path='callback' element={<GoogleCallbackPage />} />
        <Route path='logout' element={<GoogleLoginPage />} />
      </Route>
    </Routes>
  );
}

export default RootLayout;
