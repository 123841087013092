import classNames from 'classnames';
import {
  ForwardedRef,
  Ref,
  TextareaHTMLAttributes,
  forwardRef,
  useEffect,
  useRef,
  useState,
} from 'react';
import useAutosizeTextAreaCallbackRef from '../../common/hooks/use-auto-size-textarea-el';

const Textarea = forwardRef(function Textarea(
  props: TextareaHTMLAttributes<HTMLTextAreaElement>,
  refIn: ForwardedRef<HTMLTextAreaElement>,
) {
  const { value, autoFocus, className, ...restProps } = props;

  const ref = useRef<HTMLTextAreaElement | null>(null);
  // TODO handle better
  const [ref2, setRef2] = useState<HTMLTextAreaElement | null>(null);

  const finalRef: Ref<HTMLTextAreaElement> = refIn
    ? (node) => {
        if (typeof refIn === 'function') {
          refIn?.(node);
        }

        setRef2(node);
        ref.current = node;
      }
    : ref;

  useAutosizeTextAreaCallbackRef(ref2, [ref2]);

  useEffect(() => {
    if (!refIn && ref.current) {
      setRef2(ref.current);
    }
  }, [refIn, ref.current]);

  useEffect(() => {
    if (autoFocus && ref.current) {
      ref.current.focus();
    }
  }, [autoFocus, ref.current]);

  return (
    <textarea
      className={classNames('textarea w-full h-auto rounded-xl', className)}
      ref={finalRef}
      value={value}
      {...restProps}
    />
  );
});

export default Textarea;
