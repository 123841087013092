import classNames from 'classnames';
import { memo, useState } from 'react';
import { FaExpandAlt } from 'react-icons/fa';
import { Navigation, Pagination, Zoom } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { UploadFileFragment } from '../../generated/graphql';
import { FormatsEnum } from '../../types';
import { getSizedMediaLink } from '../../utils/image';
import FullGallery from './FullGallery';

import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';
import { isEmpty } from 'lodash';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/zoom';

function InlineGallery({
  onClick,
  images,
  showNoImagePlaceholder,
  autoHeight,
}: {
  onClick?: () => void;
  images: (string | UploadFileFragment)[];
  showNoImagePlaceholder?: boolean;
  autoHeight?: boolean;
}) {
  const items = images.map((upload) => {
    if (typeof upload === 'string') {
      return {
        fullscreen: upload,
        medium: upload,
      };
    }
    return {
      fullscreen:
        getSizedMediaLink({
          upload,
          size: FormatsEnum.large,
        }) || '',
      medium:
        getSizedMediaLink({
          upload,
          size: FormatsEnum.small,
        }) || '',
    };
  });

  const [openGallery, setOpenGallery] = useState(false);

  if (isEmpty(items)) {
    if (showNoImagePlaceholder) {
      return (
        <div className='bg-base-200 min-h-[20vh] flex-center flex-1'>
          이미지가 없어요
        </div>
      );
    }

    return null;
  }

  return (
    <div className='bg-base-200'>
      <Swiper
        zoom
        style={{ height: '100%' }}
        pagination={{
          clickable: true,
        }}
        autoHeight={autoHeight}
        modules={[Zoom, Navigation, Pagination]}
        className='mySwiper bg-gray-100'
      >
        {items.map((item) => {
          return (
            <SwiperSlide key={item.medium} className='h-full'>
              <div
                className='swiper-zoom-container h-full max-h-[100vh]'
                onClick={onClick}
              >
                <img
                  alt='thumbnail'
                  src={item.medium}
                  className={classNames('w-full object-contain')}
                />
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
      <div className='relative h-0 z-10'>
        <div className='absolute right-2 -top-12'>
          <button
            className='btn btn-circle w-10 h-10 opacity-70'
            onClick={() => {
              setOpenGallery(true);
            }}
          >
            <FaExpandAlt size={24} />
          </button>
        </div>
      </div>
      {openGallery && (
        <FullGallery
          images={images}
          onClose={() => {
            setOpenGallery(false);
          }}
        />
      )}
    </div>
  );
}

export default memo(InlineGallery);
