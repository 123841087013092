import { useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';

export default function useSearchKey() {
  const [params] = useSearchParams();
  const navigate = useNavigate();

  const searchParam = params.get('key') || '';
  const [searchKey, setSearchKey] = useState(searchParam || '');

  useEffect(() => {
    setSearchKey(searchParam);
  }, [searchParam]);

  function onSearch(newKey: string) {
    navigate(`/search?key=${newKey}`);
  }

  return [
    { searchKey, searchParam },
    { setSearchKey, onSearch, navigate },
  ] as const;
}
