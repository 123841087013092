import { HTMLAttributes, memo } from 'react';

import classNames from 'classnames';
import { MdEdit } from 'react-icons/md';
import { useRerenderInterval } from '../../../common/hooks/use-rerender-interval';
import { CommentFragment, PostFragment } from '../../../generated/graphql';
import { ClientModel } from '../../../types/gql-enhanced-types';
import { dateDistance, formatDate, parseDate } from '../../../utils/date';

function NotiComment({
  post,
  comment,
  onClick,
  onPostClick,
  onEditClick,
  reply,
}: {
  post?: ClientModel<PostFragment> | null;
  comment: ClientModel<CommentFragment>;
  onPostClick?: () => void;
  onEditClick?: () => void;
  reply?: boolean;
} & HTMLAttributes<HTMLDivElement>) {
  useRerenderInterval({
    intervalMs: 1 * 1000,
  });

  return (
    <>
      {!reply && post && (
        <div
          className='line-clamp-1 cursor-pointer hover:bg-base-100 rounded'
          onClick={onPostClick}
        >
          글: {(post?.title || post?.desc || '').slice(0, 40)}
        </div>
      )}
      <div className='flex-col cursor-pointer' onClick={onClick}>
        <div>
          <span
            className={classNames('text-lg', !reply && ' line-clamp-3')}
            onClick={() => {
              if (reply) {
                onEditClick?.();
              }
            }}
          >
            {!reply && (
              <>
                <span className='font-semibold'>
                  {comment?.creator?.data?.attributes?.displayName}
                </span>
                {'님의 댓글: '}
              </>
            )}
            <span>{comment?.content}</span>
            {reply && (
              <span className='ml-2'>
                <MdEdit className='inline opacity-80' size={18} />
              </span>
            )}
          </span>
        </div>

        <div className='flex text-sm'>
          <span className='flex-1'>
            {formatDate(parseDate(comment?.createdAt))}
          </span>
          <span>{dateDistance(comment?.createdAt)}</span>
        </div>
      </div>
    </>
  );
}

export default memo(NotiComment);
