import { memo } from 'react';
import TradeList from './TradeList';

function TradeHomePage() {
  return (
    <div className='flex-1 flex-col'>
      <TradeList />

      <div className='h-36'></div>
    </div>
  );
}

export default memo(TradeHomePage);
