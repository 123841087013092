import { marked } from 'marked';
import { memo, useMemo } from 'react';

import './index-md.scss';

const convertMdToHtml = (md: string) =>
  marked(md, {
    gfm: true,
  });

function TermsPage() {
  const data = '';

  const html = useMemo(() => convertMdToHtml(data || ''), [data]);

  return (
    <div className='md-content'>
      <div
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: html }}
      />
    </div>
  );
}

export default memo(TermsPage);
