import classNames from 'classnames';
import img from '../assets/brand/logo-copy.svg';

export default function LogoCopy({ className }: { className?: string }) {
  return (
    <img
      src={img}
      alt='ttopet logo copy'
      className={classNames('', className)}
    />
  );
}
