/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect } from 'react';

// Updates the height of a <textarea> when the value changes.
const useAutosizeTextAreaCallbackRef = (
  el: HTMLTextAreaElement | null,
  deps: any[],
) => {
  function rerender() {
    if (el) {
      // We need to reset the height momentarily to get the correct scrollHeight for the textarea
      // el.style.height = '0px';
      // eslint-disable-next-line
      const scrollHeight = el.scrollHeight;

      // We then set the height directly, outside of the render loop
      // Trying to set this with state or a el will product an incorrect value.
      // eslint-disable-next-line
      const adjustment = 0;
      const finalH = scrollHeight + adjustment;
      el.style.height = `${finalH}px`;
    }
  }

  useEffect(() => {
    const handleChange = () => {
      rerender();
    };

    el?.addEventListener('keyup', handleChange);

    return () => {
      el?.removeEventListener('keyup', handleChange);
    };
  }, [...deps, el]);

  // useEffect(() => {
  //   rerender();
  // }, [...deps, el?.value]);

  return { rerender };
};

export default useAutosizeTextAreaCallbackRef;
