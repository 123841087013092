import classNames from 'classnames';
import { memo, useEffect } from 'react';
import toast from 'react-hot-toast';
import { MdClose } from 'react-icons/md';
import { Navigation, Pagination, Zoom } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useNavBlocker } from '../../common/hooks/use-nav-blocker';
import { useUiVars } from '../../common/hooks/use-ui-vars';
import Modal from '../../components/Modal';
import { UploadFileFragment } from '../../generated/graphql';
import { FormatsEnum } from '../../types';
import { getSizedMediaLink } from '../../utils/image';

import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/zoom';

function FullGallery({
  onClose,
  images,
}: {
  onClose: () => void;
  images: (string | UploadFileFragment)[];
}) {
  const { isMobile } = useUiVars();

  const items = images.map((upload) => {
    if (typeof upload === 'string') {
      return {
        fullscreen: upload,
        medium: upload,
      };
    }
    return {
      fullscreen:
        getSizedMediaLink({
          upload,
          size: FormatsEnum.large,
        }) || '',
      medium:
        getSizedMediaLink({
          upload,
          size: FormatsEnum.small,
        }) || '',
    };
  });

  useNavBlocker({
    block: true,
    onBack: () => {
      onClose();
    },
  });

  useEffect(() => {
    toast('더블탭, 핀치, 드래그 하시면 되요.', {
      position: 'top-center',
      duration: 1500,
    });
  }, []);

  return (
    <Modal
      noTopPad
      open
      className='p-0 flex-col flex-1 lg:max-w-none lg:w-screen lg:h-screen'
    >
      <div
        className={classNames(
          'fixed h-0 z-10 w-full',
          isMobile ? 'bottom-20' : 'top-8',
        )}
      >
        <div className='absolute right-8'>
          <button
            className='btn btn-circle w-12 h-12 opacity-70'
            onClick={() => {
              onClose();
            }}
          >
            <MdClose size={28} />
          </button>
        </div>
      </div>
      <Swiper
        zoom
        style={{ height: '100%', width: '100%' }}
        pagination={{
          clickable: true,
        }}
        modules={[Zoom, Navigation, Pagination]}
        className='mySwiper bg-gray-200'
      >
        {items.map((item) => {
          return (
            <SwiperSlide key={item.medium} className='h-full'>
              <div className='swiper-zoom-container h-full'>
                <img
                  alt='thumbnail'
                  src={item.fullscreen}
                  className={classNames('w-full object-contain')}
                />
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </Modal>
  );
}

export default memo(FullGallery);
