import { memo, useState } from 'react';

import classNames from 'classnames';
import produce from 'immer';
import { isEmpty, pick, size, trim, values } from 'lodash';
import toast from 'react-hot-toast';
import {
  HiMiniMagnifyingGlass,
  HiOutlineMagnifyingGlass,
} from 'react-icons/hi2';
import { IoMdClose } from 'react-icons/io';
import {
  MdArrowBack,
  MdNotificationsNone,
  MdOutlineRemoveRedEye,
} from 'react-icons/md';
import { useModalManage } from '../../common/hooks/use-modal-manage';
import useSearchState, { FilterMap } from '../../common/hooks/use-search-state';
import Modal from '../../components/Modal';
import Loading from '../../components/loading/Loading';
import AquaticsSearchInput from '../../components/selector/AquaticsSearchInput';
import RegionSelector from '../../components/selector/RegionSelector';
import SkelItem from '../../components/skeleton/SkelItem';
import { parseRegions } from '../../modules/noti-configs/utils';
import { notEmpty } from '../../utils/array';
import { isNotEmpty } from '../../utils/boolean';
import appConfig from '../appConfig';
import { useNotiConfigsRpc } from './hooks/use-noti-configs-rpc';

const { maxConfigs } = appConfig.trade;

function ModalTradeSearch(props: {
  onClose: () => void;
  onFilter: (n: FilterMap) => void;
  searchMap: FilterMap;
}) {
  const { onFilter, searchMap: pMap, onClose } = props;

  useModalManage({
    modalKey: 'trade-search',
    onBlocked: () => {
      onClose();
    },
  });

  const [localFilterMap, setLocalFilterMap] = useState(
    pick(pMap, ['regions', 'keywords']) || {},
  );
  const { keywords } = localFilterMap || {};
  const keyword = keywords?.[0];

  const hasFilterValues = !!values(localFilterMap).find((v) => !isEmpty(v));

  const [
    {
      searchState: { keywordHistory },
    },
    { setSearchState },
  ] = useSearchState();

  const {
    notiConfigs,
    loading,
    userId,
    creating,
    updating,
    deleting,
    updateNotiConfig,
    createNotiConfig,
    deleteNotiConfig,
  } = useNotiConfigsRpc();

  function handleMapChange({
    nMap,
    submit,
  }: {
    nMap?: Partial<FilterMap>;
    submit?: boolean;
  }) {
    const nMapL1 = {
      ...localFilterMap,
      ...(nMap || {}),
    } as FilterMap;

    setLocalFilterMap(nMapL1);

    if (submit) {
      onFilter(nMapL1);
      onClose();
    }
  }

  return (
    <Modal open className='w-full lg:w-[500px] p-0'>
      <div className='flex-col h-full'>
        <div className='flex-none flex-center-y gap-2 py-4'>
          <div>
            <button
              className='btn btn-ghost'
              onClick={() => {
                onClose();
              }}
            >
              <MdArrowBack size={30} />
            </button>
          </div>

          <AquaticsSearchInput
            value={
              keyword ? { value: keyword || '', label: keyword || '' } : null
            }
            onEnter={(o) => {
              handleMapChange({
                nMap: {
                  keywords: o ? [o] : [],
                },
                submit: true,
              });
            }}
            onChange={(o) => {
              setLocalFilterMap((st) =>
                produce(st, (dr) => {
                  if (o?.label) {
                    dr.keywords = [o?.label];
                  } else {
                    dr.keywords = [];
                  }
                }),
              );
            }}
          />

          <div>
            <button
              className='btn btn-ghost'
              onClick={() => {
                handleMapChange({
                  nMap: localFilterMap,
                  submit: true,
                });
              }}
            >
              <HiOutlineMagnifyingGlass size={30} />
            </button>
          </div>
        </div>
        <div className='flex-1 flex-col gap-7 p-4 overflow-auto'>
          <div className='flex-col gap-3'>
            <label>최근 키워드</label>
            <div className='overflow-x-auto no-scrollbar'>
              <ul className='flex gap-2 flex-wrap w-[44rem]'>
                {keywordHistory?.map((keywordH1, idx) => {
                  return (
                    <li key={keywordH1}>
                      <button
                        className='btn px-3 py-1 border rounded-full flex-center gap-2'
                        onClick={() => {
                          setLocalFilterMap((st) =>
                            produce(st, (dr) => {
                              dr.keywords = [keywordH1];
                            }),
                          );
                        }}
                      >
                        <span className='text-base'>#{keywordH1}</span>
                        <span
                          className='opacity-50'
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();

                            setSearchState((st) =>
                              produce(st, (dr) => {
                                const nList = dr.keywordHistory || [];
                                nList.splice(idx, 1);

                                dr.keywordHistory = nList;
                              }),
                            );
                          }}
                        >
                          <IoMdClose size={21} />
                        </span>
                      </button>
                    </li>
                  );
                })}

                {!isNotEmpty(keywordHistory) && (
                  <div className='px-4'>없음</div>
                )}
              </ul>
            </div>
          </div>

          <div className='flex-col gap-2'>
            <label>지역</label>
            <div className=''>
              <RegionSelector
                value={localFilterMap?.regions || []}
                onChange={(options) => {
                  const nValues = options.map((i) => i.value);
                  setLocalFilterMap((st) =>
                    produce(st, (dr) => {
                      dr.regions = nValues;
                    }),
                  );
                }}
              />

              {!isNotEmpty(keywordHistory) && <div className='px-4'>없음</div>}
            </div>
          </div>

          <div className='flex-col gap-3 p-3 border rounded-xl'>
            <div className='flex'>
              <div className='flex-1 flex-center-y'>
                <label>알림 설정</label>
                {(loading || creating || deleting || updating) && (
                  <span className='px-2'>
                    <Loading />
                  </span>
                )}
              </div>
              <div>
                <button
                  className={classNames(
                    'btn btn-sm btn-outline gap-1',
                    !hasFilterValues && 'opacity-60',
                    size(notiConfigs) >= maxConfigs && 'opacity-60',
                  )}
                  onClick={() => {
                    if (creating) return;

                    if (size(trim(keyword)) < 1) {
                      toast.error('키워드를 입력하세요.');
                      return;
                    }
                    if (size(notiConfigs) >= maxConfigs) {
                      toast.error(`최대 ${maxConfigs}개까지 등록 가능합니다.`);
                      return;
                    }

                    createNotiConfig({
                      variables: {
                        data: {
                          creator: userId,
                          keyword,
                          regionsJsonStr: localFilterMap.regions
                            ? JSON.stringify(localFilterMap.regions)
                            : JSON.stringify(['전국']),
                        },
                      },
                    });
                  }}
                >
                  <MdNotificationsNone size={22} /> 알림추가
                </button>
              </div>
            </div>
            <ul className='flex-col gap-2 flex-wrap w-full'>
              {loading && (
                <div className='flex-col gap-2'>
                  <SkelItem />
                  <SkelItem />
                </div>
              )}
              {!loading &&
                notiConfigs?.map((notiConfig) => {
                  const regions = parseRegions(notiConfig.regionsJsonStr);
                  return (
                    <li
                      key={notiConfig.id}
                      className='flex-1 pl-3 pr-1 py-1 border rounded flex-center-y gap-2 w-full'
                      onClick={() => {
                        onFilter({
                          keywords: notiConfig.keyword
                            ? [notiConfig.keyword]
                            : [],
                          regions,
                        });
                        onClose();
                      }}
                    >
                      <div className='flex-1 flex gap-2 overflow-hidden'>
                        <span className='text-base font-semibold nowrap'>
                          {notiConfig.keyword}
                        </span>
                        <span className='text-base ellipsis2'>
                          {notEmpty(regions)
                            ? `[${regions.join(', ')}]`
                            : '[전국]'}
                        </span>
                        <span className='flex-center opacity-60'>
                          <MdOutlineRemoveRedEye size={18} />
                        </span>
                      </div>
                      <div className='flex'>
                        <div className='flex-center'>
                          <input
                            type='checkbox'
                            className={classNames('toggle toggle-primary')}
                            checked={!!notiConfig.enabled}
                            onChange={() => {}}
                            onClick={(e) => {
                              e.stopPropagation();

                              if (updating || !notiConfig.id) return;

                              updateNotiConfig({
                                variables: {
                                  id: notiConfig.id,
                                  data: {
                                    enabled: !notiConfig.enabled,
                                    creator: userId,
                                  },
                                },
                              });
                            }}
                          />
                        </div>

                        <button
                          className='btn btn-ghost p-1'
                          onClick={(e) => {
                            e.stopPropagation();

                            if (!notiConfig.id) return;

                            deleteNotiConfig({
                              variables: {
                                id: notiConfig.id,
                              },
                            });
                          }}
                        >
                          <IoMdClose size={23} className=' opacity-50' />
                        </button>
                      </div>
                    </li>
                  );
                })}

              {!isNotEmpty(notiConfigs) && (
                <div className='px-4 opacity-50'>등록된 알림 없음</div>
              )}
            </ul>
          </div>

          <div className='flex-center'>
            <button
              className='btn btn-primary'
              onClick={() => {
                handleMapChange({ submit: true });
              }}
            >
              <HiMiniMagnifyingGlass className='text-white' /> 검색
            </button>
          </div>

          <div className='h-20'></div>
        </div>
      </div>
    </Modal>
  );
}

export default memo(ModalTradeSearch);
