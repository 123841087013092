import { memo, useRef, useState } from 'react';

import classNames from 'classnames';
import { omit } from 'lodash';
import { SubmitHandler, useForm } from 'react-hook-form';
import appConfig from '../../app/appConfig';
import Textarea from '../../components/form/Textarea';
import LoadingIcon from '../../components/loading/LoadingIcon';
import {
  CommentInput,
  CreateCommentMutation,
  useCreateCommentMutation,
  UserFragment,
} from '../../generated/graphql';
import { onError } from '../../utils/apollo-utils';
import { notEmpty } from '../../utils/array';
import useAuth from '../auth/use-auth';
import { Comment, Id } from '../users/type';
import { addNewComment } from './comment-caches';
import { getRepliesQueryParams } from './comment-params';
import { increaseCommentCount } from './utils';

type Inputs = CommentInput & {
  creatorId: string;
  targetId?: string | null;
};

function CommentReplyForm({
  targetId,
  comment,
  replyTo,
  onLeafComment,
  onSuccess,
  onCancel,
  replyToComment,
  commentToId,
}: {
  targetId?: string | null;
  comment: Comment;
  replyTo?: UserFragment | null;
  replyToComment?: Id;
  onLeafComment?: boolean;
  onSuccess: (data: CreateCommentMutation) => void;
  onCancel: () => void;
  commentToId?: Id | null;
}) {
  const [{ user, isLoggedIn }] = useAuth();

  const ref = useRef<HTMLFormElement>(null);
  const replyToUser = replyTo || comment.attributes?.creator?.data;
  const [taRef, setTaRef] = useState<HTMLTextAreaElement | null>(null);
  const { register, handleSubmit, reset } = useForm<Inputs>({
    defaultValues: {
      creator: user?.id,
      targetId,
      commentTo: commentToId || comment.id,
      replyToUser: replyToUser?.id,
      replyToComment,
      private: comment.attributes?.private,
    },
  });

  const uniqKey = `${comment.id}-${replyToComment}-${commentToId}`;

  const [createComment, { loading: creatingComments }] =
    useCreateCommentMutation({
      onError,
      onCompleted: (data) => {
        onSuccess(data);
        reset();
      },
      update(cache, { data }) {
        if (replyToComment) {
          increaseCommentCount({ cache, commentId: replyToComment });
        }

        if (comment.id) {
          increaseCommentCount({ cache, commentId: comment.id });
        }

        const variables = getRepliesQueryParams({
          commentId: comment.id,
        });

        // adding it to creator list
        addNewComment({
          cache,
          newData: data?.createComment?.data,
          variables,
          replyToComment,
        });
      },
    });

  const onSubmit: SubmitHandler<Inputs> = async (values) => {
    if (creatingComments) {
      return;
    }

    let nValues = {
      ...values,
    };

    if (!notEmpty(nValues.content)) {
      return;
    }

    if (!onLeafComment) {
      nValues = omit(nValues, ['replyToUser']);
    }

    createComment({
      variables: {
        data: nValues,
      },
    });
  };

  if (!isLoggedIn) {
    return null;
  }

  const taRegister = register('content', {
    required: {
      value: true,
      message: 'required',
    },
  });

  return (
    <form ref={ref} onSubmit={handleSubmit(onSubmit)}>
      {!creatingComments ? (
        <div
          className={classNames(
            'card flex flex-col rounded-md bg-base-200',
            // onLeafComment ? 'bg-base-200 ' : 'bg-base-100 '
          )}
        >
          {onLeafComment && (
            <div className='font-bold px-4 pt-4'>
              <span className='font-normal'>댓글</span> @
              {replyToUser?.attributes?.displayName}
            </div>
          )}
          <Textarea
            placeholder='요기가 댓글 쓰는 곳'
            className={classNames(
              'textarea border-0 outline-none focus:outline-none resize-none bg-transparent leading-5',
              'pt-[16px] px-[16px] pb-0 min-h-[2.5rem] h-[1.5rem]',
            )}
            {...taRegister}
            ref={(node) => {
              taRegister.ref(node);
              setTaRef(node);
            }}
            maxLength={appConfig.comment.maxLength}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                const isCmdEnter = e.metaKey && !e.ctrlKey; // Cmd+Enter on Mac
                const isCtrlEnter = !e.metaKey && e.ctrlKey; // Ctrl+Enter on Windows/Linux

                if (isCmdEnter || isCtrlEnter) {
                  e.preventDefault();
                  ref.current?.dispatchEvent(
                    new Event('submit', { cancelable: true, bubbles: true }),
                  );
                }
              }
            }}
          ></Textarea>
          <div
            className={classNames(
              'flex-cy justify-end text-right bg-base-200 gap-2',
            )}
            onClick={() => {
              taRef?.focus();
            }}
          >
            <div className='flex-cy gap-1'>
              <input
                id={`private-${uniqKey}`}
                type='checkbox'
                className='toggle toggle-xs'
                {...register('private')}
              />
              <label
                htmlFor={`private-${uniqKey}`}
                className='cursor-pointer text-sm'
              >
                비밀
              </label>
            </div>
            <button
              className={classNames(
                'btn btn-sm btn-ghost font-bold opacity-40 px-1',
              )}
              onClick={() => {
                onCancel();
                reset();
              }}
            >
              취소
            </button>
            <button
              className={classNames('btn btn-sm btn-ghost font-bold')}
              type='submit'
            >
              등록
            </button>
          </div>
        </div>
      ) : (
        <div className='flex-col flex-center py-7'>
          <LoadingIcon />
        </div>
      )}
    </form>
  );
}

export default memo(CommentReplyForm);
