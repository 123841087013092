import { memo } from 'react';
import { RiMapPinLine } from 'react-icons/ri';
import Card from '../../components/Card';
import { TTrade } from '../../types/gql-enhanced-types';
import { formatNumber, formatPrice } from '../../utils/number';
import { parseJsonToStrArr } from '../../utils/parseJson';

function PostTradeCard({
  trade,
  summary,
}: {
  trade: TTrade;
  summary?: boolean;
}) {
  if (summary) {
    return (
      <Card className='p-0 bg-transparent'>
        <div className='flex-col gap-1'>
          <div className='flex flex-1'>
            <div className='flex-1 font-bold'>
              {trade?.aquatic?.data?.attributes?.name}
            </div>
            <div className=''>
              {formatPrice(trade.price)} {' x '} {formatNumber(trade.quantity)}{' '}
              = {formatPrice((trade.quantity || 1) * (trade.price || 1))}
            </div>
          </div>
        </div>
      </Card>
    );
  }

  return (
    <Card className='bg-blue-50'>
      <div className='flex-col gap-1'>
        <div className='flex flex-1'>
          <div className='flex-1'>
            <div className='badge badge-primary badge-lg'>분양중</div>
          </div>
          <div className=''>
            <div className='text-lg font-semibold'>
              {trade?.aquatic?.data?.attributes?.name}
            </div>
          </div>
        </div>
        <div className='flex flex-1'>
          <div className='flex-1'>단가</div>
          <div className=''>합계</div>
        </div>
        <div className='flex flex-1 font-semibold text-lg'>
          <div className='flex-1'>
            {formatPrice(trade.price)} {' x '} {formatNumber(trade.quantity)}{' '}
          </div>
          <div className=''>
            {formatPrice((trade.quantity || 1) * (trade.price || 1))}
          </div>
        </div>
        <div className='py-1'>
          <div className='border-t border-gray-300'></div>
        </div>
        <div className='flex flex-1'>
          <div className='flex-1'>
            <RiMapPinLine />
          </div>
          <div className='flex gap-2'>
            {parseJsonToStrArr(trade?.regionsJsonStr).map((item) => {
              return <div key={item}>{item}</div>;
            })}
          </div>
        </div>
      </div>
    </Card>
  );
}

export default memo(PostTradeCard);
