import classNames from 'classnames';
import { isNumber } from 'lodash';
import { memo, useMemo } from 'react';
import useCopyClipboard from 'react-use-clipboard';
import YouTube from 'react-youtube';
import appConfig from '../../app/appConfig';
import { useAppRouter } from '../../common/hooks/use-app-router';
import Card from '../../components/Card';
import { IconPaperPlane } from '../../components/Icons';
import { convToRender, TPost } from '../../types/gql-enhanced-types';
import { dateDistance } from '../../utils/date';
import { getYoutubeVId } from '../social/utils/url';
import ProfileUser from '../users/ProfileUser';
import { usePostData } from './hooks/use-post-data';
import InlineGallery from './InlineGallery';
import PostLikeButton from './PostLikeButton';
import PostTradeSection from './PostTradeSection';
import UserContentRenderer from './UserContentRenderer';

function PostCard({ post }: { post: TPost }) {
  const { getPostPath } = useAppRouter();

  const postPath = getPostPath(post?.id);

  const [linkCopied, setLinkCopied] = useCopyClipboard(
    `${window.location.origin}${postPath}`,
    {
      successDuration: appConfig.post.showCopyMessageDuration,
    },
  );

  const aquatic = convToRender(post?.aquatic?.data);
  const social = convToRender(post?.postSocial?.data);
  const link = social?.link;
  const videoId = getYoutubeVId(link);
  const metadata = social?.metadata;

  const { trade } = usePostData({ post });

  const images = useMemo(() => {
    return [...(post?.images?.data || []), ...(aquatic?.images?.data || [])];
  }, [post?.images?.data]);

  const ratio = (metadata?.width || 1) / (metadata?.height || 1);

  return (
    <Card className='p-0 rounded-none'>
      {videoId && (
        <div
          className='w-full bg-base-300'
          style={{
            aspectRatio: isNumber(ratio) ? ratio / 1 : undefined,
          }}
        >
          <YouTube
            videoId={videoId || ''}
            className='w-full h-full'
            opts={{
              width: '100%',
              height: '100%',
              playerVars: {
                autoplay: 0,
              },
            }}
            // className='w-full h-full'
            // onReady={(event) => setPlayer(event.target)}
            // onStateChange={(s) => {
            //   if (s.data === 1) {
            //     setPlaying(true);
            //   } else {
            //     setPlaying(false);
            //   }
            // }}
          />
        </div>
      )}

      <InlineGallery images={images} />

      <div className='p-3 flex-col gap-7'>
        <div className='flex justify-between gap-2'>
          <div>
            <ProfileUser user={post.creator?.data} />
          </div>
          <div className='flex flex-none gap-2 text-sm'>
            <div className='opacity-50'>{dateDistance(post.createdAt)}</div>
          </div>
        </div>

        <UserContentRenderer text={post.desc} />

        {/* TRADE INFO  */}
        {trade && <PostTradeSection trade={trade} />}

        <div>
          {aquatic && (
            <>
              <span className='badge bg-primary bg-opacity-10'>#생물</span>
              {aquatic?.name && (
                <span className='badge bg-primary bg-opacity-10'>
                  #{aquatic?.name}
                </span>
              )}
            </>
          )}
        </div>

        {link && (
          <div className='flex gap-3'>
            <label htmlFor=''>링크열기</label>
            <a
              href={link}
              target='_blank'
              rel='noreferrer'
              className='text-primary'
            >
              {link}
            </a>
          </div>
        )}

        {/* user actions */}
        <div className='flex gap-3 flex-center'>
          <PostLikeButton post={post} />
          <div className='flex gap-2 cursor-pointer'>
            <button
              className='border-gray-400 rounded-full btn btn-outline'
              onClick={async () => {
                setLinkCopied();

                if (navigator.canShare && navigator.share) {
                  const shareData = {
                    url: `${window.location.origin}${postPath}`,
                  };
                  const share = navigator.canShare(shareData);

                  if (share) {
                    await navigator.share(shareData);
                  }
                }
              }}
            >
              <IconPaperPlane /> <span>공유하기</span>
            </button>
            <div
              className={classNames('relative h-0 -top-1', {
                hidden: !linkCopied,
              })}
            >
              <div className='absolute px-2 py-1 border rounded whitespace-nowrap'>
                링크 복사됨
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className=''></div>
    </Card>
  );
}

export default memo(PostCard);
