import { memo } from 'react';
import SkelPostItem from './SkelPostItem';

function SkelPostList() {
  return (
    <div className='flex-col gap-3 py-2'>
      <SkelPostItem />
      <SkelPostItem />
      <SkelPostItem />
      <SkelPostItem />
      <SkelPostItem />
      <SkelPostItem />
      <SkelPostItem />
      <SkelPostItem />
      <SkelPostItem />
    </div>
  );
}

export default memo(SkelPostList);
