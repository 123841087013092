import produce from 'immer';
import { atom, useRecoilState } from 'recoil';
import { MaybeTReportInput } from '../../../types/gql-enhanced-types';

const GlobalState = atom<{
  reportState?: MaybeTReportInput;
  openDownload?: boolean;
}>({
  key: 'GlobalState',
  default: {},
});

export default function useGlobalState() {
  const [globalState, setGlobalState] = useRecoilState(GlobalState);
  const { reportState } = globalState;

  function setReportState(p: MaybeTReportInput | null) {
    setGlobalState((st) =>
      produce(st, (dr) => {
        dr.reportState = p;
      }),
    );
  }

  function setDownloadApp(p: boolean) {
    setGlobalState((st) =>
      produce(st, (dr) => {
        dr.openDownload = p;
      }),
    );
  }

  return [
    { globalState, reportState },
    { setDownloadApp, setGlobalState, setReportState },
  ] as const;
}
