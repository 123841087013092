import { useLocation, useNavigate, useParams } from 'react-router';
import { Enum_Post_Category } from '../../generated/graphql';

export function useAppRouter() {
  const loc = useLocation();
  const nav = useNavigate();
  const isHome = loc.pathname === '/';

  const params = useParams<{
    postId?: string;
    aqId?: string;
    boardType?: string;
  }>();
  const { aqId, postId, boardType: pBoardType } = params;
  const boardType = pBoardType || 'main';
  const isNotiPage = loc.pathname === '/notifications';

  function getPostPath(
    nPostId?: string | null,
    nBoardType?: string | Enum_Post_Category | null,
  ) {
    if (!nPostId) {
      return `/posts`;
    }

    return `/posts/${nBoardType || boardType}/${nPostId}`;
  }

  return {
    isSubPage: aqId || postId || isNotiPage,
    isPostHome: loc.pathname === '/posts',
    params,
    isHome,
    loc,
    nav,
    getPostPath,
  } as const;
}
