import { memo, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Platform, useConnectAuthMutation } from '../../../generated/graphql';
import useAuth from '../use-auth';

function GoogleLogin() {
  const [p] = useSearchParams();
  const [code] = p.getAll('code');

  const [, { handleLogin }] = useAuth();

  const [connect] = useConnectAuthMutation({
    onCompleted: async (connectData) => {
      if (connectData?.connect.user) {
        await handleLogin(connectData?.connect);

        // send success to parent window
        if (window.opener) {
          window.opener.postMessage(
            { googleAuth: 1, success: true, connect: connectData?.connect },
            '*',
          );
          window.close();
        }
      }
    },
    onError: async (err1) => {
      if (window.opener) {
        window.opener.postMessage(
          { googleAuth: 1, success: false, msg: err1.message },
          '*',
        );
        window.close();
      }
    },
  });

  useEffect(() => {
    if (code) {
      connect({
        variables: {
          input: {
            code,
            type: 'google',
            platform: Platform.Web,
          },
        },
      });
    }
  }, []);

  return (
    <div className='flex flex-col h-full p-5 lg:p-10'>구글 로그인 성공</div>
  );
}

function GoogleCallbackPage() {
  return (
    // <GoogleOAuthProvider clientId={envs.auth.google.clientId || ''}>
    <GoogleLogin />
    // </GoogleOAuthProvider>
  );
}

export default memo(GoogleCallbackPage);
