import { isEmpty, size } from 'lodash';
import { memo } from 'react';
import { Waypoint } from 'react-waypoint';

import { IoFish } from 'react-icons/io5';
import appConfig from '../../app/appConfig';
import { usePostsQuery } from '../../generated/graphql';
import { usePageLoadMore } from '../../graphql/use-page-load-more';

import classNames from 'classnames';
import produce from 'immer';
import { MdClose } from 'react-icons/md';
import useSearchState from '../../common/hooks/use-search-state';
import LoadingIcon from '../../components/loading/LoadingIcon';
import SkelPostList from '../../components/skeleton/SkelPostList';
import { convToRender } from '../../types/gql-enhanced-types';
import { notEmpty, notNull } from '../../utils/array';
import useAuth from '../auth/use-auth';
import useBlockState from '../report/hooks/use-block-state';
import { getTradeQueryParams } from './trade-query-params';
import TradePostItem from './TradePostItem';

const { pageSize } = appConfig.post;

function TradeList() {
  const [{ userId }] = useAuth();

  const [{ keyword, regions }, { setSearchState }] = useSearchState();
  const [, { filterBlockedPost }] = useBlockState();

  const listQueryVariables = getTradeQueryParams({
    creatorId: userId,
    keyword,
    regions,
  });

  const { data, loading, fetchMore } = usePostsQuery({
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: listQueryVariables,
  });

  const posts = (data?.posts?.data || [])
    .map(convToRender)
    .filter((i) => i?.deleted !== true)
    .filter(filterBlockedPost)
    .filter(notNull);

  const listLength = size(posts);
  const [{ hasMore, loadingMore }, { loadMore }] = usePageLoadMore({
    fetchMore,
    loading,
    pageSize,
    listLength,
  });

  const noPosts = isEmpty(posts);

  const searchKeys = [...(regions || [])].filter(notEmpty);

  return (
    <div
      onKeyDown={(e) => {
        e.stopPropagation();
      }}
      className='flex-1 flex flex-col'
    >
      <div className='px-3'>
        {!isEmpty(searchKeys) && (
          <div className='flex gap-2 pt-3 flex-wrap justify-end'>
            {searchKeys.map((searchKey) => {
              return (
                <span
                  key={searchKey}
                  className={classNames(
                    'font-bold flex-center rounded-full px-3 h-9 border border-black shadow-md cursor-pointer',
                  )}
                  onClick={() => {
                    setSearchState((st) =>
                      produce(st, (dr) => {
                        dr.searchMap.regions = dr.searchMap.regions?.filter(
                          (k) => k !== searchKey,
                        );
                      }),
                    );
                  }}
                >
                  {searchKey}
                  <MdClose size={18} />
                </span>
              );
            })}
          </div>
        )}
      </div>

      {loading && <SkelPostList />}

      {!loading &&
        posts.map((item) => {
          return <TradePostItem key={item.id} post={item} />;
        })}

      {!loading && noPosts && (
        <div className='flex-col flex-1 flex-center'>
          <div className='font-primary text-6xl'>텅!</div>
          <IoFish size={20} />
        </div>
      )}

      {loadingMore && (
        <div className='flex-center-x p-10'>
          <LoadingIcon />
        </div>
      )}

      {hasMore && <Waypoint scrollableAncestor='window' onEnter={loadMore} />}
    </div>
  );
}

export default memo(TradeList);
