import { ReactNode, useState } from 'react';
import { Outlet } from 'react-router';

import classNames from 'classnames';
import { HiMenuAlt2 } from 'react-icons/hi';
import { IoFishOutline } from 'react-icons/io5';
import { LuMessagesSquare } from 'react-icons/lu';
import { MdArrowCircleUp, MdOutlineSell } from 'react-icons/md';
import { RiHome5Line } from 'react-icons/ri';
import { NavLink } from 'react-router-dom';
import { useAppRouter } from '../common/hooks/use-app-router';
import useScrollAnchor from '../common/hooks/use-scroll-anchor';
import useScrollToTop from '../common/hooks/use-scroll-to-top';
import LogoBlinkerCenter from '../components/loading/LogoBlink';
import useAuth from '../modules/auth/use-auth';
import GlobalFeatures from '../modules/report/GlobalFeatures';
import SignInModalWrap from '../modules/users/SignInModal';
import GlobalActionMenu from './GlobalActionMenu';
import GlobalNav from './GlobalNav';
import ModalMobileMenu from './ModalMobileMenu';

function Layout({ children }: { children?: ReactNode }) {
  const [{ authInit, loading }] = useAuth();

  const [openMenu, setOpenMenu] = useState(false);
  const [open, setOpen] = useState(false);

  const { isSubPage } = useAppRouter();
  const { isAbove, scrollToTop } = useScrollAnchor({ topY: 150 });

  useScrollToTop();

  if (!authInit || loading) {
    return (
      <div className='flex-1 justify-center'>
        <LogoBlinkerCenter />
      </div>
    );
  }

  return (
    <div className='flex-col mx-auto min-h-full w-full max-w-2xl'>
      {openMenu && (
        <ModalMobileMenu
          onClose={() => {
            setOpenMenu(false);
          }}
        />
      )}

      {/* <!-- Navbar --> */}
      <div className='z-10 select-none'>{!isSubPage && <GlobalNav />}</div>

      {/* <!-- Page content here --> */}
      <div
        className={classNames(
          'flex-1 flex overflow-hidden',
          isSubPage ? '' : 'mt-[52px]',
        )}
      >
        <div
          className={classNames('flex-1 flex-col overflow-hidden bg-base-100')}
        >
          {children || <Outlet />}
        </div>
      </div>
      <div id='modal-root'></div>

      {/* GLOBAL ACTION BUTTON */}
      {!isSubPage && (
        <div className='fixed max-w-2xl w-full block bottom-0 h-0 overflow-visible z-20'>
          <div className='relative w-full'>
            <GlobalActionMenu />
          </div>
        </div>
      )}

      {/* BUTTON NAV BAR */}
      {!isSubPage && (
        <div className='fixed max-w-2xl w-full flex gap-3 bottom-0 bg-white py-1 border-t border-gray-200 z-20 select-none'>
          <NavLink
            to='/'
            className={({ isActive }) => {
              return classNames(
                'flex-1 flex-col flex-center py-1',
                isActive && 'font-bold text-primary',
              );
            }}
          >
            <RiHome5Line size={25} />
            <span>홈</span>
          </NavLink>
          <NavLink
            to='/posts'
            className={({ isActive }) => {
              return classNames(
                'flex-1 flex-col flex-center py-1',
                isActive && 'font-bold text-primary',
              );
            }}
          >
            <LuMessagesSquare size={25} />
            <span>커뮤니티</span>
          </NavLink>
          <NavLink
            to='/trades'
            className={({ isActive }) => {
              return classNames(
                'flex-1 flex-col flex-center py-1',
                isActive && 'font-bold text-primary',
              );
            }}
          >
            <MdOutlineSell size={25} />
            <span>분양 장터</span>
          </NavLink>
          <NavLink
            to='/pet/aquatics'
            className={({ isActive }) => {
              return classNames(
                'flex-1 flex-col flex-center py-1',
                isActive && 'font-bold text-primary',
              );
            }}
          >
            <IoFishOutline size={25} />
            <span>생물정보</span>
          </NavLink>
          <div
            className='flex-1 flex-col flex-center py-1 cursor-pointer'
            onClick={() => {
              setOpenMenu(!openMenu);
            }}
          >
            <HiMenuAlt2 size={28} />
            <span>메뉴</span>
          </div>
        </div>
      )}

      <div className='h-0 overflow-hidden'>
        <div className='flex justify-center p-3 gap-2 pointer-events-auto'>
          <button
            id='join-beta'
            className='btn btn-primary'
            onClick={() => {
              setOpen(true);
            }}
          >
            로그인
          </button>
        </div>
        {open && <SignInModalWrap setOpen={setOpen} />}
      </div>
      <div
        className={classNames(
          'fixed w-full max-w-2xl flex z-10',
          isSubPage ? 'bottom-12 ' : 'bottom-24 ',
          isAbove ? 'hidden' : 'flex-center',
        )}
      >
        <button
          className='btn btn-sm btn-accent shadow opacity-80'
          onClick={() => {
            scrollToTop();
          }}
        >
          <MdArrowCircleUp size={21} />
          <span>위로가기</span>
        </button>
      </div>

      <GlobalFeatures />
    </div>
  );
}

export default Layout;
