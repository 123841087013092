import { PostsQuery } from '../generated/graphql';
import { ClientModel } from '../types/gql-enhanced-types';
import { notNull } from '../utils/array';

export function convToRender<
  T extends { id?: string | null; attributes?: T['attributes'] }
>(data?: T | null): ClientModel<T> | null {
  if (data == null) return null;

  return {
    id: data?.id,
    ...data?.attributes,
  };
}

export function prepPosts<T extends PostsQuery | undefined>(response: T) {
  const list = (response?.posts?.data || []).map(convToRender).filter(notNull);
  return list;
}
