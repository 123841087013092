import { memo, useState } from 'react';

import classNames from 'classnames';
import produce from 'immer';
import { isEmpty, size, trim, values } from 'lodash';
import toast from 'react-hot-toast';
import { IoMdClose } from 'react-icons/io';
import {
  MdArrowBack,
  MdOutlineNotificationAdd,
  MdOutlineRemoveRedEye,
} from 'react-icons/md';
import { useModalManage } from '../../common/hooks/use-modal-manage';
import useSearchState, { FilterMap } from '../../common/hooks/use-search-state';
import Modal from '../../components/Modal';
import InlineBtnLoading from '../../components/loading/InlineBtnLoading';
import Loading from '../../components/loading/Loading';
import AquaticsSelector from '../../components/selector/AquaticsSelector';
import RegionSelector from '../../components/selector/RegionSelector';
import { SelectOption } from '../../components/selector/selector-types';
import SkelItem from '../../components/skeleton/SkelItem';
import { H3 } from '../../components/typographic/Headers';
import { parseRegions } from '../../modules/noti-configs/utils';
import { notEmpty } from '../../utils/array';
import { isNotEmpty } from '../../utils/boolean';
import appConfig from '../appConfig';
import { useNotiConfigsRpc } from './hooks/use-noti-configs-rpc';

const { maxConfigs } = appConfig.trade;

function ModalTradeNotiConfigs(props: {
  onClose: () => void;
  onFilter: (n: FilterMap) => void;
  searchMap: FilterMap;
}) {
  const { onFilter, searchMap: pMap, onClose } = props;

  useModalManage({
    modalKey: 'trade-search',
    onBlocked: () => {
      onClose();
    },
  });

  const initialRegions = isEmpty(pMap?.regions)
    ? ['전국']
    : pMap?.regions || ['전국'];

  const [localFilterMap, setLocalFilterMap] = useState<FilterMap>({
    regions: initialRegions,
    keywords: pMap?.keywords || [],
  });

  const hasFilterValues = !!values(localFilterMap).find((v) => !isEmpty(v));

  const [
    {
      searchState: { keywordHistory },
    },
  ] = useSearchState();

  const [aquatic, setAquatic] = useState<SelectOption | null>(null);

  const {
    notiConfigs,
    loading,
    userId,
    creating,
    updating,
    deleting,
    updateNotiConfig,
    createNotiConfig,
    deleteNotiConfig,
  } = useNotiConfigsRpc({
    onCreateCompleted: () => {
      setAquatic(null);
      setLocalFilterMap({ regions: localFilterMap.regions, keywords: [] });
    },
  });

  return (
    <Modal open className='w-full lg:w-[500px] p-0'>
      <div className='flex-col h-full'>
        <div className='flex-none flex-center-y gap-2 py-4'>
          <div>
            <button
              className='btn btn-ghost'
              onClick={() => {
                onClose();
              }}
            >
              <MdArrowBack size={30} />
            </button>
          </div>
          <div className='flex-1'>
            <H3 className='flex-center pr-10'>알림 설정</H3>
          </div>
        </div>

        <div className='flex-1 overflow-auto p-3 flex-col gap-5'>
          {notEmpty(notiConfigs) && (
            <div className='flex-col gap-3 p-3 border rounded-xl bg-base-200'>
              <div className='flex'>
                <div className='flex-1 flex-center-y'>
                  <label className='flex-1 flex justify-between'>
                    <span className='flex-center-y'>
                      <span>등록된 알림</span>
                      {(loading || deleting || updating) && (
                        <span className='px-2'>
                          <Loading />
                        </span>
                      )}
                    </span>

                    <span>(최대 {maxConfigs}개)</span>
                  </label>
                </div>
              </div>
              <ul className='flex-col gap-2 flex-wrap w-full'>
                {loading && (
                  <div className='flex-col gap-2'>
                    <SkelItem />
                    <SkelItem />
                  </div>
                )}
                {!loading &&
                  notiConfigs?.map((notiConfig) => {
                    const regions = parseRegions(notiConfig.regionsJsonStr);
                    return (
                      <li
                        key={notiConfig.id}
                        className='flex-1 pl-3 pr-1 py-1 border rounded flex-center-y gap-2 w-full bg-base-100'
                        onClick={(e) => {
                          e.stopPropagation();

                          onFilter({
                            keywords: notiConfig.keyword
                              ? [notiConfig.keyword]
                              : [],
                            regions,
                          });
                          onClose();
                        }}
                      >
                        <div className='flex-1 flex gap-2 overflow-hidden'>
                          <span className='text-base font-semibold nowrap'>
                            {notiConfig.keyword}
                          </span>
                          <span className='text-base ellipsis2'>
                            {notEmpty(regions)
                              ? `[${regions.join(', ')}]`
                              : '[전국]'}
                          </span>
                          <span className='flex-center opacity-60'>
                            <MdOutlineRemoveRedEye size={18} />
                          </span>
                        </div>
                        <div className='flex'>
                          <div className='flex-center'>
                            <input
                              type='checkbox'
                              className={classNames('toggle toggle-primary')}
                              checked={!!notiConfig.enabled}
                              onClick={(e) => {
                                e.stopPropagation();

                                if (updating || !notiConfig.id) return;

                                updateNotiConfig({
                                  variables: {
                                    id: notiConfig.id,
                                    data: {
                                      enabled: !notiConfig.enabled,
                                      creator: userId,
                                    },
                                  },
                                });
                              }}
                            />
                          </div>

                          <button
                            className='btn btn-ghost p-1'
                            onClick={(e) => {
                              e.stopPropagation();

                              if (!notiConfig.id) return;

                              deleteNotiConfig({
                                variables: {
                                  id: notiConfig.id,
                                },
                              });
                            }}
                          >
                            <IoMdClose size={23} className=' opacity-50' />
                          </button>
                        </div>
                      </li>
                    );
                  })}

                {!isNotEmpty(notiConfigs) && (
                  <div className='px-4 opacity-50'>등록된 알림 없음</div>
                )}
              </ul>
            </div>
          )}

          {size(notiConfigs) < maxConfigs ? (
            <div className='flex-col gap-7 p-3 border rounded-xl'>
              <label htmlFor=''>알림 등록</label>
              <div className='flex-col gap-3'>
                <div className='flex'>
                  <div className='flex-1 flex-center-y'>
                    <label>어종</label>
                  </div>
                  <div></div>
                </div>
                <div>
                  <AquaticsSelector
                    placeholder='어종을 검색하여 선택하세요.'
                    value={aquatic}
                    onChange={(option) => {
                      setAquatic(option);
                    }}
                  />
                </div>
              </div>

              <div className='flex-col gap-2'>
                <label>지역</label>
                <div className=''>
                  <RegionSelector
                    value={localFilterMap?.regions || []}
                    onChange={(options) => {
                      const nValues = options.map((i) => i.value);
                      setLocalFilterMap((st) =>
                        produce(st, (dr) => {
                          dr.regions = nValues;
                        }),
                      );
                    }}
                  />

                  {!isNotEmpty(keywordHistory) && (
                    <div className='px-4'>없음</div>
                  )}
                </div>
                <div className='text-sm opacity-60'>
                  <label htmlFor=''>다중선택 가능</label>
                </div>
              </div>

              <div className='flex justify-end'>
                <button
                  className={classNames(
                    'btn btn-sm btn-outline gap-1 pr-2 pl-1',
                    !hasFilterValues && 'opacity-60',
                  )}
                  onClick={() => {
                    if (creating) return;

                    const keyword = trim(aquatic?.label || '');

                    if (isEmpty(keyword)) {
                      toast.error('어종을 선택하세요.');
                      return;
                    }
                    if (size(notiConfigs) > 2) {
                      toast.error('최대 3개까지 등록 가능합니다.');
                      return;
                    }

                    createNotiConfig({
                      variables: {
                        data: {
                          creator: userId,
                          keyword,
                          regionsJsonStr: notEmpty(localFilterMap.regions)
                            ? JSON.stringify(localFilterMap.regions)
                            : null,
                        },
                      },
                    });
                  }}
                >
                  {creating ? (
                    <InlineBtnLoading />
                  ) : (
                    <MdOutlineNotificationAdd size={24} />
                  )}
                  알림추가
                </button>
              </div>
            </div>
          ) : (
            <div>* 최대 3개까지 등록 가능합니다.</div>
          )}

          <div className='h-20'></div>
        </div>
      </div>
    </Modal>
  );
}

export default memo(ModalTradeNotiConfigs);
