import { memo, useState } from 'react';

import { FiEye, FiMessageCircle } from 'react-icons/fi';
import Card from '../../components/Card';
import { dateDistance } from '../../utils/date';
import useAuth from '../auth/use-auth';

import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';
import { IoMdHeart, IoMdHeartEmpty } from 'react-icons/io';
import useAppConfig from '../../app/use-app-configs';
import { useAppRouter } from '../../common/hooks/use-app-router';
import useSearchState from '../../common/hooks/use-search-state';
import { TPost, convToRender } from '../../types/gql-enhanced-types';
import { formatNumber } from '../../utils/number';
import ModalPostForm from '../post/ModalPostForm';
import PostItemMenu from '../post/PostItemMenu';
import { usePostLike } from '../post/use-post-like';

function PostSocialListItem({ post }: { post: TPost }) {
  const [, { nav }] = useAuth();

  const { getPostPath } = useAppRouter();
  const postPath = getPostPath(post?.id);

  const [openAddPost, setOpenAddPost] = useState<TPost | null>(null);
  const [{ liked }] = usePostLike({
    post,
  });

  const [{ paramBoardConfig }] = useAppConfig();
  const [{ selectedTag }] = useSearchState({ boardConfig: paramBoardConfig });

  const social = convToRender(post.postSocial?.data);

  const tagSelected = !!selectedTag;

  const firstImg = social?.thumbLink;

  return (
    <Card className='rounded-none border-b'>
      {openAddPost && (
        <ModalPostForm
          post={openAddPost}
          onClose={() => {
            setOpenAddPost(null);
          }}
        />
      )}
      <div className='flex gap-2'>
        {firstImg && (
          <div
            className='avatar cursor-pointer'
            onClick={() => {
              nav(postPath);
            }}
          >
            {liked && (
              <div className='w-0 h-0'>
                <div className='absolute top-[2.6rem] right-[0.2rem]'>
                  <IoMdHeart className='opacity-100 text-red-600' size={21} />
                </div>
              </div>
            )}
            <div className='w-16 h-16 rounded-xl bg-base-300'>
              {firstImg && <img alt='thumbnail' src={firstImg} />}
            </div>
          </div>
        )}

        <div
          className='flex-col flex-1 overflow-x-hidden cursor-pointer'
          onClick={() => {
            nav(postPath);
          }}
        >
          <div className='flex-1 min-w-0 overflow-hidden flex-center-y gap-1'>
            {!tagSelected && (
              <span className='badge bg-primary bg-opacity-10'>
                {post.tags}
              </span>
            )}
            <div className='flex-1 text-base overflow-hidden text-ellipsis whitespace-nowrap'>
              {post.title}
            </div>
          </div>
          <div className='flex-1 flex gap-x-2 flex-wrap'>
            <div className='flex flex-1 basis-2/3 gap-x-3 flex-wrap'>
              <div className='flex-center-y opacity-40 text-sm font-bold whitespace-nowrap'>
                {dateDistance(post.createdAt)}
              </div>

              <div className='flex-center-y flex-1 overflow-x-hidden'>
                <div className='flex-1 whitespace-nowrap overflow-hidden text-ellipsis'>
                  {post.creator?.data?.attributes?.displayName || ''}
                </div>
              </div>
            </div>
            <div className='flex-grow flex gap-2 justify-end'>
              <div className='flex-center gap-1'>
                <FiMessageCircle className='opacity-50' />
                <span>{formatNumber(post.commentCount || 0)}</span>
              </div>

              <div className='flex-center gap-1'>
                <FiEye className='opacity-50' />
                <span>{formatNumber(post.viewCount || 0)}</span>
              </div>

              <div className='flex-center gap-1'>
                {!liked ? (
                  <IoMdHeartEmpty className='opacity-40' />
                ) : (
                  <IoMdHeart className='opacity-60' />
                )}
                <span>{formatNumber(post.likeCount || 0)}</span>
              </div>
            </div>
          </div>
        </div>
        <div>
          <PostItemMenu post={post} />
        </div>
      </div>
    </Card>
  );
}

export default memo(PostSocialListItem);
