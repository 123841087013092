import classNames from 'classnames';
import { endsWith, isEmpty, values } from 'lodash';
import { useCallback, useEffect } from 'react';
import Dropzone from 'react-dropzone';
import { FaPlus } from 'react-icons/fa';
import { MdArrowBack } from 'react-icons/md';

import { IoMdCloseCircle } from 'react-icons/io';
import { toast } from 'react-toastify';
import appConfig from '../../app/appConfig';
import useAppConfig from '../../app/use-app-configs';
import { useModalManage } from '../../common/hooks/use-modal-manage';
import { useNavBlocker } from '../../common/hooks/use-nav-blocker';
import Textarea from '../../components/form/Textarea';
import Loading from '../../components/loading/Loading';
import Modal from '../../components/Modal';
import { Enum_Post_Category } from '../../generated/graphql';
import { convToRender, TPost } from '../../types/gql-enhanced-types';
import useAuth from '../auth/use-auth';
import { useYoutubeMetadata } from '../social/utils/use-youtube-metadata';
import { usePostForm } from './hooks/use-post-form';

const { titleMaxLen } = appConfig.post;

function ModalPostSocialForm({
  post,
  category,
  onClose,
  onSuccess,
}: {
  post?: TPost;
  category?: Enum_Post_Category;
  onClose?: () => void;
  onSuccess?: () => void;
}) {
  const [{ isSuperAdmin }] = useAuth();
  const [{ boardConfigs }] = useAppConfig();
  const defaultBoardConfig = boardConfigs.find((b) => b.id === 'main');
  const boardConfig =
    boardConfigs.find((b) => b.id === category) || defaultBoardConfig;

  useModalManage({ modalKey: 'post-create' });

  const {
    saving,
    isEdit,
    handleSave,
    handleSubmit,
    setValue,
    register,
    watch,
  } = usePostForm({
    category,
    post,
    onSuccess: (postFrag) => {
      const nPost = convToRender(postFrag);

      if (nPost?.inReview && !isSuperAdmin) {
        toast.success('관리자의 심사를 거쳐 게시물이 등록됩니다.', {
          autoClose: false,
        });
      }

      onSuccess?.();
      onClose?.();
    },
  });

  useNavBlocker({
    block: true,
    onBack: () => {
      onClose?.();
    },
  });

  const onDrop = useCallback(
    async (files: File[]) => {
      if (saving) return;

      const firstFile = files?.[0];

      if (firstFile) {
        if (endsWith(firstFile.name, 'gif')) {
          return;
        }

        setValue('post.images', [firstFile]);
        setValue('previews', [URL.createObjectURL(firstFile)]);
      }
    },
    [saving, setValue],
  );

  const postData = watch('post');
  const images = watch('post.images');
  const previews = watch('previews');
  const link = watch('social.link');
  const thumbLink = watch('social.thumbLink');
  const imgSelected = !isEmpty(images);

  const { metadata, loading } = useYoutubeMetadata({ link });

  useEffect(() => {
    if (metadata?.title) {
      setValue('social.metadata', metadata);
      setValue(
        'post.title',
        (metadata?.title || '').substring(0, titleMaxLen) || '',
      );
      setValue('social.thumbLink', metadata?.thumbnail_url || '');
      setValue('social.link', link || '');
      setValue(
        'post.desc',
        [`만든이: ${metadata?.author_name}`, `제목: ${metadata?.title}`].join(
          '\n',
        ),
      );
    }
  }, [metadata]);

  return (
    <Modal open>
      <div className='flex py-2 flex-center-y'>
        <div className='z-40 flex-none w-0 overflow-visible'>
          <button
            className='btn btn-ghost'
            onClick={() => {
              const yes = window.confirm(
                '작성중인 내용이 사라집니다. 나가시겠습니까?',
              );
              if (!yes) return;

              onClose?.();
            }}
          >
            <MdArrowBack size={32} />
          </button>
        </div>

        <div className='flex-1 text-center text-modal-title'>
          {boardConfig?.name || '글'}
        </div>

        <div className='z-40 flex-none w-0 overflow-visible'>
          <div className='relative bg-red-300 h-[40px]'>
            <div className='absolute right-0 -top-1'>
              <button
                className='flex flex-col btn btn-ghost whitespace-nowrap btn-lg'
                onClick={() => {
                  handleSubmit(handleSave, (e) => {
                    values(e).forEach((msg) => {
                      toast.error(msg.message);
                    });
                  })();
                }}
              >
                {saving ? (
                  <span className='loading loading-spinner'></span>
                ) : !isEdit ? (
                  '등록'
                ) : (
                  '수정'
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className='flex-col flex-1 px-4 overflow-auto gap-9'>
        <div className='flex-col gap-2'>
          <label htmlFor=''>주제</label>
          <select
            className='select'
            maxLength={titleMaxLen}
            placeholder='카테고리...'
            {...register('post.tags', {
              required: {
                value: true,
                message: '카테고리는 필수입니다.',
              },
            })}
          >
            <option value={undefined}>-</option>
            {boardConfig?.tags?.map((tag) => {
              return (
                <option key={tag} value={tag}>
                  {tag}
                </option>
              );
            })}
          </select>
        </div>

        {postData.category === 'social' && (
          <div className='flex-col gap-3'>
            <div className='flex justify-between'>
              <label htmlFor=''>링크</label>
              {loading && (
                <span>
                  <Loading />
                </span>
              )}
            </div>
            <input
              type='text'
              className='input'
              maxLength={50}
              placeholder='링크..'
              {...register('social.link', {
                required: {
                  value: true,
                  message: '링크는 필수입니다.',
                },
              })}
            />
          </div>
        )}

        {thumbLink && (
          <div className='flex-col gap-3'>
            <label htmlFor=''>
              썸네일 (이미지 링크를 입력하거나 하단에서 이미지 추가)
            </label>
            <input
              type='text'
              className='input'
              placeholder=''
              {...register('social.thumbLink', {})}
            />
            {thumbLink && (
              <div>
                <img src={thumbLink} className='w-1/2' />
              </div>
            )}
          </div>
        )}

        <div className='flex-col gap-3'>
          <label htmlFor=''>제목</label>
          <input
            type='text'
            className='input'
            maxLength={60}
            placeholder='제목...'
            {...register('post.title', {
              required: {
                value: true,
                message: '제목은 필수입니다.',
              },
            })}
          />
        </div>

        <div className='flex-col gap-3'>
          <label htmlFor=''>내용</label>
          <Textarea
            className='textarea-bordered min-h-[7rem]'
            placeholder='예쁜고 착한 글 적어주세요. 커뮤니티 가이드라인 위반시 계정이 차단될 수 있습니다...'
            {...register('post.desc', {
              required: {
                value: true,
                message: '내용은 필수입니다.',
              },
            })}
          />
        </div>

        <div className='py-3'>
          <div className='w-full'>
            {previews.map((preview, idx) => {
              return (
                <div key={`post-image-${idx}`} className='relative w-1/3'>
                  <div className='absolute w-0 h-0 overflow-visible -top-3 right-4'>
                    <span
                      className='block w-8 h-8 bg-white rounded-full'
                      onClick={() => {
                        setValue('post.images', []);
                        setValue('previews', []);
                      }}
                    >
                      <IoMdCloseCircle size={32} />
                    </span>
                  </div>
                  <img src={preview} />
                </div>
              );
            })}
          </div>
          <div>
            <Dropzone
              accept={{
                'image/*': [],
              }}
              multiple={false}
              onDrop={onDrop}
            >
              {({ getRootProps, getInputProps }) => (
                <div className={classNames('pt-4')} {...getRootProps()}>
                  <div className='flex-col gap-1'>
                    <button className='gap-2 btn btn-outline'>
                      <FaPlus size={14} />{' '}
                      <span>{imgSelected ? '다시선택' : '이미지'}</span>
                    </button>

                    <div className='text-center opacity-50'>
                      <span>* 지금은 한 장만 받을게요 🐠 </span>
                    </div>
                  </div>
                  <input {...getInputProps()} />
                </div>
              )}
            </Dropzone>
          </div>
          <div className='h-10'></div>
        </div>
      </div>
    </Modal>
  );
}

export default ModalPostSocialForm;
