import { useEffect, useState } from 'react';

export function useRerenderInterval({ intervalMs }: { intervalMs: number }) {
  const [renderKey, setRenderKey] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setRenderKey(renderKey + 1);
    }, intervalMs);

    return () => clearInterval(interval);
  }, [renderKey]);

  return { renderKey, setRenderKey } as const;
}
