import { memo, useState } from 'react';
import { BiReply } from 'react-icons/bi';
import envs from '../../../common/envs';
import { useAppRouter } from '../../../common/hooks/use-app-router';
import { useRerenderInterval } from '../../../common/hooks/use-rerender-interval';
import {
  CommentFragment,
  NotiFragment,
  useUpdateNotiMutation,
} from '../../../generated/graphql';
import CommentEditForm from '../../../modules/post/CommentEditForm';
import CommentReplyForm from '../../../modules/post/CommentReplyForm';
import { ClientModel, convToRender } from '../../../types/gql-enhanced-types';
import NotiComment from './NotiComment';

function NotiItem({
  noti,
  noReply,
}: {
  noReply?: boolean;
  noti: ClientModel<NotiFragment>;
}) {
  const { nav } = useAppRouter();

  useRerenderInterval({
    intervalMs: envs.isProd ? 1 * 1000 : 1000 * 1000,
  });

  const comment = convToRender(noti?.comment?.data);
  const post = convToRender(noti?.post?.data);
  const [openReply, setOpenReply] = useState(false);
  const [openReplyEdit, setOpenReplyEdit] = useState(false);

  const [newReply, setNewReply] = useState<CommentFragment | null | undefined>(
    null,
  );

  const [updateNoti, { loading: reading }] = useUpdateNotiMutation({
    fetchPolicy: 'network-only',
  });

  if (!comment) {
    return null;
  }

  const newReplyC = convToRender(newReply);
  function handleRead() {
    if (reading || !noti.id) return;

    updateNoti({
      variables: {
        id: noti.id,
        data: {
          read: true,
          seen: true,
        },
      },
    });
  }

  return (
    <li key={noti?.id} className='flex bg-base-200 rounded-lg p-3 w-full'>
      <div className='flex-col overflow-x-hidden min-w-0 w-full'>
        {!noti.seen && (
          <div className='relative h-1 overflow-visible'>
            <div className='absolute overflow-visible right-0'>
              <span className='block w-2 h-2 rounded-full bg-red-400'></span>
            </div>
          </div>
        )}
        {noti.seen && !noti.read && (
          <div className='relative h-1 overflow-visible'>
            <div className='absolute overflow-visible right-0'>
              <span className='block w-2 h-2 rounded-full bg-primary'></span>
            </div>
          </div>
        )}
        <div className='flex-col overflow-x-hidden min-w-0 w-full gap-2'>
          {comment && (
            <>
              <NotiComment
                comment={comment}
                post={post}
                onClick={() => {
                  if (newReply) return;

                  handleRead();
                  setOpenReply(!openReply);
                }}
                onPostClick={() => {
                  if (post) {
                    nav(`/posts/${post.category}/${post.id}`);
                  }
                }}
              />
              {!newReply && !openReply && (
                <div className='flex justify-end gap-2'>
                  {!noti.read && (
                    <>
                      <button
                        className='link no-underline text-primary'
                        onClick={() => {
                          handleRead();
                        }}
                      >
                        읽음 처리
                      </button>
                      {' · '}
                    </>
                  )}
                  <button
                    className='link no-underline text-primary'
                    onClick={() => {
                      if (newReply) return;

                      handleRead();
                      setOpenReply(!openReply);
                    }}
                  >
                    댓글달기
                  </button>
                </div>
              )}
            </>
          )}
        </div>

        {!noReply && (
          <>
            {openReply && noti.comment?.data && (
              <div className='flex flex-nowrap gap-2 border-t mt-2 pt-2'>
                <div className='pt-1'>
                  <BiReply className='-rotate-180' size={23} />
                </div>
                <div className='flex-1 flex-col'>
                  <CommentReplyForm
                    onLeafComment
                    targetId={noti.targetId}
                    replyToComment={noti.comment?.data.id}
                    replyTo={noti.comment?.data.attributes?.creator?.data}
                    comment={noti.comment?.data}
                    commentToId={
                      noti.comment?.data?.attributes?.commentTo?.data?.id
                    }
                    onCancel={() => {
                      setOpenReply(false);
                    }}
                    onSuccess={(newComment) => {
                      setNewReply(newComment.createComment?.data);
                      setOpenReply(false);
                    }}
                  />
                </div>
              </div>
            )}
          </>
        )}

        {!openReplyEdit && newReplyC && (
          <div className='flex flex-nowrap gap-2 border-t pt-2 mt-2'>
            <div className='pt-1'>
              <BiReply className='-rotate-180' size={23} />
            </div>
            <div className='flex-col flex-1'>
              <NotiComment
                comment={newReplyC}
                reply
                onEditClick={() => {
                  if (!newReplyC) return;

                  setOpenReplyEdit(true);
                }}
              />
            </div>
          </div>
        )}

        {openReplyEdit && newReply && (
          <div className='flex flex-nowrap gap-2 border-t mt-2 pt-2'>
            <div className='pt-1'>
              <BiReply className='-rotate-180' size={23} />
            </div>
            <div className='flex-1 flex-col'>
              <CommentEditForm
                comment={newReply}
                onSuccess={(newR) => {
                  setOpenReplyEdit(false);
                  if (newR) {
                    setNewReply(newR);
                  }
                }}
              />
            </div>
          </div>
        )}
      </div>
    </li>
  );
}

export default memo(NotiItem);
