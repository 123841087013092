import classNames from 'classnames';
import { isEmpty } from 'lodash';
import { memo } from 'react';
import { useNavigate } from 'react-router';
import LoadingIcon from '../../components/loading/LoadingIcon';
import { usePostsQuery } from '../../generated/graphql';
import { convList, convToRender, TTrade } from '../../types/gql-enhanced-types';
import { notEmpty } from '../../utils/array';
import PostTradeCard from './PostTradeCard';

function PostTradeSection({ trade }: { trade: TTrade }) {
  const nav = useNavigate();

  const creatorId = trade.creator?.data?.id;

  const { data, loading: loadingQuery } = usePostsQuery({
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      filters: {
        creator: {
          id: {
            eq: creatorId,
          },
        },
        deleted: {
          eq: false,
        },
        category: {
          eq: 'trade',
        },
        trades: {
          sold: {
            eq: false,
          },
        },
      },
      pagination: {
        pageSize: 100,
      },
    },
  });

  const items = convList(data?.posts?.data).filter((p) => {
    const pTrade = p?.trades?.data?.[0];
    if (!pTrade) return false;

    // return true;
    return pTrade.id !== trade.id;
  });

  const loading = loadingQuery && isEmpty(items);

  return (
    <div className='flex-col gap-5'>
      <PostTradeCard trade={trade} />

      {loading && (
        <div className='flex-center p-4 border'>
          <LoadingIcon />
        </div>
      )}
      {!loading && notEmpty(items) && (
        <div className='flex-col gap-4'>
          <div className='font-bold text-lg '>분양중인 다른 품목들</div>
          <div className='flex-col border rounded-xl'>
            {items.map((post, idx) => {
              const item = convToRender(post?.trades?.data?.[0]);
              if (!item) return null;

              const viewLink = `/posts/trade/${post.id}`;

              return (
                <div
                  key={post.id}
                  className={classNames(
                    'p-2 bg-blue-50 cursor-pointer',
                    idx !== 0 && 'border-t',
                  )}
                  onClick={() => {
                    nav(viewLink);
                  }}
                >
                  <PostTradeCard trade={item} summary />
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}

export default memo(PostTradeSection);
