export const colorsOptions = [
  {
    color: 'black',
    label: '검정',
    hexCode: '#000000',
  },
  {
    color: 'white',
    label: '흰색',
    hexCode: '#ffffff',
  },
  {
    color: 'silver',
    label: '은색',
    hexCode: '#e1e1e1',
  },
  {
    color: 'gray',
    label: '회색',
    hexCode: '#9b9b9b',
  },
  {
    color: 'pink',
    label: '분홍',
    hexCode: '#ff84b0',
  },
  {
    color: 'red',
    label: '빨강',
    hexCode: '#ff0000',
  },
  {
    color: 'orange',
    label: '오렌지',
    hexCode: '#ff7900',
  },
  {
    color: 'brown',
    label: '갈색',
    hexCode: '#9c540f',
  },
  {
    color: 'yellow',
    label: '노랑',
    hexCode: '#ffe800',
  },
  {
    color: 'green',
    label: '초록',
    hexCode: '#91e937',
  },
  {
    color: 'blue',
    label: '파랑',
    hexCode: '#007cff',
  },
  {
    color: 'translucent',
    label: '투명',
    hexCode: 'transparent',
  },
];

export function findColorOption(color?: string | null) {
  return colorsOptions.find((o) => o.color === color);
}

export const darkColors = ['brown', 'black', 'gray', 'blue', 'red'];
