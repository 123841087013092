import { memo, useMemo } from 'react';
// import { usePrivacyPolicyQuery } from '../generated/graphql';

import './index-md.scss';
import { convertMdToHtml } from './sanitizeHtml';

function PrivacyPolicy() {
  // const { data } = usePrivacyPolicyQuery();

  const data = '';
  const html = useMemo(() => convertMdToHtml(data || ''), [data]);

  return (
    <div className='md-content'>
      <div
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: html }}
      />
    </div>
  );
}

export default memo(PrivacyPolicy);
