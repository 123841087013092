import { memo, useState } from 'react';
import Card from '../../components/Card';
import ImgAvatar from '../../components/ImgAvatar';
import { useUserStatsQuery } from '../../generated/graphql';
import { abbreviateNumber } from '../../utils/number';
import useAuth from '../auth/use-auth';
import PostsListFull from '../post/PostsListFull';
import { User } from './type';

function ProfileView({ user }: { user?: User | null }) {
  const [{ userId }, { nav }] = useAuth();

  const [tab, setTab] = useState<'followers' | 'following' | null>(null);
  const { data } = useUserStatsQuery({
    variables: {
      userId: user?.id || '',
    },
    skip: !user?.id,
    fetchPolicy: 'cache-and-network',
  });

  const stats = data?.userStats;
  const isOwner = user?.id === userId;

  if (!user) {
    return null;
  }

  return (
    <div className='flex flex-col gap-[20px]'>
      <Card className='gap-5'>
        <div className='flex justify-between'>
          <div>
            <ImgAvatar
              className='w-20'
              key={user.profileUrl}
              img={user.profileUrl}
            />
          </div>
          {isOwner && (
            <div>
              <button
                className='btn btn-primary'
                onClick={() => {
                  nav('/profile/edit');
                }}
              >
                수정
              </button>
            </div>
          )}
        </div>
        <div>
          <h3 className='font-bold text-2xl'>{user.displayName}</h3>
          <span className='opacity-50'>@{user.username}</span>
        </div>

        {user?.bio && <div>{user?.bio}</div>}

        {/* stats */}
        <div className='flex items-center gap-3 lg:gap-5 text-sm'>
          <div
            className='flex gap-1 cursor-pointer'
            onClick={() => {
              setTab(null);
            }}
          >
            <span className='font-bold'>
              {stats?.posts != null ? abbreviateNumber(stats?.posts) : '-'}
            </span>
            <span className='opacity-50'>Posts</span>
          </div>
        </div>
      </Card>

      {!tab && <PostsListFull creatorId={user.id} />}

      <div className='h-24'></div>
    </div>
  );
}

export default memo(ProfileView);
