// 1. Merge id and attributes

import { DeepPartial } from '@apollo/client/utilities';
import { pick } from 'lodash';
import {
  AquaticFragment,
  CommentFragment,
  Enum_Post_Category,
  Enum_Report_Type,
  NotiFragment,
  PostFragment,
  ReportInput,
  TradeFragment,
  UploadFileFragment,
} from '../generated/graphql';
import { FormatsEnum } from '../types';
import { notNull } from '../utils/array';
import { getSizedMediaLink } from '../utils/image';
import { Id } from './shared-types';

export type PostCategory = `${Enum_Post_Category}` | 'all';

// 2. Enhancing MySql JSON column type
export type ClientModel<
  T extends { id?: string | null; attributes?: T['attributes'] },
> = {
  id?: T['id'] | null;
} & T['attributes'];

export type TNoti = ClientModel<NotiFragment>;

export type TAquatics = Omit<ClientModel<AquaticFragment>, 'colors'> & {
  colors?: string[] | null;
};

export type TPost = Omit<
  ClientModel<PostFragment>,
  'questionTreeKeys' | 'organization'
> & {
  p?: string;
};

export type TImage = {
  width?: number | null;
  height?: number | null;
  thumb?: string | null;
  url: string;
};

export type TComment = ClientModel<CommentFragment>;

export type TTrade = ClientModel<TradeFragment>;

export type GqlInput<T> =
  | T & {
      id?: Id;
    };
export type TReportInput = GqlInput<ReportInput>;
export type MaybeTReportInput = GqlInput<ReportInput> | null;

export type TReportType = `${Enum_Report_Type}`;

export function convToRender<
  T extends { id?: string | null; attributes?: T['attributes'] },
>(data?: T | null): ClientModel<T> | null {
  if (data == null) return null;

  return {
    id: data?.id,
    ...data?.attributes,
  };
}

export function convList<
  T extends { id?: string | null; attributes?: T['attributes'] },
>(data?: T[] | null): ClientModel<T>[] {
  if (data == null) return [];

  return data.map(convToRender).filter(notNull);
}

export function convAquatic(
  obj?: DeepPartial<AquaticFragment> | AquaticFragment | null,
) {
  return convToRender(obj) as TAquatics | null;
}

export function convertUploadToTImage(
  image?: TImage | UploadFileFragment | string,
): TImage | undefined {
  if (image == null) return image;

  if (typeof image === 'string') {
    return {
      thumb: image,
      url: image,
    };
  }

  if ('url' in image) return image as TImage;

  const url = getSizedMediaLink({
    upload: image,
    size: FormatsEnum.large,
  });
  if (url == null) return undefined;

  return {
    ...pick(image.attributes, ['width', 'height']),
    thumb: getSizedMediaLink({
      upload: image,
      size: FormatsEnum.thumbnail,
    }),
    url,
  };
}

export function convertUploadToTImages(
  uploads: (TImage | UploadFileFragment | string)[],
): TImage[] {
  return uploads.map(convertUploadToTImage).filter(notNull);
}
