import { memo, useMemo, useRef } from 'react';

import classNames from 'classnames';
import { omit } from 'lodash';
import { SubmitHandler, useForm } from 'react-hook-form';
import appConfig from '../../app/appConfig';
import Textarea from '../../components/form/Textarea';
import {
  CommentFragment,
  CommentInput,
  useUpdateCommentMutation,
} from '../../generated/graphql';
import { onError } from '../../utils/apollo-utils';
import useAuth from '../auth/use-auth';
import { Comment } from '../users/type';
import { initiateLogin } from '../users/utils/auth-actions';

type Inputs = CommentInput & { id: string | null | undefined };

function CommentEditForm({
  onSuccess,
  comment,
}: {
  onSuccess: (c?: Comment | null) => void;
  comment: CommentFragment;
}) {
  const [{ user, isLoggedIn }] = useAuth();

  const ref = useRef<HTMLFormElement>(null);

  const commentInput = useMemo(() => {
    return {
      id: comment?.id,
      creator: comment?.attributes?.creator?.data?.id,
      content: comment?.attributes?.content,
      private: comment?.attributes?.private,
    };
  }, []);

  const { register, handleSubmit, reset, watch } = useForm<Inputs>({
    defaultValues: {
      ...commentInput,
    },
  });

  const content = watch('content');

  const [updateComment, { loading: updating }] = useUpdateCommentMutation({
    onError,
    onCompleted: (data) => {
      onSuccess(data.updateComment?.data);
      reset();
    },
  });

  const onSubmit: SubmitHandler<Inputs> = async (values) => {
    if (updating || !values.id) return;

    if (!isLoggedIn) {
      initiateLogin();
      return;
    }

    const optimisticResponse = {
      updateComment: {
        __typename: 'CommentEntityResponse',
        data: {
          id: comment.id,
          attributes: {
            ...comment.attributes,
            content: values.content || '',
          },
          __typename: 'CommentEntity',
        },
      },
    } as const;

    updateComment({
      variables: {
        id: values.id,
        data: {
          ...omit(values, ['id']),
          creator: user?.id,
        },
      },
      optimisticResponse,
    });

    onSuccess();
  };

  return (
    <form
      ref={ref}
      onSubmit={handleSubmit(onSubmit)}
      className='pr-[15px] lg:pr-[30px]'
    >
      <div className='card flex flex-col border rounded-sm'>
        <Textarea
          placeholder='요기가 댓글 쓰는 곳'
          className='textarea border-0 outline-none focus:outline-none resize-none min-h-3 leading-5 pt-[16px] px-[16px] pb-0'
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              const isCmdEnter = e.metaKey && !e.ctrlKey; // Cmd+Enter on Mac
              const isCtrlEnter = !e.metaKey && e.ctrlKey; // Ctrl+Enter on Windows/Linux

              if (isCmdEnter || isCtrlEnter) {
                e.preventDefault();
                ref.current?.dispatchEvent(
                  new Event('submit', { cancelable: true, bubbles: true }),
                );
              }
            }
          }}
          {...register('content', {
            required: {
              value: true,
              message: 'required',
            },
          })}
          maxLength={appConfig.comment.maxLength}
        ></Textarea>
        <div className='bg-base-100 text-right flex-cy gap-1 justify-end'>
          <div className='flex-cy gap-1'>
            <input
              id={`private-${comment.id}`}
              type='checkbox'
              className='toggle toggle-xs'
              {...register('private')}
            />
            <label
              htmlFor={`private-${comment.id}`}
              className='cursor-pointer text-sm'
            >
              비밀
            </label>
          </div>
          <button
            type='button'
            className={classNames('btn btn-sm btn-ghost font-bold ')}
            onClick={() => {
              if (comment.attributes?.content !== content) {
                const yes = window.confirm('취소할까요?');
                if (!yes) return;
              }

              onSuccess();
            }}
          >
            취소
          </button>
          <button
            className={classNames('btn btn-sm btn-ghost font-bold ', {
              'opacity-30': !content,
            })}
            type='submit'
          >
            등록
          </button>
        </div>
      </div>
    </form>
  );
}

export default memo(CommentEditForm);
