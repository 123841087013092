/* eslint-disable react/jsx-no-useless-fragment */
import classNames from 'classnames';
import { ReactElement, cloneElement, memo } from 'react';
import useScrollReactor from '../../common/hooks/use-scroll-reactor';

function StickyHeader({ children }: { children: ReactElement }) {
  const { isScrollAtTop, isScrollingDown, isScrollingUp } = useScrollReactor();

  // FIXED HOVERING HEADER
  const child1 = cloneElement(children, {
    className: classNames(
      children.props.className,
      'fixed transition-all z-20',
      !isScrollAtTop && isScrollingDown && '-top-44',
      !isScrollAtTop && isScrollingUp && 'fixed -top-[1px]',
      isScrollAtTop && 'hidden',
    ),
  });

  // PLACEHOLDER AT TOP OF THE PAGE
  const child2 = cloneElement(children, {
    className: classNames(
      children.props.className,
      'z-10 relative',
      isScrollingDown && !isScrollAtTop && 'opacity-0',
      isScrollAtTop && 'opacity-100',
    ),
  });

  return (
    <>
      {child1}
      {child2}
    </>
  );
}

export default memo(StickyHeader);
