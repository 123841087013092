import { memo } from 'react';
import { HomePagePart, useCalcHomePageMutation } from '../../generated/graphql';

function SuperAdminPage() {
  const [calcHomePage] = useCalcHomePageMutation({
    fetchPolicy: 'network-only',
  });

  return (
    <div className='flex flex-col gap-[20px] p-6'>
      <div>
        <button
          className='btn'
          onClick={() => {
            calcHomePage({
              variables: {
                type: HomePagePart.Trending,
              },
            });
          }}
        >
          pick trending posts
        </button>
        <button
          className='btn'
          onClick={() => {
            calcHomePage({
              variables: {
                type: HomePagePart.Aquatics,
              },
            });
          }}
        >
          pick new Aquatics
        </button>
      </div>
    </div>
  );
}

export default memo(SuperAdminPage);
