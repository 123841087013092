import classNames from 'classnames';
import { HTMLAttributes, ReactNode } from 'react';
import { useNavigate } from 'react-router';

import ImgAvatar from '../../components/ImgAvatar';
import { User } from './type';

export default function Profile({
  img,
  name: pName,
  subtitle,
  to,
  viewOnly,
  noImage,
  blocked,
}: {
  img?: User['profileUrl'];
  name?: ReactNode;
  subtitle?: ReactNode;
  to?: string;
  viewOnly?: boolean;
  noImage?: boolean;
  blocked?: boolean;
} & HTMLAttributes<HTMLDivElement>) {
  const navigate = useNavigate();
  const name = pName || '삭제된 사용자';

  return (
    <div
      className={classNames('flex w-full gap-2 flex-center-y overflow-hidden', {
        'cursor-pointer': to,
      })}
      onClick={
        viewOnly
          ? undefined
          : () => {
              if (to) {
                navigate(to);
              }
            }
      }
    >
      {noImage !== true && <ImgAvatar key={img} img={blocked ? null : img} />}
      <div className='flex flex-col flex-auto w-full overflow-hidden'>
        <div className='flex-auto w-full overflow-hidden text-sm'>
          <div className='w-full overflow-hidden font-bold whitespace-nowrap text-ellipsis'>
            {blocked ? '차단사용자' : name}
          </div>
        </div>
        {!blocked && subtitle && (
          <span className='hidden overflow-hidden text-sm opacity-60 whitespace-nowrap text-ellipsis'>
            {subtitle || ''}
          </span>
        )}
      </div>
    </div>
  );
}
