import { DependencyList, RefObject, useEffect } from 'react';

export function useGlobalKeydown(
  {
    ref,
    onEscapeKey,
    onEnterKey,
    onClick,
    onKeydown,
    onClickOutside,
  }: {
    ref?: RefObject<HTMLElement>;
    onEnterKey?: () => void;
    onEscapeKey?: () => void;
    onClick?: (e: MouseEvent) => void;
    onKeydown?: (e: KeyboardEvent) => void;
    onClickOutside?: (e: MouseEvent) => void;
  },
  deps: DependencyList,
) {
  useEffect(() => {
    function handleKeyDown(e: KeyboardEvent) {
      if (onEnterKey && e.code === 'Enter') {
        onEnterKey();
      }
      if (onEscapeKey && e.code === 'Escape') {
        onEscapeKey();
      }
      if (onKeydown) {
        onKeydown(e);
      }
    }

    function handleClick(e: MouseEvent) {
      if (onClick) {
        onClick(e);
      }
    }

    function handleClickOutside(e: MouseEvent) {
      if (ref?.current && !ref?.current.contains(e.target as Node)) {
        onClickOutside?.(e);
      }
    }

    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('mouseup', handleClick);
    document.addEventListener('mouseup', handleClickOutside);

    return function cleanup() {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('mouseup', handleClick);
      document.removeEventListener('mouseup', handleClickOutside);
    };
  }, deps);
}
