import { useState } from 'react';

import { MdAdd } from 'react-icons/md';
import { RiQuestionnaireLine } from 'react-icons/ri';
import { Action, Fab } from 'react-tiny-fab';
import 'react-tiny-fab/dist/styles.css';
import LogoBlinkerCenter from '../../components/loading/LogoBlink';
import { Enum_Post_Category } from '../../generated/graphql';
import useAuth from '../auth/use-auth';
import ModalPostForm from '../post/ModalPostForm';
import SignInModalWrap from '../users/SignInModal';

function AquaticActionMenu({ aquaticId }: { aquaticId: string }) {
  const [{ isLoggedIn, authInit, loading }] = useAuth();

  // const loc = useLocation();
  // const isMarketRoute = startsWith(loc.pathname, '/trades');
  const [authModalOpen, setAuthModalOpen] = useState(false);
  // const isCommunityRoute = startsWith(loc.pathname, '/posts');

  const [addPostCategory, setAddPostCategory] =
    useState<Enum_Post_Category | null>(null);

  if (!authInit || loading) {
    return (
      <div className='justify-center flex-1'>
        <LogoBlinkerCenter />
      </div>
    );
  }

  return (
    <div className='fixed z-20 block w-full h-0 max-w-2xl overflow-visible -bottom-36'>
      <div className='relative w-full'>
        <Fab icon={<MdAdd size={35} className='' />} alwaysShowTitle>
          <Action
            text='생물게시판'
            onClick={() => {
              if (!isLoggedIn) {
                setAuthModalOpen(true);
                return;
              }

              setAddPostCategory(Enum_Post_Category.Opinion);
            }}
          >
            <RiQuestionnaireLine />
          </Action>
        </Fab>
        {addPostCategory === Enum_Post_Category.Opinion && (
          <ModalPostForm
            category={addPostCategory}
            post={{
              aquatic: {
                data: {
                  id: aquaticId,
                },
              },
            }}
            onSuccess={() => {
              setAddPostCategory(null);
            }}
            onClose={() => {
              setAddPostCategory(null);
            }}
          />
        )}

        {authModalOpen && <SignInModalWrap open setOpen={setAuthModalOpen} />}
      </div>
    </div>
  );
}

export default AquaticActionMenu;
