import { pick } from 'lodash';
import { User } from './type';

export const userCommonFields = [
  'id',
  'username',
  'displayName',
  'profileUrl',
  'email',
  'bio',
];

export const notiFields = [
  'notiEmail',
  'notiFrequency',
  'notiPostsOfFollowing',
  'notiOnFollowMe',
  'notiOnLikes',
  'notiOnComments',
];

export const userConfig = {
  bioMax: 250,
  fieldsEditable: [...userCommonFields, ...notiFields],
};

export function sanitizeFields(
  user: User | null | undefined,
  fields: string[]
) {
  return pick(user, fields);
}
