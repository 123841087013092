import {
  CommentFragment,
  PostFragment,
  UserFragment,
  UserMeFragment,
} from '../../generated/graphql';

export type User = UserMeFragment;
export type Post = PostFragment;
export type Comment = CommentFragment;
export type Id = PostFragment['id'];

export function consolidateUser(
  user?: UserFragment | User | null
): User | undefined | null {
  if (user?.__typename === 'UsersPermissionsUserEntity') {
    return {
      id: user.id,
      ...user.attributes,
    } as User;
  }

  return user as User;
}
