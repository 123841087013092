import { isEmpty, trim } from 'lodash';
import { memo } from 'react';
import { SingleValue } from 'react-select';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { useDebouncedCallback } from 'use-debounce';
import {
  useAquaticsLazyQuery,
  useCreateAquaticMutation,
} from '../../generated/graphql';
import { getAqNeedValidationCondition } from '../../modules/aquatics/aquatics-query-params';
import useAuth from '../../modules/auth/use-auth';
import { onError } from '../../utils/apollo-utils';
import { notNull } from '../../utils/array';
import { SelectOption } from './selector-types';

function AquaticsSelector({
  value,
  onChange,
  placeholder,
}: {
  value?: SelectOption | null;
  onChange: (o: SingleValue<SelectOption>) => void;
} & {
  placeholder?: string;
}) {
  const [{ userId }] = useAuth();

  const [fetchAquatics] = useAquaticsLazyQuery({
    // fetchPolicy: 'cache-first',
    // fetchPolicy: 'cache-and-network',
    fetchPolicy: 'network-only',
  });

  const [createAquatics, { loading: creating }] = useCreateAquaticMutation({
    onError,
    onCompleted(data) {
      onChange({
        label: data?.createAquatic?.data?.attributes?.name || '',
        value: data?.createAquatic?.data?.id || '',
      });
    },
  });

  const loadOptions = (
    inputValue: string,
    callback: (options: SelectOption[]) => void,
  ) => {
    fetchAquatics({
      variables: {
        filters: {
          or: [
            {
              name: {
                containsi: inputValue,
              },
            },
            {
              otherNames: {
                containsi: inputValue,
              },
            },
          ],
          and: [
            {
              deleted: {
                eq: false,
              },
            },
            getAqNeedValidationCondition({ userId }),
          ],
        },
      },
      onCompleted(data) {
        const list =
          data?.aquatics?.data
            .map((tr) => {
              const name = tr.attributes?.name;
              const label = `${name}`;

              const nValue = tr.id;
              if (!nValue || !label) return null;
              if (label) {
                return {
                  value: nValue,
                  label,
                };
              }
              return null;
            })
            .filter(notNull) || [];

        callback(list);
      },
    });
  };

  const loadSuggestions = useDebouncedCallback(loadOptions, 700);

  return (
    <AsyncCreatableSelect
      loadOptions={loadSuggestions}
      isSearchable
      isClearable
      value={value || null}
      isLoading={creating}
      placeholder={placeholder || '생물을 검색하세요.'}
      loadingMessage={() => '로딩중 ...'}
      noOptionsMessage={({ inputValue }) => {
        if (isEmpty(inputValue)) {
          return '검색어를 입력해주세요.';
        }
        return '검색 결과가 없습니다.';
      }}
      styles={{
        placeholder: (provided) => ({
          ...provided,
          fontSize: '1.1rem',
        }),
        input: (provided) => ({
          ...provided,
          fontSize: '1.1rem',
          lineHeight: '1.6rem',
        }),
      }}
      formatCreateLabel={(inputValue) => `새로 추가하기: ${inputValue}`}
      onCreateOption={async (newName) => {
        if (isEmpty(trim(newName))) return;

        // create an aquatics and send it back
        createAquatics({
          variables: {
            data: {
              name: trim(newName),
            },
          },
        });
      }}
      className='min-w-[16rem]'
      onChange={(o) => {
        onChange(o);
      }}
    />
  );
}

export default memo(AquaticsSelector);
