import classNames from 'classnames';
import { HTMLAttributes, memo, useState } from 'react';
import profileDefault from '../assets/profile-default.png';
import { getMediaUrl } from '../utils/image';

const baseCls = 'bg-base-300 text-neutral-content rounded-full';

function ImgAvatar({
  img: imgRaw,
  className = 'w-10',
  indiLabel,
  ...props
}: {
  img?: string | null;
  indiLabel?: string;
} & HTMLAttributes<HTMLDivElement>) {
  const [img, setImg] = useState(imgRaw);

  if (!img) {
    return (
      <div className='indicator' {...props}>
        {indiLabel && (
          <span className='indicator-item indicator-start border-none bg-red-500 text-[11px] top-1 left-1 px-[6px] py-[1px] rounded-full text-white font-bold'>
            {indiLabel}
          </span>
        )}
        <div className='avatar placeholder'>
          <div className={classNames(baseCls, className)}>
            <img src={profileDefault} className='w-full' />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className='avatar placeholder' {...props}>
      <div className={classNames(baseCls, className)}>
        <img
          src={getMediaUrl(img)}
          referrerPolicy='no-referrer'
          onError={() => {
            setImg('');
          }}
        />
      </div>
    </div>
  );
}

export default memo(ImgAvatar);
