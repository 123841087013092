import { memo } from 'react';

import { isEmpty, size, trim } from 'lodash';
import { Waypoint } from 'react-waypoint';
import appConfig from '../../app/appConfig';
import Card from '../../components/Card';
import { IconGhost } from '../../components/Icons';
import LoadingIcon from '../../components/loading/LoadingIcon';
import { usePostsQuery, useUsersQuery } from '../../generated/graphql';
import { prepPosts } from '../../gql-tools/convert';
import { usePageLoadMore } from '../../graphql/use-page-load-more';
import FollowButton from '../follow/FollowButton';
import PostCard from '../post/PostCard';
import ProfileUser from '../users/ProfileUser';
import useSearchKey from './use-search-key';

const { postsPageSize: pageSize } = appConfig.search;

function SearchPage({
  searchKey,
  closeModal,
}: {
  searchKey?: string;
  closeModal?: () => void;
}) {
  const [{ searchParam }, { navigate }] = useSearchKey();

  const searchTerm = searchKey || searchParam;

  const { data: usersData, loading: loadingUsers } = useUsersQuery({
    skip: !trim(searchTerm),
    variables: {
      filters: {
        or: [
          {
            displayName: {
              containsi: searchTerm,
            },
          },
          {
            username: {
              containsi: searchTerm,
            },
          },
          {
            bio: {
              containsi: searchTerm,
            },
          },
        ],
      },
    },
  });

  const users = usersData?.usersPermissionsUsers?.data || [];

  const {
    data: postData,
    loading: loadingPadcast,
    fetchMore,
  } = usePostsQuery({
    skip: !trim(searchTerm),
    variables: {
      filters: {
        desc: {
          containsi: searchTerm,
        },
      },
      sort: ['createdAt:DESC', 'id:DESC'],
      pagination: {
        page: 1,
        pageSize,
      },
    },
  });

  const loading = loadingUsers || loadingPadcast;
  const posts = prepPosts(postData);

  const overThreshold = size(users) > appConfig.search.seeAllThreshold;

  const usersToRender = users.slice(0, appConfig.search.seeAllThreshold);

  const [{ hasMore, loadingMore }, { loadMore }] = usePageLoadMore({
    fetchMore,
    loading: loadingPadcast,
    pageSize,
    listLength: size(posts),
  });

  return (
    <div className='flex flex-col gap-[20px]'>
      <Card className='gap-5'>
        {loading && (
          <>
            <span className='font-bold text-2xl'>Search</span>

            <div className='flex-center-x p-10'>
              <LoadingIcon />
            </div>
          </>
        )}

        {!loading && (
          <h3 className='flex justify-between items-baseline'>
            {isEmpty(usersToRender) && isEmpty(posts) ? (
              <span className='font-bold text-2xl'>Search</span>
            ) : (
              <span className='font-bold text-2xl'>People</span>
            )}
            {!loading && overThreshold && (
              <span
                className='font-bold text-sm cursor-pointer'
                onClick={() => {
                  if (closeModal) {
                    closeModal();
                  }
                  navigate(`/people?key=${searchTerm}`);
                }}
              >
                See All
              </span>
            )}
          </h3>
        )}

        {!loading && isEmpty(usersToRender) && (
          <div className='flex-col flex-center h-44 gap-2'>
            <IconGhost className='opacity-50' />
            <span className='opacity-50'>
              We couldn’t find any good results...
            </span>
          </div>
        )}

        {!isEmpty(usersToRender) &&
          usersToRender.map((userItem) => (
            <div key={userItem.id} className='flex flex-col gap-3'>
              <div key={userItem.id} className='flex gap-3 justify-between'>
                <div
                  className='flex justify-center bg-neutral-content rounded gap-2 cursor-pointer overflow-hidden'
                  onClick={() => {
                    navigate(`/profile/${userItem.attributes?.username}`);
                  }}
                >
                  <ProfileUser user={userItem} />
                </div>
                <div className='flex-none'>
                  <FollowButton user={userItem} />
                </div>
              </div>
              <div>{userItem?.attributes?.bio}</div>
            </div>
          ))}
      </Card>

      {!isEmpty(posts) && (
        <div className='flex flex-col gap-3'>
          {posts.map((post) => (
            <PostCard key={post.id} post={post} />
          ))}
        </div>
      )}

      {loadingMore && (
        <div className='flex-center-x p-10'>
          <LoadingIcon />
        </div>
      )}
      {hasMore && <Waypoint scrollableAncestor='window' onEnter={loadMore} />}
    </div>
  );
}

export default memo(SearchPage);
