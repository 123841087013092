import { last, uniq } from 'lodash';
import { memo } from 'react';
import Select, { MultiValue } from 'react-select';
import { RegionOptions } from './models/regions';
import { SelectOption } from './selector-types';

export const koreanCitiesAndRegions = uniq(
  RegionOptions.reduce((acc, cur) => {
    return [...acc, ...(cur.list || [])];
  }, [] as string[]),
);

function RegionSelector({
  value,
  onChange,
  placeholder,
  regions,
  excludes,
}: {
  value?: string[];
  onChange: (o: MultiValue<SelectOption>) => void;
  placeholder?: string;
  regions?: string[];
  excludes?: string[];
}) {
  const options = (regions || koreanCitiesAndRegions)
    .map((region) => ({
      value: region,
      label: region,
    }))
    .filter((o) => !excludes?.includes(o.value));

  const values = value?.map((v) => ({ value: v, label: v })) || [];

  return (
    <Select
      isSearchable
      isMulti
      value={values}
      placeholder={placeholder || '지역을 검색하여 선택하세요.'}
      noOptionsMessage={() => {
        return '검색한 지역 없음';
      }}
      options={options}
      styles={{
        placeholder: (provided) => ({
          ...provided,
          fontSize: '1.1rem',
        }),
        input: (provided) => ({
          ...provided,
          fontSize: '1.1rem',
          lineHeight: '1.6rem',
        }),
      }}
      className='min-w-[]'
      onChange={(e) => {
        const lastValue = last(e)?.value;

        if (lastValue === '전국') {
          onChange([{ value: '전국', label: '전국' }]);
          return;
        }

        if (e.find((i) => i.value === '전국')) {
          onChange(e.filter((i) => i.value !== '전국'));
          return;
        }

        onChange(e);
      }}
    />
  );
}

export default memo(RegionSelector);
