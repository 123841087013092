import { memo } from 'react';
import { MdAutoFixHigh } from 'react-icons/md';
import { useNavigate } from 'react-router';

function Four04Page() {
  const navigate = useNavigate();

  return (
    <div className='flex-center flex-col h-[30vh] gap-5 opacity-50'>
      <div>
        <MdAutoFixHigh size='5rem' />
      </div>
      <div>아이고! 이게 머선일이고~~</div>
      <div
        className='btn btn-link'
        onClick={async () => {
          navigate(-1);
        }}
      >
        이전 페이지로 돌아가기
      </div>
    </div>
  );
}

export default memo(Four04Page);
