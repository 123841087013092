import { Menu, MenuButton, MenuItem } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';
import { memo, useState } from 'react';
import { FiMoreVertical } from 'react-icons/fi';
import {
  Enum_Post_Category,
  useUpdatePostMutation,
} from '../../generated/graphql';
import { listToIds } from '../../libs/strapi/list';
import { TPost } from '../../types/gql-enhanced-types';
import { pretty } from '../../utils/debug';
import useAuth from '../auth/use-auth';
import useBlockState from '../report/hooks/use-block-state';
import useGlobalState from '../report/hooks/use-global-state';
import ModalPostSocialForm from './ModalPostSocialForm';

function PostItemMenu({
  post,
  onBlock,
}: {
  post: TPost;
  onBlock?: () => void;
}) {
  const [{ isSuperAdmin, isLoggedIn, userId }] = useAuth();
  const [, { setReportState }] = useGlobalState();
  const [, { blockUserId }] = useBlockState();

  const [openAddPost, setOpenAddPost] = useState<TPost | null>(null);

  const [updatePost] = useUpdatePostMutation({
    refetchQueries: ['Posts'],
    onCompleted() {},
  });

  const postCreatorId = post?.creator?.data?.id;
  const isOwner = userId && userId === postCreatorId;

  return (
    <>
      {openAddPost && (
        <ModalPostSocialForm
          post={openAddPost}
          category={post.category as Enum_Post_Category}
          onClose={() => {
            setOpenAddPost(null);
          }}
        />
      )}

      {isLoggedIn && (
        <div
          className='flex-center'
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
        >
          <Menu
            menuButton={
              <MenuButton>
                <FiMoreVertical size={23} />
              </MenuButton>
            }
            transition
          >
            {!isOwner && (
              <>
                <MenuItem
                  onClick={() => {
                    setReportState({
                      reportKey: `post-${post?.id}`,
                      accusedUser: postCreatorId,
                      creator: userId,
                      snapshot: pretty(post),
                      images: listToIds(post.images?.data),
                    });
                  }}
                >
                  신고하기
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    const yes = window.confirm('차단할까요?');
                    if (!yes) return;

                    blockUserId(postCreatorId);
                    onBlock?.();
                  }}
                >
                  차단하기
                </MenuItem>
              </>
            )}
            {(isSuperAdmin || isOwner) && (
              <>
                <MenuItem
                  onClick={() => {
                    setOpenAddPost(post);
                  }}
                >
                  수정
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    const yes = window.confirm('삭제 할까요?');
                    if (!yes) return;

                    if (post.id && postCreatorId) {
                      updatePost({
                        variables: {
                          id: post.id,
                          data: {
                            deleted: true,
                          },
                        },
                      });
                    }
                  }}
                >
                  삭제
                </MenuItem>
              </>
            )}
          </Menu>
        </div>
      )}
    </>
  );
}

export default memo(PostItemMenu);
