import { UploadFileFragment } from './generated/graphql';

/* eslint-disable @typescript-eslint/no-explicit-any */
declare global {
  export interface Window {
    initialLanguage?: string;
    // eslint-disable-next-line
    initialI18nStore?: Record<string, any>;
    authCallback: (tk: string | null) => void;
    env: Record<string, string>;
    rrr?: string | null;
    // eslint-disable-next-line
    __APOLLO_STATE__?: Record<string, any>;
  }
}

export type StringMap = Record<string, any>;

export interface UserProfile {
  id: number;
  profileImageUrl?: string;
  subsNews?: boolean;
  subsEvent?: boolean;
  cover?: UploadFileFragment;
}

export interface User {
  id: number;
  username: string;
  email?: string;
  provider?: string;
  confirmed?: boolean;
  blocked?: boolean;
  role?: number;
  created_at?: string;
  updated_at?: string;
  userProfile?: UserProfile;
}

export interface MediumT {
  id: number;
  name: string;
  alternativeText: string;
  caption: string;
  width: number;
  height: number;
  formats: Formats;
  hash: string;
  ext: string;
  mime: string;
  size: number;
  url: string;
  previewUrl?: null;
  provider: string;
  provider_metadata?: null;
  created_at: string;
  updated_at: string;
}
export enum FormatsEnum {
  large,
  medium,
  small,
  thumbnail,
}

export interface Formats {
  thumbnail?: ThumbnailOrLargeOrMediumOrSmall;
  large?: ThumbnailOrLargeOrMediumOrSmall;
  medium?: ThumbnailOrLargeOrMediumOrSmall;
  small?: ThumbnailOrLargeOrMediumOrSmall;
}

export interface ThumbnailOrLargeOrMediumOrSmall {
  name: string;
  hash: string;
  ext: string;
  mime: string;
  width: number;
  height: number;
  size: number;
  path?: null;
  url: string;
}

export type FileT =
  | {
      readonly size: number;
      readonly type: string;
      arrayBuffer: any;
      slice: any;
      stream: any;
      text: any;
    }
  | null
  | undefined;
