import { first, startsWith } from 'lodash';

export function getParams(url: string) {
  if (!url) {
    return null;
  }
  try {
    return new URL(url).searchParams;
  } catch (error) {
    // console.log('error', error);
  }
  return null;
}

export function getVParam(url: string) {
  if (!url) {
    return null;
  }
  const params = getParams(url);
  if (params) {
    return params.get('v');
  }
  return null;
}

export function getYoutubeVId(url?: string | null) {
  if (!url) {
    return null;
  }

  if (startsWith(url, 'https://youtu.be/')) {
    const path = url.replace('https://youtu.be/', '');
    if (path.includes('/')) {
      return first(path.split('/'));
    }
    if (path.includes('?')) {
      return first(path.split('?'));
    }
    return path;
  }

  return getVParam(url);
}
