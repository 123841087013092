import classNames from 'classnames';
import { memo } from 'react';
import { MdChevronLeft } from 'react-icons/md';
import Skeleton from 'react-loading-skeleton';
import { useNavigate } from 'react-router';
import { createArray } from '../../utils/array';
import { H3 } from '../typographic/Headers';

function SkelPostView() {
  const nav = useNavigate();

  return (
    <>
      <div
        className={classNames(
          'navbar border-b bg-base-100 max-w-2xl',
          'flex justify-between w-full p-0 border-b gap-2',
        )}
      >
        <span
          className='px-2 cursor-pointer'
          onClick={() => {
            nav(-1);
          }}
        >
          <MdChevronLeft className='' size={24} />
        </span>
        <H3
          className='flex-1 flex flex-center-y gap-3 '
          onClick={() => {
            nav(-1);
          }}
        >
          <div className='w-full px-5'>
            <Skeleton width={'100%'} height={'14px'} />
          </div>
        </H3>
        <div className='flex-none'></div>
      </div>

      <div className='p-5 flex-col gap-3'>
        <div className='flex gap-5'>
          <div className='flex-1'>
            <Skeleton className='flex-1' width={'100%'} height={'40vw'} />
          </div>
        </div>

        {/* profile  */}
        <div className='flex gap-5'>
          <div className='w-12 h-12'>
            <Skeleton circle width={'100%'} height={'100%'} />
          </div>
          <div className='flex-1'>
            <Skeleton className='flex-1' width={'100%'} height={'12px'} />
            <Skeleton className='flex-1' width={'60%'} height={'12px'} />
          </div>
        </div>

        {/* like and comment count */}
        <div className='flex gap-5'>
          <div className='flex-1 flex h-20 flex-center gap-2'>
            <div className='w-14'>
              <Skeleton className='flex-1' width={'100%'} height={'100%'} />
            </div>
            <div className='w-14'>
              <Skeleton className='flex-1' width={'100%'} height={'100%'} />
            </div>
          </div>
        </div>

        {createArray(4).map((_, i) => (
          <div key={i} className='flex gap-5'>
            <div className='w-12 h-12'>
              <Skeleton circle width={'100%'} height={'100%'} />
            </div>
            <div className='flex-1'>
              <Skeleton className='flex-1' width={'100%'} height={'12px'} />
              <Skeleton className='flex-1' width={'60%'} height={'12px'} />
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

export default memo(SkelPostView);
