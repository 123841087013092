import { isEmpty, trim } from 'lodash';

export function isNotFalsy<T>(value: T | undefined | null): value is T {
  if (value == null) {
    return false;
  }

  return true;
}

export function isNotEmpty<T>(value: T | undefined | null): value is T {
  if (typeof value === 'string') {
    return !isEmpty(trim(value));
  }

  return !isEmpty(value);
}
