import { GoogleOAuthProvider, useGoogleLogin } from '@react-oauth/google';
import { useEffect } from 'react';
import envs from '../../../common/envs';

function GoogleLogin() {
  const googleLogin = useGoogleLogin({
    flow: 'auth-code',
    ux_mode: 'redirect',
    select_account: true,
    redirect_uri: envs.auth.google.redirectUrl,
  });

  useEffect(() => {
    setTimeout(() => {
      googleLogin();
    }, 400);
  }, []);

  return <div className='flex-center flex-1'>구글 로그인 중 ...</div>;
}

// eslint-disable-next-line
function GoogleLoginPage(props: any) {
  return (
    <GoogleOAuthProvider clientId={envs.auth.google.clientId || ''}>
      <GoogleLogin {...props} />
    </GoogleOAuthProvider>
  );
}

export default GoogleLoginPage;
