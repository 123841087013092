import { IconProfile, IconSettings } from '../../components/Icons';
import { UsersPermissionsMe } from '../../generated/graphql';

export const getMenus = (user: UsersPermissionsMe | null | undefined) => {
  return [
    {
      label: '내 정보',
      to: `/profile/${user?.username}`,
      icon: <IconProfile />,
    },
    {
      label: '설정',
      to: 'settings',
      icon: <IconSettings />,
    },
  ];
};
