/* eslint-disable @typescript-eslint/no-explicit-any */

import { ApolloCache } from '@apollo/client';
import { CommentsDocument } from '../../generated/graphql';
import { insertItemAtIndex } from '../../utils/array';

export function removeComment({
  newData,
  cache,
  variables,
}: {
  newData?: any;
  cache: ApolloCache<any>;
  variables: any;
}) {
  const comments = cache.readQuery({
    query: CommentsDocument,
    variables,
  });

  // @ts-ignore
  const list: { id: string }[] = comments?.comments?.data || [];
  const nextData = list.filter((i) => i.id !== newData.id);

  cache.writeQuery({
    query: CommentsDocument,
    variables,
    data: {
      comments: {
        data: nextData,
      },
    },
    overwrite: true,
  });
}

export function addNewComment({
  newData,
  cache,
  variables,
  replyToComment,
}: {
  newData?: any;
  cache: ApolloCache<any>;
  variables: any;
  replyToComment?: string | null;
}) {
  // adding it to creator list
  const comments = cache.readQuery({
    query: CommentsDocument,
    variables,
  });

  // @ts-ignore
  const list: { id: string }[] = comments?.comments?.data || [];

  const index = list.findIndex((i) => i.id === replyToComment);

  const nextData =
    index != null && replyToComment
      ? insertItemAtIndex({
          array: list,
          index: index + 1,
          item: newData,
        })
      : [newData, ...list];

  cache.writeQuery({
    query: CommentsDocument,
    variables,
    data: {
      comments: {
        data: nextData,
      },
    },
    overwrite: true,
  });
}
