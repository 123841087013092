import { ApolloProvider } from '@apollo/client';
import { Toaster } from 'react-hot-toast';
import { RouterProvider } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { useClient } from '../graphql/client';
import AppRoutes from './AppRoutes';

import 'react-loading-skeleton/dist/skeleton.css';

export default function App() {
  const client = useClient();

  return (
    <ApolloProvider client={client}>
      <RouterProvider router={AppRoutes} />
      <ToastContainer position='bottom-center' />
      <Toaster position='bottom-center' reverseOrder={false} />
    </ApolloProvider>
  );
}
