import { HTMLAttributes, memo } from 'react';

import classNames from 'classnames';
import { MdClose } from 'react-icons/md';
import { darkColors, findColorOption } from '../models/colors';

function Color(
  props: {
    color?: string;
    deleting?: boolean;
    noLabel?: boolean;
    highlighted?: boolean;
    blur?: boolean;
  } & HTMLAttributes<HTMLSpanElement>
) {
  const {
    highlighted,
    color,
    deleting,
    className,
    blur,
    noLabel = false,
    ...restProps
  } = props;
  const colorOption = findColorOption(color);
  if (!colorOption) return null;

  return (
    <span
      className={classNames(
        'font-bold flex-center rounded-full shadow-md cursor-pointer',
        darkColors.includes(colorOption.color) ? 'text-white' : '',
        className || 'w-20 h-9 border-black',
        highlighted ? 'border-2 ' : '',
        blur ? 'opacity-80' : ''
      )}
      style={{ backgroundColor: colorOption.hexCode }}
      {...restProps}
    >
      {noLabel !== true && (
        <span className='whitespace-nowrap flex-nowrap'>
          {colorOption.label}
        </span>
      )}
      {deleting && <MdClose size={22} />}
    </span>
  );
}

export default memo(Color);
