import classNames from 'classnames';
import Spinner from 'react-spinner-material';

type SpinnerProps = {
  radius?: number | string;
  color?: string;
  stroke?: number | string;
  visible?: boolean;
};

export default function Loading({
  color,
  className,
  radius,
  stroke,
  ...restProps
}: {
  color?: string;
  className?: string;
} & SpinnerProps) {
  let nextColor = 'hsl(var(--p) / var(--tw-border-opacity))';
  if (className?.includes('border-secondary')) {
    nextColor = 'hsl(var(--s) / var(--tw-border-opacity))';
  }
  return (
    <Spinner
      {...restProps}
      radius={radius || 13}
      color={color || nextColor}
      stroke={stroke || 2}
      className={classNames('border-primary', className)}
    />
  );
}
