import classNames from 'classnames';
import mobileAvatar from '../assets/brand/logo-120.svg';

export default function Logo({ className }: { className?: string }) {
  return (
    <img
      src={mobileAvatar}
      alt='ttopet logo'
      className={classNames('', className)}
    />
  );
}
