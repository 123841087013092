/* eslint-disable @typescript-eslint/no-explicit-any */
import classNames from 'classnames';
import { Component, CSSProperties, HTMLAttributes, ReactNode } from 'react';
import ReactDOM from 'react-dom';

export default class Modal extends Component<
  {
    noTopPad?: boolean;
    open?: boolean;
    containerCls?: string;
    boxStyle?: CSSProperties | undefined;
    onClickBackdrop?: () => void;
    children: ReactNode;
  } & HTMLAttributes<HTMLDivElement>
> {
  el: any;

  constructor(props: any) {
    super(props);
    // @ts-ignore
    this.el = document.createElement('div');
  }

  componentDidMount() {
    // The portal element is inserted in the DOM tree after
    // the Modal's children are mounted, meaning that children
    // will be mounted on a detached DOM node. If a child
    // component requires to be attached to the DOM tree
    // immediately when mounted, for example to measure a
    // DOM node, or uses 'autoFocus' in a descendant, add
    // state to Modal and only render the children when Modal
    // is inserted in the DOM tree.
    //
    const modalRoot = document.getElementById('modal-root') as HTMLElement;

    modalRoot?.appendChild(this.el);
  }

  componentWillUnmount() {
    const modalRoot = document.getElementById('modal-root') as HTMLElement;
    modalRoot?.removeChild(this.el);
  }

  render() {
    const {
      noTopPad,
      className,
      onClickBackdrop,
      open,
      children,
      style,
      boxStyle,
    } = this.props;
    if (!open) {
      return ReactDOM.createPortal(null, this.el);
    }

    return ReactDOM.createPortal(
      <div
        className='modal modal-open'
        style={style}
        onClick={() => {
          if (onClickBackdrop) {
            onClickBackdrop();
          }
        }}
      >
        <div
          className={classNames(
            'modal-box w-full max-w-none max-h-screen bg-white',
            'flex-col overflow-hidden',
            'rounded-none rounded-t-2xl lg:rounded-t-none',
            'lg:max-w-lg lg:mt-0 h-full lg:h-auto',
            'mt-10 py-2 px-0',
            noTopPad && 'mt-0 rounded-t-none py-0',
            className,
          )}
          onClick={(e) => {
            e.stopPropagation();
          }}
          style={boxStyle}
        >
          {!noTopPad && (
            <div className='flex-center lg:hidden'>
              <div className='h-2 rounded-full w-1/3 bg-black bg-opacity-20'></div>
            </div>
          )}
          {children}
        </div>
      </div>,
      this.el,
    );
  }
}
