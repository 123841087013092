import { useEffect, useState } from 'react';

const useScrollAnchor = ({ topY }: { topY: number }) => {
  const [isAbove, setIsAbove] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      const isTop = scrollTop < topY;

      setIsAbove(isTop);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  function scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  return { isAbove, scrollToTop };
};

export default useScrollAnchor;
