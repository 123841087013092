import { useLocation } from 'react-router';

import classNames from 'classnames';
import { capitalize } from 'lodash';
import { HTMLAttributes } from 'react';
import { NavLink } from 'react-router-dom';
import useAuth from '../modules/auth/use-auth';
import { getMenus } from '../modules/users/menu';

function NavMenu({
  className,
  itemCls,
  onMenuClick,
}: HTMLAttributes<HTMLUListElement> & {
  itemCls?: string;
  onMenuClick?: () => void;
}) {
  const loc = useLocation();
  const isHome = loc.pathname === '/';

  const [{ isSuperAdmin, user }] = useAuth();
  const menus = getMenus(user);

  return (
    <ul className={classNames('menu menu-horizontal px-1 flex-1', className)}>
      <li>
        <NavLink
          to='/posts'
          className={({ isActive }) => {
            return classNames(
              itemCls,
              isHome || isActive ? 'font-bold text-primary' : '',
            );
          }}
          onMouseDown={() => {
            onMenuClick?.();
          }}
        >
          커뮤니티
        </NavLink>
      </li>
      <li>
        <NavLink
          to='/trades'
          className={({ isActive }) => {
            return classNames(
              itemCls,
              isActive ? 'font-bold text-primary' : '',
            );
          }}
          onMouseDown={() => {
            onMenuClick?.();
          }}
        >
          분양 장터
        </NavLink>
      </li>
      <li>
        <NavLink
          to='/pet/aquatics'
          className={({ isActive }) => {
            return classNames(
              itemCls,
              isActive ? 'font-bold text-primary' : '',
            );
          }}
          onMouseDown={() => {
            onMenuClick?.();
          }}
        >
          생물정보
        </NavLink>
      </li>

      {menus.map((menu) => (
        <li key={menu.label}>
          <NavLink
            end
            to={menu.to || menu.label.toLowerCase()}
            className={({ isActive }) => {
              return classNames(
                itemCls,
                isActive ? 'font-bold text-primary' : '',
              );
            }}
            onMouseDown={() => {
              onMenuClick?.();
            }}
          >
            {capitalize(menu.label)}
          </NavLink>
        </li>
      ))}

      {isSuperAdmin && (
        <li>
          <NavLink
            end
            to={'/super'}
            className={({ isActive }) => {
              return classNames(
                itemCls,
                isActive ? 'font-bold text-primary' : '',
              );
            }}
            onMouseDown={() => {
              onMenuClick?.();
            }}
          >
            Super
          </NavLink>
        </li>
      )}
    </ul>
  );
}

export default NavMenu;
