import axios from 'axios';
import { useEffect, useState } from 'react';
import { getYoutubeVId } from './url';

export interface YoutubeMeta {
  title: string;
  author_name: string;
  author_url: string;
  type: string;
  height: number;
  width: number;
  version: string;
  provider_name: string;
  provider_url: string;
  thumbnail_height: number;
  thumbnail_width: number;
  thumbnail_url: string;
  html: string;
}

const getMetadata = async (url: string): Promise<YoutubeMeta> => {
  // eslint-disable-next-line
  const protocol = window.location.protocol;

  return (
    await axios.get(`${protocol}//youtube.com/oembed?url=${url}&format=json`)
  ).data;
};

export function useYoutubeMetadata({ link }: { link?: string | null }) {
  const videoId = getYoutubeVId(link);
  const [loading, setLoading] = useState(false);
  const [metadata, setMetaData] = useState<YoutubeMeta | null>(null);

  useEffect(() => {
    (async () => {
      if (videoId) {
        setLoading(true);

        const r = await getMetadata(`https://youtu.be/${videoId}`);
        setMetaData(r);

        setLoading(false);
      }
    })();
  }, [videoId]);

  return { loading, videoId, metadata };
}
