import classNames from 'classnames';
import { memo, useEffect, useState } from 'react';
import { MdClose, MdLogout } from 'react-icons/md';
import envs from '../common/envs';
import { useAppRouter } from '../common/hooks/use-app-router';
import { useModalManageWoBlocker } from '../common/hooks/use-modal-manage';
import { useNavBlocker } from '../common/hooks/use-nav-blocker';
import LogoCopy from '../components/LogoCopy';
import Modal from '../components/Modal';
import useAuth from '../modules/auth/use-auth';
import SignInModalWrap from '../modules/users/SignInModal';
import NavMenu from './NavMenu';

function ModalMobileMenu(props: { onClose: () => void }) {
  const { onClose } = props;

  const { loc } = useAppRouter();
  const [{ isLoggedIn, user }, { nav, handleLogout }] = useAuth();

  const [authModalOpen, setAuthModalOpen] = useState(false);
  const [opened, setOpened] = useState(false);
  useEffect(() => {
    setOpened(true);
  }, []);

  const [blocking, setBlocking] = useState(true);

  useNavBlocker({
    block: blocking,
    onBack: () => {
      onClose();
    },
  });

  useEffect(() => {
    return () => {
      onClose();
    };
  }, [loc.pathname]);

  useModalManageWoBlocker({ modalKey: 'mobile-gnb' });

  return (
    <Modal
      open
      noTopPad
      onClickBackdrop={() => {
        onClose();
      }}
    >
      <div
        className={classNames(
          'flex-1 h-full relative transition-all duration-150 ease-in-out',
          opened ? 'left-0' : '-left-24',
        )}
      >
        <div className='flex-col flex-1 gap-6 overflow-hidden bg-base-100 h-full py-4'>
          <div
            className={classNames(
              'flex-center-y justify-between gap-4 pl-4 pr-2',
            )}
          >
            <div
              className={classNames(
                'font-bold normal-case text-xl flex-nowrap flex gap-3 cursor-pointer',
              )}
              onClick={() => {
                nav('/');
                onClose();
              }}
            >
              <span className={classNames('flex-center-x')}>
                <LogoCopy className='h-10' />
              </span>
            </div>

            <button
              className={classNames(
                'btn btn-ghost flex-center py-0 min-h-fit h-auto hover:bg-transparent',
              )}
              onClick={() => {
                onClose();
              }}
            >
              <MdClose size={20} />
            </button>
          </div>

          <div>
            <div className='font-head text-3xl px-5'>
              <div className='text-xl'>안녕하세요?</div>
              <div>{user?.displayName} 👋 </div>
            </div>
          </div>

          <div className='overflow-y-auto'>
            <NavMenu
              className='flex-col gap-3'
              itemCls='text-xl'
              onMenuClick={() => {
                setBlocking(false);
              }}
            />
          </div>

          <div className='px-1 flex-1 justify-end flex-col gap-2 w-full text-xl'>
            <div className='flex-none'>
              {!isLoggedIn && (
                <div>
                  <button
                    className='btn btn-ghost text-lg'
                    onClick={() => {
                      setAuthModalOpen(true);
                    }}
                  >
                    로그인
                  </button>
                </div>
              )}
              {isLoggedIn && (
                <div className={classNames('flex-center-y gap-3')}>
                  <div
                    className={classNames('btn btn-ghost flex gap-3 text-lg')}
                    onClick={async () => {
                      await handleLogout();
                      setAuthModalOpen(false);
                    }}
                  >
                    <span className='flex-center-y gap-2'>
                      로그아웃
                      <MdLogout />
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className='px-6 flex gap-2'>
            <span>또펫</span>
            <span>·</span>
            <span>
              <a href={envs.tos} target='_blank' rel='noreferrer'>
                이용자 약관
              </a>
            </span>
            <span>·</span>
            <span>
              <a href={envs.privacy} target='_blank' rel='noreferrer'>
                개인정보 처리방침
              </a>
            </span>
          </div>
        </div>

        {authModalOpen && <SignInModalWrap open setOpen={setAuthModalOpen} />}
      </div>
      <div
        className='w-20 bg-transparent'
        onClick={() => {
          onClose();
        }}
      ></div>
    </Modal>
  );
}

export default memo(ModalMobileMenu);
