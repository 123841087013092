import { toast } from 'react-toastify';
import {
  useCreateFollowMutation,
  useDeleteFollowMutation,
  useFollowingQuery,
  UserFragment,
} from '../../generated/graphql';
import useAuth from '../auth/use-auth';
import { consolidateUser, User } from '../users/type';

export function useFollow({
  user: userUnknown,
}: {
  user?: UserFragment | User | null;
}) {
  const user = consolidateUser(userUnknown);

  const [{ isLoggedIn, auth }] = useAuth();

  const {
    data,
    // loading: loadingFollowing,
    refetch,
  } = useFollowingQuery({
    variables: {
      userId: user?.id || '0',
    },
    fetchPolicy: 'cache-and-network',
  });

  const followId = data?.following?.data?.id;

  const [createFollow, { loading: creating }] = useCreateFollowMutation({
    refetchQueries: ['Followings', 'UserStats'],
    onCompleted: () => {
      refetch();
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });
  const [deleteFollow, { loading: deleting }] = useDeleteFollowMutation({
    refetchQueries: ['UserStats'],
    onCompleted: () => {
      refetch();
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });

  const iamFollowing = !!followId;

  return [
    {
      creating,
      deleting,
      itIsMyself: user?.id === auth?.id,
      followId,
      auth,
      isLoggedIn,
      user,
      iamFollowing,
    },
    { createFollow, deleteFollow },
  ] as const;
}
