import classNames from 'classnames';
import { memo } from 'react';

import { IoMdHeart } from 'react-icons/io';
import useAuth from '../auth/use-auth';
import { Comment } from '../users/type';
import { initiateLogin } from '../users/utils/auth-actions';
import { useCommentLike } from './use-comment-like';

function CommentLikeButton({ comment }: { comment: Comment }) {
  const [{ user, isLoggedIn }] = useAuth();

  const [
    { myLikeData, likeLoading, liked, likeId },
    { updateLike, createLike },
  ] = useCommentLike({
    comment,
  });

  return (
    <div
      className='flex gap-2 cursor-pointer'
      onClick={() => {
        if (!isLoggedIn) {
          // open login
          initiateLogin();
          return;
        }

        if (likeLoading) {
          return;
        }

        if (!liked) {
          if (likeId) {
            updateLike({
              variables: {
                id: likeId,
                data: {
                  liked: true,
                  comment: comment.id,
                  user: user?.id,
                },
              },
            });
          } else {
            createLike({
              variables: {
                data: {
                  liked: true,
                  comment: comment.id,
                  user: user?.id,
                },
              },
            });
          }
        } else {
          updateLike({
            variables: {
              id: myLikeData!.id!,
              data: {
                liked: false,
                comment: comment.id,
                user: user?.id,
              },
            },
          });
        }
      }}
    >
      {liked ? (
        <IoMdHeart size='1.4rem' className={classNames('text-error')} />
      ) : (
        <IoMdHeart size='1.4rem' className={classNames('text-black')} />
      )}
      <span>{comment.attributes?.likeCount || 0}</span>
    </div>
  );
}

export default memo(CommentLikeButton);
