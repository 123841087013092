/* eslint-disable @typescript-eslint/no-explicit-any */
export function pretty(obj: any) {
  return JSON.stringify(obj, null, 2);
}

export function sleep(second: number) {
  return new Promise((resolve) => {
    setTimeout(resolve, 1000 * second);
  });
}
