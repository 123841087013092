import { endsWith, isNaN, toNumber } from 'lodash';

// got it from https://stackoverflow.com/questions/9461621/format-a-number-as-2-5k-if-a-thousand-or-more-otherwise-900
export function abbreviateNumber(number: number) {
  const SI_POSTFIXES = ['', 'k', 'M', 'G', 'T', 'P', 'E'];
  const sign = number < 0 ? '-1' : '';
  const absNumber = Math.abs(number);
  // eslint-disable-next-line
  const tier = (Math.log10(absNumber) / 3) | 0;
  // if zero, we don't need a prefix
  // eslint-disable-next-line
  if (tier == 0) return `${absNumber}`;
  // get postfix and determine scale
  const postfix = SI_POSTFIXES[tier];
  const scale = 10 ** (tier * 3);
  // scale the number
  const scaled = absNumber / scale;
  const floored = Math.floor(scaled * 10) / 10;
  // format number and add postfix as suffix
  let str = floored.toFixed(1);
  // remove '.0' case
  str = /\.0$/.test(str) ? str.substr(0, str.length - 2) : str;
  return `${sign}${str}${postfix}`;
}

export function toValidNumber(num: string | number | undefined | null) {
  const n = toNumber(num);
  if (isNaN(n)) {
    return undefined;
  }
  return n;
}

export const formatNumber = (num: string | number | undefined | null) => {
  const n = toValidNumber(num);
  return n?.toLocaleString();
};

export const formatPrice = (num: string | number | undefined | null) => {
  if (num && endsWith(`${num}`, '0000')) {
    return `${`${num}`.slice(0, -4)} 만원`;
  }
  if (num && endsWith(`${num}`, '000')) {
    return `${`${num}`.slice(0, -3)} 천원`;
  }

  return `${formatNumber(num)} 원`;
};
