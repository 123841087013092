import produce from 'immer';
import { isEmpty, trim, uniq } from 'lodash';
import { atom, useRecoilState } from 'recoil';
import { persistAtom } from '../libs/recoil';
import { AquaticTab, aquaticViewTabs } from '../modules/aquatics/configs';

const PersistAppConfigState = atom<{
  aquaticTabSelected?: AquaticTab;
  shadowNames?: string[];
}>({
  key: 'PersistAppConfigState',
  default: {
    aquaticTabSelected: aquaticViewTabs[0],
    shadowNames: [],
  },
  effects: [persistAtom],
});

export default function usePersistAppConfig() {
  const [persistAppConfig, setPersistAppConfig] = useRecoilState(
    PersistAppConfigState,
  );

  function setAquaticTabSelected(tabId: AquaticTab) {
    setPersistAppConfig((prev) => ({
      ...prev,
      aquaticTabSelected: tabId,
    }));
  }

  function addShadowName(name: string) {
    if (isEmpty(trim(name))) return;

    setPersistAppConfig((prev) => ({
      ...prev,
      shadowNames: uniq([...(prev?.shadowNames || []), name]),
    }));
  }

  function removeShadowName(name: string) {
    setPersistAppConfig((st) =>
      produce(st, (dr) => {
        dr.shadowNames = dr.shadowNames?.filter((n) => n !== name);
      }),
    );
  }

  return [
    {
      ...persistAppConfig,
      persistAppConfig,
    },
    {
      addShadowName,
      removeShadowName,
      setAquaticTabSelected,
      setPersistAppConfig,
    },
  ] as const;
}
