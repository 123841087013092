import { values } from 'lodash';
import { useForm } from 'react-hook-form';
import { BottomSheet } from 'react-spring-bottom-sheet';
import Textarea from '../../components/form/Textarea';
import {
  Enum_Report_Type,
  useCreateReportMutation,
} from '../../generated/graphql';
import { TReportInput, TReportType } from '../../types/gql-enhanced-types';
import { toastNested } from '../../utils/toast';
import useGlobalState from './hooks/use-global-state';

import toast from 'react-hot-toast';
import 'react-spring-bottom-sheet/dist/style.css';
import { useModalManage } from '../../common/hooks/use-modal-manage';
import InlineBtnLoading from '../../components/loading/InlineBtnLoading';

function ModalReportForm() {
  const [{ reportState }, { setReportState }] = useGlobalState();

  useModalManage({
    modalKey: 'report-form',
    onBlocked: () => {
      setReportState(null);
    },
  });

  const { handleSubmit, register } = useForm<TReportInput>({
    defaultValues: {
      type: null,
      desc: '',
      ...reportState,
    },
  });

  const [createReport, { loading }] = useCreateReportMutation({
    onCompleted() {
      // THINK ABOUT AUTO BLOCK. IT DOES NOT COVER ALL CASES.
      // if (reportState?.accusedUser) {
      //   blockUserId(reportState?.accusedUser);
      // }

      toast('신고가 접수되었습니다.');
      setReportState(null);
    },
  });

  const open = !!reportState?.accusedUser;
  if (!open) return null;

  return (
    <BottomSheet className='fixed z-20' open={open}>
      <div className='p-5'>
        <div className='flex flex-col gap-8'>
          <div className='text-lg font-semibold text-center'>신고하기</div>
          <div className='flex-col flex-1 gap-6'>
            <div className='flex-col gap-3'>
              <label htmlFor=''>신고 카테고리</label>
              <select
                className='select w-full'
                {...register('type', {
                  required: {
                    value: true,
                    message: '카테고리를 선택해주세요.',
                  },
                })}
              >
                <option value=''>-- 카테고리 선택 --</option>
                {values(Enum_Report_Type).map((type: TReportType) => (
                  <option key={type} value={type}>
                    {type === 'ads' && '부적절 혹은 상업적 광고'}
                    {type === 'abusive' && '명예훼손, 욕설 및 비방'}
                    {type === 'copyright' && '저작권 침해'}
                    {type === 'sexual' && '음란물, 성적 콘텐츠'}
                    {type === 'spam' && '스팸'}
                    {type === 'etc' && '기타'}
                  </option>
                ))}
              </select>
            </div>
            <div className='flex-col gap-3'>
              <label htmlFor=''>신고 사유</label>
              <Textarea
                className='min-h-[5rem]'
                {...register('desc', {
                  // required: {
                  //   value: true,
                  //   message: '신고 사유를 입력해주세요.',
                  // },
                })}
              />
            </div>

            <div className='flex justify-end gap-3'>
              <button
                className='btn btn-ghost'
                onClick={() => {
                  setReportState(null);
                }}
              >
                취소
              </button>
              <button
                className='btn btn-primary'
                onClick={() => {
                  if (loading) return;

                  // submit
                  handleSubmit(
                    (validValues) => {
                      createReport({
                        variables: {
                          data: {
                            ...validValues,
                          },
                        },
                      });
                    },
                    (e) => {
                      toastNested(values(e));
                    },
                  )();
                }}
              >
                {loading && <InlineBtnLoading />}
                보내기
              </button>
            </div>
          </div>
        </div>
      </div>
    </BottomSheet>
  );
}

export default ModalReportForm;
