import { pick } from 'lodash';
import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import {
  Enum_Post_Category,
  PostFragment,
  PostInput,
  PostSocialInput,
  useCreatePostMutation,
  useCreatePostSocialMutation,
  useUpdatePostMutation,
  useUpdatePostSocialMutation,
  useUploadMutation,
} from '../../../generated/graphql';
import { TPost } from '../../../types/gql-enhanced-types';
import { sanitizeDataToInput } from '../../../utils/apollo-utils';
import { notNull } from '../../../utils/array';
import { getMediaUrl } from '../../../utils/image';
import useAuth from '../../auth/use-auth';

export type PostFormData = {
  post: Omit<PostInput, 'images'> & {
    images: (File | string)[];
  };
  social: PostSocialInput;
  previews: string[];
  socialId?: string | null;
};

export function usePostForm({
  category,
  post,
  onSuccess,
}: {
  category?: Enum_Post_Category;
  post?: TPost | null;
  onSuccess?: (p?: PostFragment | null) => void;
}) {
  const [{ isSuperAdmin, user }] = useAuth();
  const creatorId = user?.id;
  const isEdit = !!post;

  const formData = useMemo((): PostFormData => {
    const cate = post?.category || category || Enum_Post_Category.Main;

    if (!post) {
      return {
        post: {
          category: cate,
          images: [],
          previews: [],
        },
        social: {},
        previews: [],
      } as PostFormData;
    }

    const imagesIds =
      post?.images?.data?.map((d) => d.id).filter(notNull) || [];
    const imagesData = post?.images?.data || [];
    const previews =
      imagesData?.map((d) => getMediaUrl(d.attributes?.url)).filter(notNull) ||
      [];

    return {
      post: {
        ...pick(post, ['category', 'title', 'tags', 'desc']),
        category: cate,
        images: imagesIds,
      },
      social: {
        ...sanitizeDataToInput(post?.postSocial?.data?.attributes),
      },
      previews,
      socialId: post?.postSocial?.data?.id,
    };
  }, [post, category]);

  const { handleSubmit, getValues, setValue, register, watch } =
    useForm<PostFormData>({
      defaultValues: formData,
    });

  const [saving, setSaving] = useState(false);
  const [upload] = useUploadMutation({
    onError() {
      setSaving(false);
    },
  });

  const [createPost] = useCreatePostMutation({
    refetchQueries: ['Posts'],
    onCompleted: (param) => {
      toast.success('등록완료');
      setSaving(false);

      onSuccess?.(param.createPost?.data);
    },
    onError() {
      setSaving(false);
    },
  });
  const [createPostSocial] = useCreatePostSocialMutation({});
  const [updatePostSocial] = useUpdatePostSocialMutation({});

  const [updatePost] = useUpdatePostMutation({
    refetchQueries: ['Posts'],
    onCompleted: (param) => {
      toast.success('저장완료');
      setSaving(false);

      onSuccess?.(param.updatePost?.data);
    },
    onError() {
      setSaving(false);
    },
  });

  async function handleSave() {
    try {
      if (saving) return;

      const nData = getValues();
      const firstFile = nData.post.images?.[0];

      setSaving(true);

      let uploadId: string | undefined;

      if (typeof firstFile === 'object') {
        const { data: uploadData } = await upload({
          variables: {
            file: firstFile,
          },
        });

        uploadId = uploadData?.upload.data?.id as string;

        if (!uploadId) {
          toast.error('업로드 에러');
          throw new Error('업로드 에러');
        }

        if (uploadId != null) {
          setValue('post.images', [uploadId]);
        }
      } else if (typeof firstFile === 'string') {
        uploadId = firstFile;
      }

      let { socialId } = nData;

      if (nData.social.link) {
        if (socialId) {
          // UPDATING SOCIAL
          await updatePostSocial({
            variables: {
              id: socialId,
              data: {
                ...nData.social,
              },
            },
            onError(error) {
              throw error;
            },
          });
        } else {
          // CREATING NEW SOCIAL
          const resL1 = await createPostSocial({
            variables: {
              data: {
                ...nData.social,
              },
            },
            onError(error) {
              throw error;
            },
          });

          socialId = resL1?.data?.createPostSocial?.data?.id;
          setValue('socialId', socialId);
        }
      }

      const data = {
        images: uploadId ? [uploadId] : [],
        title: nData?.post.title,
        desc: nData?.post.desc,
        tags: nData?.post.tags,
        creator: creatorId,
        category: nData?.post.category as Enum_Post_Category,
        postSocial: socialId,
      } as PostInput;

      if (!isSuperAdmin) {
        if (['social'].includes(data.category || '')) {
          data.inReview = true;
        }
      }

      // updating
      if (post?.id) {
        await updatePost({
          variables: {
            id: post?.id,
            data,
          },
        });
        return;
      }

      // CREATING
      await createPost({
        variables: {
          data,
        },
      });
    } catch (error) {
      // eslint-disable-next-line
      console.log('error', error);

      toast.error(`${error}`);
    }

    setSaving(false);
  }

  return {
    formData,
    isEdit,

    saving,
    setSaving,

    upload,
    getValues,
    setValue,
    register,
    watch,
    handleSave,
    handleSubmit,
    createPostSocial,
    createPost,
    updatePost,
  } as const;
}
