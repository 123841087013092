import { memo, useState } from 'react';
import toast from 'react-hot-toast';
import { IoMdCloseCircle } from 'react-icons/io';
import { VscChromeClose } from 'react-icons/vsc';
import { useModalManage } from '../common/hooks/use-modal-manage';
import Modal from '../components/Modal';
import { useLoginShadowMutation } from '../generated/graphql';
import useAuth from '../modules/auth/use-auth';
import { notEmpty } from '../utils/array';
import ShadowSelect from './component/ShadowSelect';
import usePersistAppConfig from './use-persist-app-state';

function AdminToolsModal({ setOpen }: { setOpen: (n: boolean) => void }) {
  useModalManage({ modalKey: 'admin-tools' });
  const [
    { user, isSuperUserOrShadow, isSuperAdmin, isShadow },
    { handleLogin },
  ] = useAuth();

  const [shadow, setShadow] = useState('');

  const [{ shadowNames }, { removeShadowName, addShadowName }] =
    usePersistAppConfig();

  const [loginShadow] = useLoginShadowMutation({
    onCompleted: (data) => {
      if (!data.loginShadow.user) {
        toast.error('존재하지 않습니다.');
        return;
      }

      const name = data.loginShadow.user?.displayName;
      if (name) {
        addShadowName(name);
      }

      setShadow('');

      handleLogin(data.loginShadow);
      setOpen(false);
    },
  });

  return (
    <Modal
      open
      className='lg:w-[600px] lg:h-screen'
      onClickBackdrop={() => {
        setOpen(false);
      }}
    >
      <div className='flex-1 flex flex-col p-5'>
        <div className='flex justify-end flex-none text-3xl text-center'>
          <span
            className='cursor-pointer'
            onClick={() => {
              setOpen(false);
            }}
          >
            <VscChromeClose className='w-6 opacity-70' />
          </span>
        </div>
        <div className='flex-1 flex'>
          <div className='flex-1 flex-col gap-4'>
            {isSuperUserOrShadow && (
              <div className='flex-col gap-2'>
                <label htmlFor=''>현재유저: {user?.displayName}</label>
              </div>
            )}
            <div className='flex-1 h-full flex-col overflow-auto gap-9'>
              {(isShadow || isSuperAdmin) && (
                <>
                  <div className='flex-col gap-2'>
                    <label htmlFor=''>작성자</label>
                    <ShadowSelect
                      value={{
                        value: shadow,
                        label: shadow,
                      }}
                      onChange={(o) => {
                        if (o?.value) {
                          setShadow(o.value);
                        }
                      }}
                    />
                    <div className='flex gap-2 flex-wrap'>
                      {shadowNames?.filter(notEmpty)?.map((name) => {
                        return (
                          <span
                            className='cursor-pointer badge badge-primary badge-lg whitespace-nowrap'
                            onClick={() => {
                              setShadow(name);
                            }}
                          >
                            {name}
                            <span
                              onClick={() => {
                                removeShadowName(name);
                              }}
                            >
                              <IoMdCloseCircle
                                size={20}
                                className='opacity-50'
                              />
                            </span>
                          </span>
                        );
                      })}
                    </div>
                    <div>
                      <button
                        className='btn btn-secondary'
                        onClick={() => {
                          if (!shadow) return;

                          loginShadow({
                            variables: {
                              name: shadow,
                            },
                          });
                        }}
                      >
                        화려한변신
                      </button>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default memo(AdminToolsModal);
