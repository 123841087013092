/* eslint-disable @typescript-eslint/no-explicit-any */
import { trim } from 'lodash';
import appConfig from '../../app/appConfig';
import { PostsQueryVariables } from '../../generated/graphql';
import { isNotEmpty } from '../../utils/boolean';

const { pageSize } = appConfig.post;

export function getPostQueryParams({
  creatorId,
  category,
  keyword,
  tags,
  sort,
  aquaticId,
}: {
  creatorId?: string | null;
  keyword?: string | null;
  category?: string;
  tags?: string;
  aquaticId?: string | null;
  sort?: string[];
} = {}) {
  const variables = {
    sort: sort || ['createdAt:DESC', 'id:DESC'],
    pagination: {
      page: 1,
      pageSize,
    },
    filters: {
      inReview: {
        eq: false,
      },
      deleted: {
        eq: false,
      },
      // partnerPostCat: {
      //   null: true,
      // }
    },
  } as PostsQueryVariables;

  if (aquaticId) {
    variables.filters!.aquatic = {
      id: {
        eq: aquaticId,
      },
    };
  }

  if (tags) {
    variables.filters!.tags = {
      contains: tags,
    };
  }
  if (creatorId) {
    variables.filters!.creator = {
      id: {
        eq: creatorId,
      },
    };
  } else {
    variables.filters!.partnerPostRank = {
      null: true,
    };
  }

  if (isNotEmpty(keyword)) {
    variables.filters!.or = [
      {
        title: {
          contains: trim(keyword),
        },
      },
      {
        desc: {
          contains: trim(keyword),
        },
      },
    ];
  }

  if (category) {
    variables.filters!.category = {
      eq: category,
    };
  }

  return variables;
}
