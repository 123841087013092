import {
  useCreateNotiConfigMutation,
  useDeleteNotiConfigMutation,
  useNotiConfigsQuery,
  useUpdateNotiConfigMutation,
} from '../../../generated/graphql';
import useAuth from '../../../modules/auth/use-auth';
import { convList } from '../../../types/gql-enhanced-types';

export function useNotiConfigsRpc({
  onCreateCompleted,
}: { onCreateCompleted?: () => void } = {}) {
  const [{ userId }] = useAuth();

  const { data, loading } = useNotiConfigsQuery({
    fetchPolicy: 'cache-first',
    variables: {
      filters: {
        creator: {
          id: {
            eq: userId,
          },
        },
      },
    },
  });

  const [updateNotiConfig, { loading: updating }] = useUpdateNotiConfigMutation(
    {},
  );
  const [deleteNotiConfig, { loading: deleting }] = useDeleteNotiConfigMutation(
    {
      refetchQueries: ['NotiConfigs'],
    },
  );
  const [createNotiConfig, { loading: creating }] = useCreateNotiConfigMutation(
    {
      refetchQueries: ['NotiConfigs'],
      onCompleted() {
        onCreateCompleted?.();
      },
    },
  );

  const notiConfigs = convList(data?.notiConfigs?.data);

  return {
    userId,
    notiConfigs,
    loading,
    creating,
    deleting,
    updating,
    updateNotiConfig,
    deleteNotiConfig,
    createNotiConfig,
  };
}
