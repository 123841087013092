import produce from 'immer';
import { first } from 'lodash';
import {
  CommentFragment,
  CommentFragmentDoc,
  useCreateLikeMutation,
  useUpdateLikeMutation,
} from '../../generated/graphql';

export function useCommentLike({ comment }: { comment?: CommentFragment }) {
  const [updateLike, { loading: updating }] = useUpdateLikeMutation({
    update(cache, { data: mutData }) {
      const frag = cache.readFragment<CommentFragment>({
        id: `CommentEntity:${comment?.id}`,
        fragment: CommentFragmentDoc,
        fragmentName: 'Comment',
      });

      const addingLike = !!mutData?.updateLike?.data?.attributes?.liked;

      const newFrag = produce(frag, (draft) => {
        if (draft?.attributes?.MyLikes?.data && mutData?.updateLike?.data) {
          draft.attributes.MyLikes.data = [mutData?.updateLike?.data];
        }

        if (draft?.attributes?.likeCount != null) {
          if (addingLike) {
            draft.attributes.likeCount++;
          } else {
            draft.attributes.likeCount--;
          }
        }
      });

      cache.writeFragment({
        id: `CommentEntity:${comment?.id}`,
        data: newFrag,
        fragment: CommentFragmentDoc,
        fragmentName: 'Comment',
      });
    },
  });

  const [createLike, { loading: creating }] = useCreateLikeMutation({
    update(cache, { data: mutData }) {
      const frag = cache.readFragment<CommentFragment>({
        id: `CommentEntity:${comment?.id}`,
        fragment: CommentFragmentDoc,
        fragmentName: 'Comment',
      });

      const addingLike = !!mutData?.createLike?.data?.attributes?.liked;

      const newFrag = produce(frag, (draft) => {
        if (draft?.attributes?.MyLikes?.data && mutData?.createLike?.data) {
          draft.attributes.MyLikes.data = [mutData?.createLike?.data];
        }

        if (draft?.attributes?.likeCount != null) {
          if (addingLike) {
            draft.attributes.likeCount++;
          } else {
            draft.attributes.likeCount--;
          }
        }
      });

      cache.writeFragment({
        id: `CommentEntity:${comment?.id}`,
        data: newFrag,
        fragment: CommentFragmentDoc,
        fragmentName: 'Comment',
      });
    },
  });

  const likeLoading = creating || updating;

  const myLikeData = first(comment?.attributes?.MyLikes?.data);
  const likeId = myLikeData?.id;
  const liked = !!likeId && !!myLikeData?.attributes?.liked;

  return [
    { myLikeData, likeLoading, liked, likeId },
    { updateLike, createLike },
  ] as const;
}
