export function getEnvs() {
  const envs = {
    mobilePkId: process.env.REACT_APP_MOBILE_PK_ID,
    mobileAppleLink: process.env.REACT_APP_MOBILE_APPLE_LINK,
    appleAppId: process.env.REACT_APP_MOBILE_PK_ID,
    clientHost: process.env.REACT_APP_CLIENT_DOMAIN,
    // clientBaseUrl: process.env.REACT_APP_CLIENT_BASE,
    BackendUrl: `${process.env.REACT_APP_BACKEND_BASE}/graphql`,
    backendBaseUrl: process.env.REACT_APP_BACKEND_BASE,
    auth: {
      google: {
        clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        redirectUrl: process.env.REACT_APP_GOOGLE_REDIRECT_URL,
      },
    },
    kakaoJsKey: process.env.REACT_APP_KAKAO_JS_KEY || '',
    isProd: process.env.NODE_ENV === 'production',
    tos: process.env.REACT_APP_TOS,
    privacy: process.env.REACT_APP_PRIVACY,
  };

  return envs;
}

export default getEnvs();
