import classNames from 'classnames';
import { first, isNull, isUndefined, omit, omitBy, size } from 'lodash';
import React, { useState } from 'react';
import ScaleDots from '../../components/ScalelDots';
import { TAquatics } from '../../types/gql-enhanced-types';
import { strToArr } from '../../utils/array';
import Color from './components/Color';
import {
  convActiveTime,
  convBreedingLevel,
  convCareLevel,
  convDiet,
  convSchooling,
  formatMinMax,
} from './convert-enum';
import { findColorOption } from './models/colors';

function StatItem({
  label,
  value,
  unit,
  onClick,
}: {
  label: string;
  unit?: string;
  value?: React.ReactNode;
  onClick?: () => void;
}) {
  return (
    <div className='flex-grow text-lg'>
      <div className='flex flex-row items-center space-x-3'>
        <div
          className={classNames('flex-grow-0 opacity-50', {
            'cursor-pointer': !!onClick,
          })}
          onClick={onClick}
        >
          {label}
        </div>
        <div className='flex-grow'></div>
        {value === null ? null : (
          <div
            onClick={onClick}
            className={classNames('flex-grow-0 text-right font-semibold', {
              'cursor-pointer': !!onClick,
            })}
          >
            {value && (typeof value === 'string' || typeof value === 'number')
              ? `${value} ${unit ? `${unit}` : ''}`
              : null}
            {value && typeof value !== 'string' && typeof value !== 'number'
              ? value
              : null}
            {!value ? '-' : null}
          </div>
        )}
      </div>
    </div>
  );
}

export default function AquaticStats({
  aquaticModel,
}: {
  aquaticModel: TAquatics | null;
}) {
  const aquatic = aquaticModel;

  const fieldsToOmit = [
    'content',
    'children',
    'covers',
    'id',
    'name',
    'otherNames',
    'parent',
    'slug',
    '__typename',
  ];

  const stats: Partial<TAquatics> = omit(
    omitBy(aquatic, (v) => isNull(v) || isUndefined(v)),
    fieldsToOmit,
  );

  const otherNames = strToArr(aquatic?.otherNames);
  const colors = aquatic?.colors;
  const [showMoreNames, setShowMoreNames] = useState(false);
  const hasMoreThan1Name = size(otherNames) > 1;

  return (
    <div className='flex flex-col space-y-5'>
      {!!aquatic?.otherNames && (
        <StatItem
          label='다른 이름'
          value={
            <div className='flex flex-col items-end space-y-1'>
              {showMoreNames ? (
                <>
                  {otherNames.map((n) => {
                    return (
                      <div key={n} className='flex-grow'>
                        <span className='badge badge-outline'>{n}</span>
                      </div>
                    );
                  })}
                </>
              ) : (
                <div className='flex-grow'>
                  <span className='badge badge-outline'>
                    {first(otherNames)}
                  </span>
                  {hasMoreThan1Name && (
                    <div>
                      <span
                        className='link text-sm'
                        onClick={() => {
                          setShowMoreNames(true);
                        }}
                      >
                        다른 이름 다 보기 ({size(otherNames) - 1})
                      </span>
                    </div>
                  )}
                </div>
              )}
            </div>
          }
        />
      )}
      <StatItem
        label='색상'
        value={
          <div className='flex items-end space-y-1 gap-1 flex-wrap'>
            {colors?.map((n) => {
              const option = findColorOption(n);
              if (!option) return null;

              return (
                <Color
                  key={n}
                  color={option.color}
                  noLabel
                  className='w-5 h-5 border-[1px] border-gray-300'
                />
              );
            })}
          </div>
        }
      />
      <StatItem label='성어크기' value={stats?.sizeCm} unit='cm' />
      <StatItem label='키우기 난이도' value={convCareLevel(stats?.careLevel)} />
      <StatItem
        label='번식 난이도'
        value={convBreedingLevel(stats?.breedingLevel)}
      />
      <StatItem
        label='추천 적정온도'
        value={formatMinMax(stats?.tempMin, stats?.tempMax)}
        unit='°C'
      />
      <StatItem
        label='페하 (pH)'
        value={formatMinMax(stats?.phMin, stats?.phMax)}
      />
      <StatItem
        label='경도'
        value={formatMinMax(stats?.hardnessMin, stats?.hardnessMax)}
      />
      <StatItem label='활성 시간대' value={convActiveTime(stats?.activeType)} />
      <StatItem label='떼수영' value={convSchooling(stats?.schooling)} />
      {/*
              <StatItem
        label="Colors"
        value={
          <div className="flex flex-row items-center space-x-1">
            {aquatic?.colors?.map((c) => {
              if (!c.hex) {
                return <span>{c.name}</span>;
              }
              return (
                <span
                  key={c.name}
                  className="inline-block w-5 h-5 border border-gray-200 rounded-full"
                  style={{
                    backgroundColor: c.hex,
                  }}
                ></span>
              );
            })}
          </div>
        }
      />
*/}
      <StatItem label='식성' value={convDiet(stats?.diet)} />
      <StatItem label='텃세성' value={<ScaleDots value={stats?.territ} />} />
      <StatItem label='가격대' value={<ScaleDots value={stats?.price} />} />
      <StatItem label='평균적 수명' value={stats?.lifeSpanYear} />
    </div>
  );
}
