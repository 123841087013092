import { memo } from 'react';

import { MdChevronRight } from 'react-icons/md';
import envs from '../../common/envs';

function SettingsPage() {
  // const [{ user }, { handleLogin }] = useAuth({
  //   navigateToUnAuthed: '/',
  // });

  return (
    <div className='flex flex-col gap-[20px]'>
      <div>
        <div
          className='flex-center-y py-2 pl-4'
          onClick={() => {
            window.open(envs.tos, '_blank');
          }}
        >
          <div className='flex-1'>
            <span>이용약관</span>
          </div>
          <div className='flex-center'>
            <button className='btn btn-ghost'>
              <MdChevronRight size={28} />
            </button>
          </div>
        </div>
        <div
          className='flex-center-y py-2 pl-4'
          onClick={() => {
            window.open('https://open.kakao.com/o/g407gcVf', '_blank');
          }}
        >
          <div className='flex-1'>
            <span>문의/제안 (카카오채널)</span>
          </div>
          <div className='flex-center'>
            <button className='btn btn-ghost'>
              <MdChevronRight size={28} />
            </button>
          </div>
        </div>
      </div>
      <div className='h-24'></div>
    </div>
  );
}

export default memo(SettingsPage);
