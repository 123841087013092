import { useApolloClient } from '@apollo/client';
import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useRecoilState } from 'recoil';
import useAppConfig, { BoardConfigs } from '../../app/use-app-configs';
import {
  AdminRoleEnum,
  ConnectPayload,
  useLogoutMutation,
  useMeV2LazyQuery,
} from '../../generated/graphql';
import { setAccessToken } from '../../graphql/client';
import { AuthState } from './auth-state';

export default function useAuth({
  navigateToAuthed,
  navigateToUnAuthed,
}: {
  navigateToAuthed?: string;
  navigateToUnAuthed?: string;
} = {}) {
  const client = useApolloClient();
  const nav = useNavigate();

  const [{ appConfig }, { setAppConfig }] = useAppConfig();
  const { authLoading, authInit } = appConfig || {};

  const [authState, setAuthState] = useRecoilState(AuthState);

  const [logoutMutation] = useLogoutMutation({
    onCompleted: () => {
      client.clearStore();
    },
  });

  /**
   * Navigate to user to somewhere when authed
   */
  useEffect(() => {
    if (navigateToAuthed && authInit && authState?.jwt) {
      nav(navigateToAuthed);
    }
    if (navigateToUnAuthed && authInit && !authState?.jwt) {
      nav(navigateToUnAuthed);
    }
  }, [authInit, authState?.jwt, navigateToAuthed, navigateToUnAuthed]);

  const handleLogin = async (resp: ConnectPayload) => {
    setAccessToken(resp?.jwt || authState?.jwt);

    setAuthState({
      jwt: resp?.jwt || authState?.jwt,
      user: resp?.user,
    });
  };

  const [fetchMe, { loading }] = useMeV2LazyQuery({
    fetchPolicy: 'cache-and-network',
    onCompleted: async (data) => {
      setAppConfig((st) => {
        const boardConfigs = (data?.appConfig?.data?.attributes?.boardConfigs ||
          []) as BoardConfigs;

        return {
          ...st,
          authInit: true,
          authLoading: false,
          boardConfigs,
        };
      });

      if (data?.meV2) {
        await handleLogin(data.meV2);
      }
    },
  });

  // auth initialize
  useEffect(() => {
    if (!loading && !authLoading && !authInit) {
      setAppConfig((st) => ({ ...st, authLoading: true, authInit: false }));

      fetchMe();
    }
  }, [setAuthState, authLoading, authInit, loading]);

  const handleLogout = useCallback(async () => {
    await logoutMutation({
      onCompleted: () => {
        setAccessToken(null);
        client.clearStore();

        setAppConfig((st) => ({ ...st, authLoading: false, authInit: false }));
        setAuthState(null);
      },
    });
  }, [client, setAccessToken, setAppConfig, setAuthState, logoutMutation]);

  const isSuperAdmin = authState?.user?.adminRole === AdminRoleEnum.Superadmin;
  const isShadow = authState?.user?.shadow;

  return [
    {
      isLoggedIn: !!authState?.jwt,
      authInit,
      loading: authLoading || !authInit || loading,
      user: authState?.user,
      userId: authState?.user?.id,
      auth: authState?.user,
      isPartner1: authState?.user?.partnerCode === 'partner1',
      isSuperAdmin,
      isShadow,
      isSuperUserOrShadow: isSuperAdmin || isShadow,
      authState,
    },
    {
      handleLogin,
      handleLogout,
      nav,
      navigate: nav,
      setAuthState,
      setAppConfig,
    },
  ] as const;
}
