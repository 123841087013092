// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseJsonToStrArr(strapiJson: any): string[] {
  if (!strapiJson) return [];

  try {
    return JSON.parse(strapiJson || '') || [];
  } catch (error) {
    // log.error('error', error);
  }

  return [];
}
