import {
  Enum_Aquatic_Activetype,
  Enum_Aquatic_Breedinglevel,
  Enum_Aquatic_Carelevel,
  Enum_Aquatic_Diet,
  Enum_Aquatic_Schooling,
} from '../../generated/graphql';

export function formatMinMax(min?: number | null, max?: number | null) {
  if (min == null && max == null) return '-';

  if (min && max) {
    return `${min} - ${max}`;
  }

  if (min) {
    return min;
  }

  if (max) {
    return max;
  }

  return '-';
}

export function convSchooling(value?: Enum_Aquatic_Schooling | null) {
  switch (value) {
    case Enum_Aquatic_Schooling.Always:
      return '항상';
    case Enum_Aquatic_Schooling.Sometimes:
      return '가끔씩';
    case Enum_Aquatic_Schooling.Noschooling:
      return '안함';
    default:
      return '-';
  }
}

export function convActiveTime(value?: Enum_Aquatic_Activetype | null) {
  switch (value) {
    case Enum_Aquatic_Activetype.Alltime:
      return '하루종일';
    case Enum_Aquatic_Activetype.Crepuscular:
      return '박명박모성 (어스름)';
    case Enum_Aquatic_Activetype.Diurnal:
      return '주행성 (낮)';
    case Enum_Aquatic_Activetype.Nocturnal:
      return '야행성 (밤)';
    default:
      return '-';
  }
}

export function convDiet(value?: Enum_Aquatic_Diet | null) {
  switch (value) {
    case Enum_Aquatic_Diet.Carnivore:
      return '육식성';
    case Enum_Aquatic_Diet.Herbivore:
      return '초식성';
    case Enum_Aquatic_Diet.Omnivore:
      return '잡식성';
    default:
      return '-';
  }
}

export function convCareLevel(schooling?: Enum_Aquatic_Carelevel | null) {
  switch (schooling) {
    case Enum_Aquatic_Carelevel.Difficult:
      return '어려움';
    case Enum_Aquatic_Carelevel.Easy:
      return '쉬움';
    case Enum_Aquatic_Carelevel.Moderate:
      return '중간';
    default:
      return '-';
  }
}

export function convBreedingLevel(
  schooling?: Enum_Aquatic_Breedinglevel | null,
) {
  switch (schooling) {
    case Enum_Aquatic_Breedinglevel.Difficult:
      return '어려움';
    case Enum_Aquatic_Breedinglevel.Easy:
      return '쉬움';
    case Enum_Aquatic_Breedinglevel.Moderate:
      return '중간';
    default:
      return '-';
  }
}
