import appConfig from '../../app/appConfig';
import { AquaticFiltersInput, PaginationArg } from '../../generated/graphql';
import { notEmpty } from '../../utils/array';
import { sizeOptions } from './models/size';

const { pageSize } = appConfig.aquatic;

export function getAqNeedValidationCondition({
  userId,
}: {
  userId?: string | null;
}) {
  if (userId) {
    return {
      or: [
        {
          and: [
            { needValidation: { eq: true } },
            { creator: { id: { eq: userId } } },
          ],
        },
        {
          needValidation: { eq: false },
        },
      ],
    };
  }

  return {
    needValidation: { eq: false },
  };
}

export function getAquaticsQueryParams({
  size,
  color,
  keywords,
  userId,
}: {
  size?: string | null;
  color?: string | null;
  keywords?: string[];
  userId?: string | null;
}) {
  const vars: {
    filters: AquaticFiltersInput;
    sort: string[];
    pagination: PaginationArg;
  } = {
    sort: ['updatedAt:DESC', 'id:DESC'],
    pagination: {
      page: 1,
      pageSize,
    },

    filters: {
      and: [
        {
          deleted: {
            eq: false,
          },
        },
      ],
    },
  };

  vars.filters.and?.push(getAqNeedValidationCondition({ userId }));

  keywords?.filter(notEmpty).forEach((keyword) => {
    vars.filters.and?.push({
      name: {
        containsi: keyword,
      },
    });
  });

  if (color) {
    vars.filters.and?.push({
      colors: {
        containsi: color,
      },
    });
  }

  const sizeOption = sizeOptions.find((o) => o.value === size);
  if (sizeOption) {
    vars.filters.and?.push({
      sizeCm: {
        gte: sizeOption.min,
        lte: sizeOption.max,
      },
    });
  }

  return vars;
}
