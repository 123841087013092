import { memo } from 'react';

function PrivateCommentBadge() {
  return (
    <span className='indicator-item badge badge-error badge-sm text-white font-semibold mr-1'>
      비밀댓글
    </span>
  );
}

export default memo(PrivateCommentBadge);
