import { isEmpty } from 'lodash';
import { memo } from 'react';
import { SingleValue } from 'react-select';
import ReactSelect from 'react-select/creatable';
import { SelectOption } from '../../components/selector/selector-types';
import { useShadowsQuery } from '../../generated/graphql';
import useAuth from '../../modules/auth/use-auth';
import { notNull } from '../../utils/array';

function ShadowSelect({
  value,
  onChange,
  placeholder,
}: {
  value?: SelectOption | null;
  onChange: (o: SingleValue<SelectOption>) => void;
} & {
  placeholder?: string;
}) {
  const [{ isShadow, isSuperAdmin }] = useAuth();

  const { data, loading } = useShadowsQuery({
    fetchPolicy: 'cache-and-network',
  });

  if (!isSuperAdmin && !isShadow) {
    return null;
  }

  const options: SelectOption[] =
    data?.shadows?.names?.filter(notNull)?.map((name) => ({
      value: name,
      label: name,
    })) || [];

  return (
    <ReactSelect
      isSearchable
      options={options}
      value={value || null}
      isLoading={loading}
      placeholder={placeholder || '생물을 검색하세요.'}
      loadingMessage={() => '로딩중 ...'}
      noOptionsMessage={({ inputValue }) => {
        if (isEmpty(inputValue)) {
          return '검색어를 입력해주세요.';
        }
        return '검색 결과가 없습니다.';
      }}
      styles={{
        placeholder: (provided) => ({
          ...provided,
          fontSize: '1.1rem',
        }),
        input: (provided) => ({
          ...provided,
          fontSize: '1.1rem',
          lineHeight: '1.6rem',
        }),
      }}
      className='min-w-[16rem]'
      onChange={(o) => {
        onChange(o);
      }}
    />
  );
}

export default memo(ShadowSelect);
