import { size } from 'lodash';
import { memo, useMemo } from 'react';
import { clean, postDescOption } from '../pages/sanitizeHtml';

const urlRegex =
  // eslint-disable-next-line no-useless-escape
  /(\b(https|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;

function UserContentRenderer({
  text,
  truncateLen,
}: {
  truncateLen?: number;
  text?: string | null;
}) {
  const htmlText = useMemo(
    () =>
      clean(
        (text || '')?.replace(
          urlRegex,
          '<a class="link" href="$1"  target="_blank" rel="noreferrer">$1</a>',
        ),
        // @ts-ignore
        postDescOption,
      ),
    [text],
  );

  const textNoTags = useMemo(
    () =>
      clean(
        (text || '')?.replace(urlRegex, '...'),
        // @ts-ignore
        postDescOption,
      ),
    [text],
  );

  if (!htmlText) return null;

  if (truncateLen) {
    if (size(textNoTags) > truncateLen) {
      const shortText = `${textNoTags.slice(0, truncateLen)}...`;

      return <div className=''>{shortText}</div>;
    }
  }

  return (
    <span
      className='break-words whitespace-pre-wrap'
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: htmlText }}
    />
  );
}

export default memo(UserContentRenderer);
