import { memo } from 'react';
import LoadingIcon from '../../components/loading/LoadingIcon';
import { useSendNotifEmailMutation } from '../../generated/graphql';

function ForceNotiPage() {
  const [sendNoti, { loading, data }] = useSendNotifEmailMutation();

  return (
    <div className='flex gap-5 flex-col'>
      Notification testing <br />
      <button
        className='btn btn-sm'
        onClick={() => {
          sendNoti();
        }}
      >
        Send notification
      </button>
      {loading && (
        <div>
          <LoadingIcon />
        </div>
      )}
      <div>sent to {data?.sendNotifEmail}</div>
    </div>
  );
}

export default memo(ForceNotiPage);
