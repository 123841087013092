import envs from '../common/envs';

export default {
  post: {
    titleMaxLen: 60,
    pageSize: 20,
    showCopyMessageDuration: 1000,
    // partnerPostStep: 3,
    partnerPostStep: envs.isProd ? 8 : 3,
    // 20 secs
    ppInterval: envs.isProd ? 1000 * 20 : 1000 * 5,
    refetchPP: 1000 * 60 * 1,
    imageMaxCount: 5,
  },
  trade: {
    maxConfigs: 3,
  },
  ga: {
    measureId: !envs.isProd ? 'G-46KYNLMC5G' : 'G-36JXXHEL97',
    // https://stackoverflow.com/a/10644663/2330799
    // measureId: 'G-36JXXHEL97',
  },
  notis: {
    pageSize: 14,
  },
  aquatic: {
    pageSize: 20,
  },
  reply: {
    pageSize: 20,
  },
  comment: {
    maxLength: 1000,
    pageSize: 20,
  },
  player: {
    thresholdForPlayingPreviousInSec: 2,
    progressJumpSteps: { forward: 15000, backward: 15000 },
  },
  user: {
    displayNameMaxLen: 30,
    handleMaxLen: 20,
  },
  search: {
    seeAllThreshold: 3,
    usersPageSize: 10,
    postsPageSize: 10,
  },
};
