import { isArray, isEmpty } from 'lodash';

export function parseRegions(regionsJsonStr?: string | null) {
  try {
    const regions = JSON.parse(regionsJsonStr || '[]');
    if (isArray(regions)) {
      return regions;
    }
    return [];
  } catch (error) {
    //
  }
  return [];
}

export function displayRegions({ regions }: { regions: string[] }) {
  const items = [...regions];
  if (isEmpty(items)) {
    return '전국';
  }
  return regions.join(', ');
}
