import { memo } from 'react';
import useAuth from '../auth/use-auth';
import ProfileView from './ProfileView';

function ProfilePage() {
  const [{ auth }] = useAuth({
    navigateToUnAuthed: '/',
  });

  if (!auth) {
    return null;
  }

  return <ProfileView user={auth} />;
}

export default memo(ProfilePage);
