import { memo } from 'react';
import Skeleton from 'react-loading-skeleton';

function SkelPostItem() {
  return (
    <div className='flex gap-3 px-4 z-0'>
      <div className='w-16 h-16'>
        <Skeleton width={'100%'} height={'100%'} />
      </div>

      <div className='flex-1 flex-col gap-1'>
        <div className=''>
          <Skeleton count={1} width={'50%'} />
        </div>
        <div className=''>
          <Skeleton count={1} width={'100%'} />
          <Skeleton count={1} width={'90%'} />
        </div>
      </div>
    </div>
  );
}

export default memo(SkelPostItem);
