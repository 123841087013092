import { memo, useState } from 'react';

import produce from 'immer';
import { pick } from 'lodash';
import {
  HiMiniMagnifyingGlass,
  HiOutlineMagnifyingGlass,
} from 'react-icons/hi2';
import { IoMdClose } from 'react-icons/io';
import { MdArrowBack } from 'react-icons/md';
import { useModalManage } from '../../common/hooks/use-modal-manage';
import useSearchState, { FilterMap } from '../../common/hooks/use-search-state';
import Modal from '../../components/Modal';
import AquaticsSearchInput from '../../components/selector/AquaticsSearchInput';
import { isNotEmpty } from '../../utils/boolean';

function ModalPostSearch(props: {
  onClose: () => void;
  onFilter: (n: FilterMap) => void;
  searchMap: FilterMap;
}) {
  const { onFilter, searchMap: pMap, onClose } = props;

  const [localFilterMap, setLocalFilterMap] = useState(
    pick(pMap, ['keywords']) || {},
  );
  const hasFilterValues = isNotEmpty(localFilterMap);

  const { keywords } = localFilterMap || {};
  const keyword = keywords?.[0];

  const [
    {
      searchState: { keywordHistory },
    },
    { setSearchState },
  ] = useSearchState();

  useModalManage({
    modalKey: 'post-search',
    onBlocked: () => {
      onClose();
    },
  });

  function handleMapChange({
    nMap,
    submit,
  }: {
    nMap?: Partial<FilterMap>;
    submit?: boolean;
  }) {
    const nMapL1 = {
      ...localFilterMap,
      ...(nMap || {}),
    } as FilterMap;

    setLocalFilterMap(nMapL1);

    if (submit) {
      onFilter(nMapL1);
      onClose();
    }
  }

  return (
    <Modal open className='w-full lg:w-[500px] p-0'>
      <div className='flex-col h-full'>
        <div className='flex-none flex-center-y gap-2 py-4'>
          <div>
            <button
              className='btn btn-ghost'
              onClick={() => {
                onClose();
              }}
            >
              <MdArrowBack size={30} />
            </button>
          </div>
          <AquaticsSearchInput
            value={
              keyword ? { value: keyword || '', label: keyword || '' } : null
            }
            onChange={(o) => {
              setLocalFilterMap((st) =>
                produce(st, (dr) => {
                  if (o?.label) {
                    dr.keywords = [o?.label];
                  } else {
                    dr.keywords = [];
                  }
                }),
              );
            }}
          />

          <div>
            <button
              className='btn btn-ghost'
              onClick={() => {
                handleMapChange({ submit: true });
              }}
            >
              <HiOutlineMagnifyingGlass size={30} />
            </button>
          </div>
        </div>
        <div className='flex-1 flex-col gap-6 p-4 overflow-auto'>
          {hasFilterValues && (
            <div className='flex gap-3 justify-end'>
              <button
                className='btn btn-sm btn-outline'
                onClick={() => {
                  setLocalFilterMap({});
                }}
              >
                검색 초기화
              </button>
            </div>
          )}

          <div className='flex-col gap-3'>
            <label>최근 키워드</label>
            <ul className='flex gap-2 flex-wrap w-full'>
              {keywordHistory?.map((keywordH1, idx) => {
                return (
                  <li key={keywordH1}>
                    <button
                      className='btn px-3 py-1 border rounded-full flex-center gap-2'
                      onClick={() => {
                        setLocalFilterMap((st) =>
                          produce(st, (dr) => {
                            dr.keywords = [keywordH1];
                          }),
                        );
                      }}
                    >
                      <span className='text-base'>#{keywordH1}</span>
                      <span
                        className='opacity-50'
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();

                          setSearchState((st) =>
                            produce(st, (dr) => {
                              const nList = dr.keywordHistory || [];
                              nList.splice(idx, 1);

                              dr.keywordHistory = nList;
                            }),
                          );
                        }}
                      >
                        <IoMdClose size={21} />
                      </span>
                    </button>
                  </li>
                );
              })}

              {!isNotEmpty(keywordHistory) && <div className='px-4'>없음</div>}
            </ul>
          </div>

          <div className='flex-center'>
            <button
              className='btn btn-primary'
              onClick={() => {
                handleMapChange({ submit: true });
              }}
            >
              <HiMiniMagnifyingGlass className='text-white' /> 검색
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default memo(ModalPostSearch);
