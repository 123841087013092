import classNames from 'classnames';

import NotiButton from '../../modules/noti/NotiButton';
import Auth from '../../modules/users/Auth';
import NavLogo from './NavLogo';

function HomeNav() {
  return (
    <div
      className={classNames(
        'navbar border-b bg-base-100 max-w-2xl',
        'flex justify-between w-full fixed top-0 p-0 z-40 pointer-events-none border-b',
      )}
    >
      <div className='flex-1 flex justify-center gap-1 px-2 pointer-events-auto'>
        <div className='flex-1'>
          <NavLogo />
        </div>

        <span className='relative items-center flex'>
          <NotiButton />
          <Auth />
        </span>
      </div>
    </div>
  );
}

export default HomeNav;
