import ReactDOM from 'react-dom/client';
import { RecoilRoot } from 'recoil';
import './libs/ga';

import 'react-toastify/dist/ReactToastify.css';
import './index.scss';
//
import './daisy-custom.scss';

import './libs/kakao';

import App from './app/App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <RecoilRoot>
    <App />
  </RecoilRoot>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
