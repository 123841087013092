import classNames from 'classnames';

interface Props {
  max?: number | null;
  value?: number | null;
}

export default function ScaleDots({ max = 5, value = 0 }: Props) {
  return (
    <div className='flex flex-row space-x-1'>
      {new Array(max).fill(null).map((_, idx) => {
        const selected = idx < (value || 0);
        return (
          <div
            key={idx}
            className={classNames(
              'w-3 h-3 rounded-full opacity-60',
              selected ? 'bg-accent' : 'bg-gray-500'
            )}
          ></div>
        );
      })}
    </div>
  );
}
