import { memo, useRef } from 'react';

import classNames from 'classnames';
import { SubmitHandler, useForm } from 'react-hook-form';
import appConfig from '../../app/appConfig';
import Textarea from '../../components/form/Textarea';
import LoadingIcon from '../../components/loading/LoadingIcon';
import {
  CommentInput,
  useCreateCommentMutation,
} from '../../generated/graphql';
import { onError } from '../../utils/apollo-utils';
import useAuth from '../auth/use-auth';
import { initiateLogin } from '../users/utils/auth-actions';
import { addNewComment } from './comment-caches';
import { getTopCommentQueryParams } from './comment-params';

type Inputs = CommentInput & {
  creatorId: string;
  private: boolean;
};

function CommentForm({
  defaultPrivate,
  targetId,
}: {
  defaultPrivate?: boolean;
  targetId: string;
}) {
  const [{ user, isLoggedIn }] = useAuth();

  const ref = useRef<HTMLFormElement>(null);

  const { register, handleSubmit, reset, watch } = useForm<Inputs>({
    defaultValues: {
      creator: user?.id,
      targetId,
      private: defaultPrivate || false,
    },
  });

  const content = watch('content');

  const [createComment, { loading: creatingComments }] =
    useCreateCommentMutation({
      onError,
      onCompleted: () => {
        reset();
      },
      update(cache, { data: result }) {
        const variables = getTopCommentQueryParams({
          targetId,
        });

        // adding it to creator list
        addNewComment({
          cache,
          newData: result?.createComment?.data,
          variables,
        });
      },
    });

  const onSubmit: SubmitHandler<Inputs> = async (values) => {
    if (!isLoggedIn) {
      initiateLogin();
      return;
    }

    if (creatingComments) {
      return;
    }

    createComment({
      variables: {
        data: {
          ...values,
          creator: user?.id,
        },
      },
    });
  };

  return (
    <form
      ref={ref}
      onSubmit={handleSubmit(onSubmit)}
      className='pr-[15px] lg:pr-[30px]'
    >
      {!creatingComments ? (
        <div className='flex flex-col border rounded-sm card'>
          <Textarea
            placeholder='요기가 댓글 쓰는 곳'
            className='textarea border-0 outline-none focus:outline-none resize-none min-h-3 leading-5 pt-[16px] px-[16px] pb-0'
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                const isCmdEnter = e.metaKey && !e.ctrlKey; // Cmd+Enter on Mac
                const isCtrlEnter = !e.metaKey && e.ctrlKey; // Ctrl+Enter on Windows/Linux

                if (isCmdEnter || isCtrlEnter) {
                  e.preventDefault();
                  ref.current?.dispatchEvent(
                    new Event('submit', { cancelable: true, bubbles: true }),
                  );
                }
              }
            }}
            {...register('content', {
              required: {
                value: true,
                message: 'required',
              },
            })}
            maxLength={appConfig.comment.maxLength}
          />
          <div className='text-right bg-base-100 flex flex-center gap-2 justify-end'>
            <div className='flex-c gap-1'>
              <input
                id='private'
                type='checkbox'
                className='toggle toggle-xs'
                {...register('private')}
              />
              <label htmlFor='private' className='cursor-pointer text-sm'>
                비밀
              </label>
            </div>
            <button
              className={classNames('btn btn-sm btn-ghost font-bold ', {
                'opacity-30': !content,
              })}
              type='submit'
            >
              등록
            </button>
          </div>
        </div>
      ) : (
        <div className='flex-col py-2 flex-center'>
          <LoadingIcon />
        </div>
      )}
    </form>
  );
}

export default memo(CommentForm);
