/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ApolloClient,
  ApolloLink,
  from,
  // createHttpLink,
  fromPromise,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';
import produce from 'immer';
import { cloneDeep, forEach, uniqBy } from 'lodash';
import envs from '../common/envs';
import {
  CommentFragment,
  CommentFragmentDoc,
  NotiFragment,
  NotiFragmentDoc,
  PostFragment,
  PostFragmentDoc,
  RefreshTokenDocument,
  UserFragment,
  UserFragmentDoc,
} from '../generated/graphql';

const httpLink = createUploadLink({
  uri: envs.BackendUrl,
  fetchOptions: {
    credentials: 'include',
  },
});

const errorLinkRefreshToken = onError(() => {
  // eslint-disable-next-line
  console.log('error handle for refresh token');

  // refresh token error
  window.location.href = '/';
});

export function createClient() {
  const client = new ApolloClient<NormalizedCacheObject>({
    link: from([errorLinkRefreshToken, httpLink] as ApolloLink[]),
    cache: new InMemoryCache(),
  });

  return client;
}

let accessToken: undefined | string | null = null;

export function setAccessToken(value: typeof accessToken) {
  accessToken = value;
}

export function getAccessToken() {
  return accessToken;
}

const errorLink = onError(
  // eslint-disable-next-line
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) =>
        // eslint-disable-next-line
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
        ),
      );
    }

    if (networkError) {
      // @ts-ignore
      const { statusCode } = networkError;

      if (statusCode === 401) {
        const client = createClient();
        return fromPromise(
          client
            .mutate({
              mutation: RefreshTokenDocument,
            })
            .then((r) => {
              setAccessToken(r.data.refreshToken?.jwt);
              return forward(operation);
            }),
        ).flatMap(() => {
          return forward(operation);
        });
      }

      // eslint-disable-next-line
      console.log(`[Network error]: ${networkError}`);
    }

    return undefined;
  },
);

const createAuthMiddleware = () => {
  return new ApolloLink((operation, forward) => {
    const at = getAccessToken();
    // add the authorization to the headers
    operation.setContext(({ headers = {} }) => {
      return {
        headers: {
          ...headers,
          ...(at
            ? {
                authorization: `Bearer ${at}`,
              }
            : null),
        },
      };
    });

    return forward(operation);
  });
};

export function useClient() {
  const client = new ApolloClient<NormalizedCacheObject>({
    link: from([errorLink, createAuthMiddleware(), httpLink] as ApolloLink[]),
    cache: new InMemoryCache({
      typePolicies: {
        AquaticEntity: {
          merge: true,
          fields: {
            attributes: {
              merge(existing, incoming, { mergeObjects }) {
                return mergeObjects(existing, incoming);
              },
            },
          },
        },
        NotiEntity: {
          fields: {
            attributes: {
              merge(existing, incoming, { mergeObjects }) {
                return mergeObjects(existing, incoming);
              },
            },
          },
        },
        CommentEntity: {
          fields: {
            attributes: {
              merge(existing, incoming, { mergeObjects }) {
                return mergeObjects(existing, incoming);
              },
            },
          },
        },
        PostEntity: {
          fields: {
            attributes: {
              merge(existing, incoming, { mergeObjects }) {
                return mergeObjects(existing, incoming);
              },
            },
          },
        },

        UsersPermissionsUserEntity: {
          fields: {
            attributes: {
              merge(existing, incoming, { mergeObjects }) {
                return mergeObjects(existing, incoming);
              },
            },
          },
        },
        TradeEntity: {
          fields: {
            attributes: {
              merge(existing, incoming, { mergeObjects }) {
                return mergeObjects(existing, incoming);
              },
            },
            post: {
              merge: true,
            },
          },
        },

        Trade: {
          fields: {
            post: {
              merge: true,
            },
          },
        },
        Query: {
          fields: {
            aquatics: {
              keyArgs: ['filters', 'sort'],
              // https://stackoverflow.com/a/66613465/2330799
              // merge(existing, incoming, { mergeObjects }) {
              //   return mergeObjects(existing, incoming);
              // },
              merge(existing, incoming) {
                // load more and appending to the cached list
                const data = uniqBy(
                  [...(existing?.data || []), ...(incoming?.data || [])],
                  (i) => i.__ref,
                );

                return {
                  ...existing,
                  data,
                };
              },
            },
            trades: {
              keyArgs: ['filters', 'sort'],
              merge(existing, incoming) {
                const data = uniqBy(
                  [...(incoming?.data || []), ...(existing?.data || [])],
                  (i) => i.__ref,
                );

                // console.log('trades. query cache data', data);

                return {
                  ...existing,
                  meta: incoming?.meta,
                  data,
                };
              },
            },
            notisV2: {
              keyArgs: ['filters', 'sort'],
              merge(existing, incoming, { cache }) {
                // if (existing !== 'aaa') {
                //   return existing;
                // }

                try {
                  const commentsById = {} as any;
                  const postsById = {} as any;
                  const usersById = {} as any;

                  forEach(incoming?.comments?.data || [], (item) => {
                    const commentRef = item.__ref;
                    const commentFrag = cache.readFragment<CommentFragment>({
                      id: commentRef,
                      returnPartialData: true,
                      fragment: CommentFragmentDoc,
                      fragmentName: 'Comment',
                    });

                    if (commentFrag?.id) {
                      commentsById[commentFrag?.id] = commentFrag;
                    }
                  });

                  forEach(incoming?.posts?.data || [], (item) => {
                    const postRef = item.__ref;
                    const postFrag = cache.readFragment<PostFragment>({
                      id: postRef,
                      returnPartialData: true,
                      fragment: PostFragmentDoc,
                      fragmentName: 'Post',
                    });

                    // console.log('postFrag', postFrag);

                    if (postFrag?.id) {
                      postsById[postFrag.id] = {
                        ...postFrag,
                        attributes: {
                          ...postFrag.attributes,
                          trades: null,
                          MyLikes: null,
                          images: null,
                          postSocial: {
                            data: undefined,
                          },
                          creator: {
                            data: undefined,
                          },
                          aquatic: {
                            data: undefined,
                          },
                        },
                      };
                      // console.log(
                      //   'postsById[postFrag?.id]',
                      //   postsById[postFrag?.id],
                      // );
                    }
                  });

                  forEach(incoming?.users?.data || [], (item) => {
                    const userRef = item.__ref;
                    const userFrag = cache.readFragment<UserFragment>({
                      id: userRef,
                      returnPartialData: true,
                      fragment: UserFragmentDoc,
                      fragmentName: 'User',
                    });

                    if (userFrag?.id) {
                      usersById[userFrag?.id] = userFrag;
                    }
                  });

                  // console.log('postsById', postsById);
                  // console.log('usersById', usersById);

                  // consolidating
                  forEach(incoming?.notis?.data || [], (item) => {
                    const notiRef = item.__ref;
                    const notiFrag = cache.readFragment<NotiFragment>({
                      id: notiRef,
                      returnPartialData: true,
                      fragment: NotiFragmentDoc,
                      fragmentName: 'Noti',
                    });

                    if (notiFrag?.id) {
                      // consolidate noti with comment, post, user
                      const nNoti = cloneDeep(notiFrag);
                      const commentId = notiFrag.attributes?.meta?.commentId;
                      const postId = notiFrag.attributes?.meta?.postId;

                      const commentFrag: CommentFragment | undefined =
                        cloneDeep(commentsById[commentId]);

                      // console.log('commentFrag', commentFrag);

                      if (commentFrag) {
                        const commentCreatorId =
                          commentFrag.attributes?.meta?.creatorId;
                        const commentReplyToUserId =
                          commentFrag.attributes?.meta?.replyToUserId;

                        if (commentFrag.attributes) {
                          const creator = usersById[commentCreatorId];

                          const replyToUser = usersById[commentReplyToUserId];

                          if (replyToUser) {
                            commentFrag.attributes.replyToUser = {
                              data: replyToUser,
                            };
                          } else {
                            commentFrag.attributes.replyToUser = null;
                          }
                          if (creator) {
                            commentFrag.attributes.creator = {
                              data: creator,
                            };
                          } else {
                            commentFrag.attributes.creator = null;
                          }
                          commentFrag.attributes.images = null;
                          commentFrag.attributes.commentTo = null;
                          commentFrag.attributes.MyLikes = null;
                        }

                        // console.log('WRITING commentFrag', commentFrag);

                        // update replyToUser and creator
                        try {
                          cache.writeFragment<CommentFragment>({
                            id: `CommentEntity:${commentFrag.id}`,
                            fragment: CommentFragmentDoc,
                            fragmentName: 'Comment',
                            data: commentFrag,
                          });
                        } catch (error) {
                          // eslint-disable-next-line
                          console.log('comment frag write error', error);
                        }

                        nNoti.attributes!.comment = {
                          data: commentFrag,
                        };
                      }

                      const postFrag = postsById[postId];
                      // console.log('postFrag', postFrag);

                      if (postFrag) {
                        nNoti.attributes!.post = {
                          data: postFrag,
                        };
                      }

                      // console.log('nNoti', nNoti);

                      if (nNoti) {
                        try {
                          cache.writeFragment<NotiFragment>({
                            id: notiRef,
                            fragment: NotiFragmentDoc,
                            fragmentName: 'Noti',
                            data: nNoti,
                          });
                        } catch (error) {
                          // eslint-disable-next-line
                          console.log('frag write error', error, nNoti);
                        }
                      }

                      // console.log('WRITING nNoti', nNoti);
                    }
                  });

                  // console.log('incoming', incoming);

                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  const nState = produce(existing, (dr: any) => {
                    if (!dr) {
                      dr = {};
                    }
                    if (!dr.notis) dr.notis = { data: [] };
                    if (!dr.comments) dr.comments = { data: [] };
                    if (!dr.posts) dr.posts = { data: [] };
                    if (!dr.users) dr.users = { data: [] };

                    dr.notis.data = uniqBy(
                      [
                        ...(existing?.notis?.data || []),
                        ...(incoming?.notis?.data || []),
                      ],
                      (i) => i.__ref,
                    );
                    dr.comments.data = uniqBy(
                      [
                        ...(existing?.comments?.data || []),
                        ...(incoming?.comments?.data || []),
                      ],
                      (i) => i.__ref,
                    );
                    dr.posts.data = uniqBy(
                      [
                        ...(existing?.posts?.data || []),
                        ...(incoming?.posts?.data || []),
                      ],
                      (i) => i.__ref,
                    );
                    dr.users.data = uniqBy(
                      [
                        ...(existing?.users?.data || []),
                        ...(incoming?.users?.data || []),
                      ],
                      (i) => i.__ref,
                    );
                    return dr;
                  });

                  // console.log('nState', nState);

                  return nState;
                } catch (error) {
                  // eslint-disable-next-line
                  console.log('incoming processing error', error);
                }

                return existing;
              },
            },
            notis: {
              keyArgs: ['filters', 'sort'],
              merge(existing, incoming) {
                const data = uniqBy(
                  [
                    //
                    ...(existing?.data || []),
                    ...(incoming?.data || []),
                  ],
                  (i) => i.__ref,
                );

                console.log('data', data);

                return {
                  ...existing,
                  meta: incoming?.meta,
                  data,
                };
              },
            },
            // adding incoming at the end for main post board
            posts: {
              keyArgs: ['filters', 'sort'],
              merge(existing, incoming) {
                const data = uniqBy(
                  [...(existing?.data || []), ...(incoming?.data || [])],
                  (i) => i.__ref,
                );

                return {
                  ...existing,
                  meta: incoming?.meta,
                  data,
                };
              },
            },
            usersPermissionsUsers: {
              keyArgs: ['filters', 'sort'],
              merge(existing, incoming) {
                const comments = uniqBy(
                  [...(existing?.data || []), ...(incoming?.data || [])],
                  (i) => i.__ref,
                );

                return {
                  ...existing,
                  data: comments,
                };
              },
            },
            comments: {
              keyArgs: ['filters', 'sort'],
              merge(existing, incoming) {
                const comments = uniqBy(
                  [...(existing?.data || []), ...(incoming?.data || [])],
                  (i) => i.__ref,
                );

                return {
                  ...existing,
                  data: comments,
                };
              },
            },
          },
        },
      },
    }),
  });

  return client;
}
