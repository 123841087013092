import { HTMLAttributes } from 'react';
import { UserFragment } from '../../generated/graphql';
import Profile from './Profile';

import { consolidateUser, User } from './type';

export default function ProfileUser({
  user: userUnknown,
  viewOnly,
  noImage,
  thumb,
  blocked,
}: {
  user?: UserFragment | User | null;
  viewOnly?: boolean;
  noImage?: boolean;
  thumb?: boolean;
  blocked?: boolean;
} & HTMLAttributes<HTMLDivElement>) {
  const user = consolidateUser(userUnknown);

  if (thumb) {
    return <div className='text whitespace-nowrap'>{user?.displayName}</div>;
  }

  return (
    <Profile
      blocked={blocked}
      viewOnly={viewOnly}
      noImage={noImage}
      key={user?.profileUrl}
      img={user?.profileUrl}
      name={user?.displayName}
      subtitle={!user?.username || thumb ? undefined : `@${user?.username}`}
      to={user?.username ? `/profile/${user?.username}` : undefined}
    />
  );
}
