import { keys, startsWith } from 'lodash';
import { useLocation } from 'react-router';
import AquaticSearchNav from './nav/AquaticSearchNav';
import HomeNav from './nav/HomeNav';
import PostSearchNav from './nav/PostSearchNav';
import TradeSearchNav from './nav/TradeSearchNav';

// eslint-disable-next-line
const navSearchMap = {
  aquaticSearch: {
    routes: ['/pet/aquatics'],
  },
  postSearch: {
    routes: ['/posts'],
  },
  tradeSearch: {
    routes: ['/trades'],
  },
};

export type NavType = keyof typeof navSearchMap;

function GlobalNav() {
  const loc = useLocation();

  const types = keys(navSearchMap) as NavType[];
  const navSearchType = types.find((key: NavType) => {
    const sMap = navSearchMap[key];
    const routeFound = sMap.routes.find((route) => {
      return startsWith(loc.pathname, route);
    });

    if (routeFound) return key;

    return null;
  });

  if (navSearchType === 'postSearch') {
    return <PostSearchNav />;
  }

  if (navSearchType === 'tradeSearch') {
    return <TradeSearchNav />;
  }

  if (navSearchType === 'aquaticSearch') {
    return <AquaticSearchNav />;
  }

  // DEFAULT NAV
  return <HomeNav />;
}

export default GlobalNav;
