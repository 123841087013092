import { trim } from 'lodash';
import { useMemo } from 'react';
import { FormatsEnum } from '../../../types';
import {
  convertUploadToTImages,
  convToRender,
  PostCategory,
  TPost,
} from '../../../types/gql-enhanced-types';
import { notEmpty } from '../../../utils/array';
import { getSizedMediaLink } from '../../../utils/image';
import { formatPrice } from '../../../utils/number';

export type PostType = 'post' | 'trade' | 'social' | 'aquatic';

export function usePostData({ post }: { post?: TPost | null }) {
  const aquatic = useMemo(() => convToRender(post?.aquatic?.data), [post]);
  const social = useMemo(() => convToRender(post?.postSocial?.data), [post]);
  const trade = useMemo(() => convToRender(post?.trades?.data?.[0]), [post]);
  const tradeAquatic = useMemo(
    () => convToRender(trade?.aquatic?.data),
    [trade],
  );

  const postThumb = useMemo(() => {
    return (
      getSizedMediaLink({
        upload: post?.images?.data?.[0],
        size: FormatsEnum.small,
      }) || ''
    );
  }, [post]);
  const socialThumb = social?.thumbLink;
  const aquaticThumb = useMemo(() => {
    return (
      getSizedMediaLink({
        upload: aquatic?.images?.data?.[0],
        size: FormatsEnum.small,
      }) || ''
    );
  }, [post]);

  const title = tradeAquatic?.name || post?.title;

  const postType = useMemo(() => {
    return tradeAquatic
      ? 'trade'
      : social
      ? 'social'
      : aquatic
      ? 'opinion'
      : 'post';
  }, [post]);

  const images = useMemo(() => {
    if (postType === 'opinion') {
      return convertUploadToTImages([
        ...(post?.images?.data || []),
        // ...(aquatic?.images?.data || []),
      ]);
    }

    if (postType === 'trade') {
      return convertUploadToTImages([...(post?.images?.data || [])]);
    }

    return convertUploadToTImages([
      ...(post?.images?.data || []),
      ...(aquatic?.images?.data || []),
      // ...(social?.thumbLink ? [social?.thumbLink] : []),
    ]);
  }, [postType, post]);

  const uri = useMemo(() => {
    if (postType === 'opinion') {
      return postThumb;
    }

    if (postType === 'social') {
      return postThumb || socialThumb;
    }

    return socialThumb || aquaticThumb || postThumb;
  }, [postType, post]);

  const tags =
    useMemo(() => {
      return (post?.tags || '')
        .split(',')
        .filter((i) => notEmpty(i) && i !== '-')
        .map(trim);
    }, [post?.tags]) || [];

  const category = post?.category as PostCategory;

  const shareSummery = useMemo(() => {
    if (trade) {
      return (
        `⚡ ${title}, ` +
        `${formatPrice(trade.price)} x ${trade.quantity} (총 ${formatPrice(
          (trade.quantity || 1) * (trade.price || 1),
        )})`
      );
    }
    return title ? `💬 ${title}` : undefined;
  }, [post?.id, trade?.id]);

  return {
    images,
    category,
    isTrade: category === 'trade',
    isSocial: category === 'social',
    social,
    postType,
    title,
    shareSummery,
    thumbnailUri: uri,
    trade,
    tags,
  } as const;
}
