import classNames from 'classnames';
import { memo } from 'react';
import { LuUserPlus } from 'react-icons/lu';
import { IconFollowCheck, IconUserMinus } from '../../components/Icons';
import { UserFragment } from '../../generated/graphql';
import { User } from '../users/type';
import { initiateLogin } from '../users/utils/auth-actions';
import './follow-button.scss';
import { useFollow } from './use-follow';

function FollowButton({
  user: userUnknown,
}: {
  user?: UserFragment | User | null;
}) {
  const [
    { isLoggedIn, itIsMyself, followId, auth, user, iamFollowing },
    { createFollow, deleteFollow },
  ] = useFollow({ user: userUnknown });

  if (itIsMyself) {
    return null;
  }

  return (
    <span className='flex-none'>
      {iamFollowing ? (
        <button
          className='btn btn-md btn-secondary gap-2 follow-button'
          onClick={() => {
            if (!isLoggedIn) {
              initiateLogin();
              return;
            }

            if (followId) {
              deleteFollow({
                variables: {
                  id: followId,
                },
              });
            }
          }}
        >
          <span className='flex gap-2 hover-visible flex-nowrap'>
            <IconUserMinus />
            <span>팔로우취소</span>
          </span>
          <span className='flex gap-2 hover-hidden flex-nowrap'>
            <IconFollowCheck />
            <span>팔로잉</span>
          </span>
        </button>
      ) : (
        <button
          className={classNames('btn btn-accent btn-md gap-2')}
          onClick={() => {
            if (!isLoggedIn) {
              initiateLogin();
              return;
            }
            createFollow({
              variables: {
                data: {
                  user: auth?.id,
                  following: user?.id,
                },
              },
            });
          }}
        >
          <LuUserPlus className='text-current' />
          팔로우
        </button>
      )}
    </span>
  );
}

export default memo(FollowButton);
