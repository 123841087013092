/* eslint-disable @typescript-eslint/no-explicit-any */
import { format, formatDistanceStrict, isValid, parse } from 'date-fns';
import koLocale from 'date-fns/locale/ko';

export const SystemDateTimeMin = 'yyyy-MM-dd HH:mm';

export function dateDistance(dateStr?: string) {
  if (!dateStr) {
    return null;
  }

  return formatDistanceStrict(new Date(dateStr), new Date(), {
    addSuffix: true,
    locale: koLocale,
  });
}

export function parseDate(
  dateStr?: string | any | null,
  formatStr = 'yyyy-MM-dd HH:mm:ss',
  defaultValue = new Date(),
) {
  try {
    if (!dateStr) {
      return defaultValue;
    }

    const parsed = parse(dateStr, formatStr, defaultValue);

    if (isValid(parsed)) {
      return parsed;
    }

    return defaultValue;
  } catch (error) {
    // eslint-disable-next-line
    console.error('ignore parsing error', error);
  }

  return defaultValue;
}

export function formatDate(
  date?: Date | null,
  { formatStr = SystemDateTimeMin }: { formatStr?: string } = {},
) {
  if (date) {
    try {
      const d = format(date, formatStr);
      return d;
    } catch (error) {
      // eslint-disable-next-line
      console.error('error', error);
    }
  }

  return '';
}
