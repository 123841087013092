import { isArray, isEmpty, toLower, trim, uniq } from 'lodash';

export function createArray(len: number) {
  return new Array(len).fill(null);
}

export function strToArr(str?: string | null) {
  if (isArray(str)) return str;
  if (str == null) return [];

  return uniq(str.split('\n').map(trim));
}

export function splitChunks<T>(arr: T[], chunkSize: number) {
  const chunks = [];

  for (let i = 0; i < arr.length; i += chunkSize) {
    const chunk = arr.slice(i, i + chunkSize);
    // do whatever
    chunks.push(chunk);
  }

  return chunks;
}

export function notNull<T>(value: T | null | undefined): value is T {
  return value != null;
}

export function notEmpty<T>(value: T | null | undefined): value is T {
  if (typeof value === 'string') {
    return !isEmpty(trim(value));
  }

  return !isEmpty(value);
}

export function includeI(arr?: string[], item?: string) {
  if (!item || !arr) {
    return false;
  }
  return (arr || []).map(toLower).includes(toLower(item) || '');
}

export function equalI(item1: string, item2?: string) {
  return toLower(item1) === toLower(item2);
}

export function insertItemAtIndex<T>({
  array,
  item,
  index,
}: {
  array: T[];
  item: T;
  index: number;
}): T[] {
  const newArray = [...array];

  if (index < 0 || index > newArray.length) {
    // throw new Error('Index out of bounds');
    return array;
  }
  newArray.splice(index, 0, item);

  return newArray;
}

export function cutArrayInHalf<T>(arr: T[]): [T[], T[]] {
  const middleIndex = Math.floor(arr.length / 2);
  const firstHalf = arr.slice(0, middleIndex);
  const secondHalf = arr.slice(middleIndex);
  return [firstHalf, secondHalf];
}
