import { memo } from 'react';
import { IoMdNotifications, IoMdNotificationsOutline } from 'react-icons/io';
import { useNotisCountQuery } from '../../generated/graphql';
import useAuth from '../auth/use-auth';

function NotiButton() {
  const [{ isLoggedIn, userId }, { nav }] = useAuth();

  const { data } = useNotisCountQuery({
    skip: !userId,
    fetchPolicy: 'cache-and-network',
    variables: {
      filters: {
        to: { id: { eq: userId } },
        seen: { eq: false },
      },
    },
  });

  const notiCount = data?.notis?.meta.pagination.total || 0;

  if (!isLoggedIn) return null;

  return (
    <button
      className='btn btn-ghost px-2'
      onClick={() => {
        nav('/notifications');
      }}
    >
      {notiCount > 0 ? (
        <div className='indicator'>
          <span className='indicator-item badge badge-error badge-sm text-white'>
            {notiCount}
          </span>
          <IoMdNotifications size={24} />
        </div>
      ) : (
        <IoMdNotificationsOutline size={24} />
      )}
    </button>
  );
}

export default memo(NotiButton);
