import { marked } from 'marked';
import sanitizeHtml from 'sanitize-html';

// Options for the lib can be found here https://www.npmjs.com/package/sanitize-html
const options = {
  ...sanitizeHtml.defaults,
  // allowedTags: [],
  allowedAttributes: {
    '*': [
      'href',
      'align',
      'alt',
      'center',
      'width',
      'height',
      'type',
      'controls',
      'target',
    ],
    img: ['src', 'alt'],
    source: ['src', 'type'],
  },
};

export const postDescOption = {
  ...sanitizeHtml.defaults,
  allowedAttributes: {
    a: ['*'],
  },
  allowedTags: ['a', 'br'],
  allowedSchemes: ['https'],
};

export const clean = (dirty: string, opt = options) => sanitizeHtml(dirty, opt);

export const convertMdToHtml = (md: string) =>
  clean(
    marked(md, {
      gfm: true,
    })
  );
