import { HTMLAttributes } from 'react';
import classNames from 'classnames';

import Comment from '../assets/icons/Comment.svg';
import Share from '../assets/icons/Share.svg';
import SkipBack from '../assets/icons/SkipBack.svg';
import SkipForward from '../assets/icons/SkipForward.svg';
import StatePlus from '../assets/icons/State=Plus.svg';
import StateCheck from '../assets/icons/State=Check.svg';
import SignIn from '../assets/icons/SignIn.svg';
import HouseL from '../assets/icons/HouseL.svg';
import SignOut from '../assets/icons/SignOut.svg';
import Settings from '../assets/icons/Settings.svg';
import Duration from '../assets/icons/duration.svg';
import EmailUser from '../assets/icons/email-user.svg';
import EmailHeart from '../assets/icons/email-heart.svg';
import EmailChat from '../assets/icons/email-chat.svg';
import Profile from '../assets/icons/Profile.svg';
import UserPlusW from '../assets/icons/UserPlusW.svg';
import UserPlus from '../assets/icons/UserPlus.svg';
import Pen from '../assets/icons/Pen.svg';
import ArrowDown from '../assets/icons/ArrowDown.svg';
import UserGear from '../assets/icons/State=Gear.svg';
import Ghost from '../assets/icons/Ghost.svg';
import MagnifyingGlass from '../assets/icons/MagnifyingGlass.svg';
import MagnifyingGlassBlack from '../assets/icons/MagnifyingGlassBlack.svg';
import Microphone from '../assets/icons/Microphone.svg';

type IconProps = HTMLAttributes<HTMLImageElement>;
const StaticProps = { draggable: false };

const baseCls = 'svg-icon';

export function IconMicrophone({
  className,
  ...props
}: IconProps & HTMLAttributes<HTMLImageElement>) {
  return (
    <img
      src={Microphone}
      alt='follow'
      className={classNames(baseCls, className)}
      {...props}
    />
  );
}

export function IconMagnifyingGlass({ className }: IconProps) {
  return (
    <img src={MagnifyingGlass} alt='follow' className={classNames(className)} />
  );
}

export function IconMagnifyingGlassBlack({ className }: IconProps) {
  return (
    <img
      src={MagnifyingGlassBlack}
      alt='follow'
      className={classNames(className)}
    />
  );
}

export function IconGhost({ className }: IconProps) {
  return <img src={Ghost} alt='follow' className={classNames(className)} />;
}

export function IconUserGear({ className }: IconProps) {
  return <img src={UserGear} alt='follow' className={classNames(className)} />;
}

export function IconUserMinus({ className }: IconProps) {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='currentColor'
      xmlns='http://www.w3.org/2000/svg'
      className={classNames(className)}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.8761 12.3315C15.0861 13.0462 16.9537 14.5528 18.1199 16.5614C18.1749 16.6564 18.2039 16.7642 18.2039 16.8739C18.204 16.9837 18.1752 17.0915 18.1204 17.1866C18.0655 17.2817 17.9866 17.3607 17.8916 17.4156C17.7965 17.4705 17.6887 17.4994 17.5789 17.4994L2.42089 17.4992C2.31113 17.4992 2.20331 17.4703 2.10827 17.4154C2.01323 17.3605 1.93432 17.2815 1.87948 17.1865C1.82465 17.0914 1.79582 16.9835 1.7959 16.8738C1.79598 16.764 1.82496 16.6562 1.87993 16.5612C3.04614 14.5527 4.91374 13.0462 7.12357 12.3315C6.48689 11.9528 5.93129 11.452 5.48871 10.858C5.04614 10.2639 4.7253 9.5883 4.54463 8.86988C4.36396 8.15146 4.32703 7.40442 4.43594 6.67168C4.54486 5.93895 4.79749 5.23495 5.17931 4.60014C5.56112 3.96533 6.06461 3.41222 6.66084 2.97258C7.25707 2.53294 7.93429 2.21545 8.65359 2.03833C9.3729 1.86122 10.1201 1.82797 10.8523 1.94051C11.5845 2.05305 12.2872 2.30915 12.9202 2.6941C12.5406 2.88132 12.2356 3.19145 12.0547 3.57403C11.8738 3.95662 11.8277 4.38915 11.9239 4.80128C12.0201 5.2134 12.2528 5.58086 12.5844 5.84388C12.9159 6.1069 13.3267 6.25001 13.7499 6.24993H15.4831C15.7486 7.40907 15.6392 8.62258 15.1707 9.71555C14.7022 10.8085 13.8987 11.7245 12.8761 12.3315ZM17.4999 4.99994H13.7499C13.5841 4.99994 13.4251 4.93409 13.3079 4.81688C13.1907 4.69967 13.1249 4.5407 13.1249 4.37494C13.1249 4.20918 13.1907 4.05021 13.3079 3.933C13.4251 3.81579 13.5841 3.74994 13.7499 3.74994H17.4999C17.6656 3.74994 17.8246 3.81579 17.9418 3.933C18.059 4.05021 18.1249 4.20918 18.1249 4.37494C18.1249 4.5407 18.059 4.69967 17.9418 4.81688C17.8246 4.93409 17.6656 4.99994 17.4999 4.99994Z'
      />
    </svg>
  );
}

export function IconArrowDown({ className }: IconProps) {
  return <img src={ArrowDown} alt='follow' className={classNames(className)} />;
}

export function IconPen({ className }: IconProps) {
  return <img src={Pen} alt='follow' className={classNames(className)} />;
}

export function IconUserPlus({ className }: IconProps) {
  return <img src={UserPlus} alt='follow' className={classNames(className)} />;
}

export function IconUserPlusW({ className }: IconProps) {
  return <img src={UserPlusW} alt='follow' className={classNames(className)} />;
}

export function IconProfile({ className }: IconProps) {
  return <img src={Profile} alt='follow' className={classNames(className)} />;
}

export function IconDuration({ className, ...props }: IconProps) {
  return (
    <img
      src={Duration}
      alt='duration'
      className={classNames(className)}
      {...props}
    />
  );
}

export function IconEmailUser({ className, ...props }: IconProps) {
  return (
    <img
      src={EmailUser}
      alt='follows'
      className={classNames(className)}
      {...props}
    />
  );
}

export function IconEmailHeart({ className, ...props }: IconProps) {
  return (
    <img
      src={EmailHeart}
      alt='likes'
      className={classNames(className)}
      {...props}
    />
  );
}

export function IconEmailChat({ className, ...props }: IconProps) {
  return (
    <img
      src={EmailChat}
      alt='comments'
      className={classNames(className)}
      {...props}
    />
  );
}

export function IconSettings({ className }: IconProps) {
  return <img src={Settings} alt='follow' className={classNames(className)} />;
}

export function IconSignout({ className }: IconProps) {
  return <img src={SignOut} alt='follow' className={classNames(className)} />;
}

export function IconHouse({ className }: IconProps) {
  return <img src={HouseL} alt='follow' className={classNames(className)} />;
}

export function IconSignIn({ className }: IconProps) {
  return <img src={SignIn} alt='follow' className={classNames(className)} />;
}

export function IconPaused({ className }: IconProps) {
  return (
    <svg
      {...StaticProps}
      className={classNames(baseCls, className)}
      width='28'
      height='30'
      viewBox='0 0 28 30'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M27.75 2.5V27.5C27.7492 28.1628 27.4856 28.7983 27.0169 29.2669C26.5483 29.7356 25.9128 29.9992 25.25 30H19.625C18.9622 29.9992 18.3267 29.7356 17.8581 29.2669C17.3894 28.7983 17.1258 28.1628 17.125 27.5V2.5C17.1258 1.83719 17.3894 1.20175 17.8581 0.73307C18.3267 0.264394 18.9622 0.000758028 19.625 0H25.25C25.9128 0.000758028 26.5483 0.264394 27.0169 0.73307C27.4856 1.20175 27.7492 1.83719 27.75 2.5V2.5ZM8.375 0H2.75C2.08719 0.000758028 1.45175 0.264394 0.98307 0.73307C0.514394 1.20175 0.250758 1.83719 0.25 2.5V27.5C0.250758 28.1628 0.514394 28.7983 0.98307 29.2669C1.45175 29.7356 2.08719 29.9992 2.75 30H8.375C9.03781 29.9992 9.67325 29.7356 10.1419 29.2669C10.6106 28.7983 10.8742 28.1628 10.875 27.5V2.5C10.8742 1.83719 10.6106 1.20175 10.1419 0.73307C9.67325 0.264394 9.03781 0.000758028 8.375 0V0Z' />
    </svg>
  );
}

export function IconFollowAdd({ className }: IconProps) {
  return <img src={StatePlus} alt='follow' className={classNames(className)} />;
}

export function IconFollowCheck({ className }: IconProps) {
  return (
    <img src={StateCheck} alt='follow' className={classNames(className)} />
  );
}

export function IconSkipForward({ className }: IconProps) {
  return (
    <img
      src={SkipForward}
      alt='skip forward'
      className={classNames(className)}
    />
  );
}

export function IconSkipBack({ className }: IconProps) {
  return (
    <img src={SkipBack} alt='skip backward' className={classNames(className)} />
  );
}

export function IconComment() {
  return <img src={Comment} alt='Comment' />;
}

export function IconPaperPlane() {
  return <img src={Share} alt='Share' />;
}

export function IconFastForward({ className }: IconProps) {
  return (
    <svg
      width='29'
      height='28'
      viewBox='0 0 29 28'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={classNames(baseCls, className)}
      {...StaticProps}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M26.5556 14C26.5556 20.6581 21.1581 26.0556 14.5 26.0556C7.8419 26.0556 2.44444 20.6581 2.44444 14C2.44444 7.3419 7.8419 1.94444 14.5 1.94444C18.0075 1.94444 21.1651 3.44234 23.3681 5.83333H20.1389C19.6019 5.83333 19.1667 6.26861 19.1667 6.80556C19.1667 7.3425 19.6019 7.77778 20.1389 7.77778H25.1944C25.7314 7.77778 26.1667 7.3425 26.1667 6.80556V1.75C26.1667 1.21306 25.7314 0.777778 25.1944 0.777778C24.6575 0.777778 24.2222 1.21306 24.2222 1.75V3.92633C21.704 1.49538 18.2766 0 14.5 0C6.76801 0 0.5 6.26801 0.5 14C0.5 21.732 6.76801 28 14.5 28C22.232 28 28.5 21.732 28.5 14C28.5 13.5132 28.4752 13.0322 28.4267 12.5582C28.3738 12.0416 27.926 11.6667 27.4067 11.6667C26.8488 11.6667 26.4315 12.1764 26.4896 12.7311C26.5332 13.1481 26.5556 13.5715 26.5556 14ZM8.46201 16.7781V18.0001H13.441V16.7781H11.816V9.73206H10.698C10.438 9.88806 10.1563 10.0224 9.85301 10.1351C9.54967 10.2391 9.19434 10.3301 8.78701 10.4081V11.3441H10.308V16.7781H8.46201ZM16.102 18.0001C16.4487 18.1041 16.8474 18.1561 17.298 18.1561C17.8094 18.1561 18.286 18.0477 18.728 17.8311C19.17 17.6057 19.5254 17.2807 19.794 16.8561C20.0714 16.4314 20.21 15.9157 20.21 15.3091C20.21 14.7197 20.0887 14.2344 19.846 13.8531C19.612 13.4717 19.2957 13.1901 18.897 13.0081C18.507 12.8174 18.078 12.7221 17.61 12.7221C17.3934 12.7221 17.1984 12.7481 17.025 12.8001C16.8604 12.8434 16.6914 12.9084 16.518 12.9951L16.687 10.9931H19.807V9.73206H15.387L15.14 13.8141L15.855 14.2691C16.1064 14.1044 16.323 13.9874 16.505 13.9181C16.6957 13.8401 16.921 13.8011 17.181 13.8011C17.6317 13.8011 17.9957 13.9354 18.273 14.2041C18.559 14.4727 18.702 14.8541 18.702 15.3481C18.702 15.6774 18.6327 15.9634 18.494 16.2061C18.3554 16.4401 18.1647 16.6221 17.922 16.7521C17.688 16.8734 17.415 16.9341 17.103 16.9341C16.687 16.9341 16.323 16.8474 16.011 16.6741C15.699 16.4921 15.4174 16.2841 15.166 16.0501L14.477 17.0121C14.6764 17.2114 14.9104 17.4021 15.179 17.5841C15.4477 17.7574 15.7554 17.8961 16.102 18.0001Z'
        fill='#2E291F'
      />
    </svg>
  );
}

export function IconRewind({ className }: IconProps) {
  return (
    <svg
      className={classNames(baseCls, className)}
      {...StaticProps}
      width='29'
      height='28'
      viewBox='0 0 29 28'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2.44444 14C2.44444 20.6581 7.8419 26.0556 14.5 26.0556C21.1581 26.0556 26.5556 20.6581 26.5556 14C26.5556 7.3419 21.1581 1.94444 14.5 1.94444C10.9925 1.94444 7.83488 3.44234 5.63188 5.83333H8.86111C9.39805 5.83333 9.83333 6.26861 9.83333 6.80556C9.83333 7.3425 9.39805 7.77778 8.86111 7.77778H3.80556C3.26861 7.77778 2.83333 7.3425 2.83333 6.80556V1.75C2.83333 1.21306 3.26861 0.777778 3.80556 0.777778C4.3425 0.777778 4.77778 1.21306 4.77778 1.75V3.92633C7.29605 1.49538 10.7234 0 14.5 0C22.232 0 28.5 6.26801 28.5 14C28.5 21.732 22.232 28 14.5 28C6.76801 28 0.5 21.732 0.5 14C0.5 13.5132 0.524845 13.0322 0.573338 12.5582C0.626194 12.0416 1.07399 11.6667 1.59334 11.6667C2.15115 11.6667 2.56845 12.1764 2.51042 12.7311C2.4668 13.1481 2.44444 13.5715 2.44444 14ZM8.46201 16.7781V18.0001H13.441V16.7781H11.816V9.73206H10.698C10.438 9.88806 10.1563 10.0224 9.85301 10.1351C9.54967 10.2391 9.19434 10.3301 8.78701 10.4081V11.3441H10.308V16.7781H8.46201ZM16.102 18.0001C16.4487 18.1041 16.8474 18.1561 17.298 18.1561C17.8094 18.1561 18.286 18.0477 18.728 17.8311C19.17 17.6057 19.5254 17.2807 19.794 16.8561C20.0714 16.4314 20.21 15.9157 20.21 15.3091C20.21 14.7197 20.0887 14.2344 19.846 13.8531C19.612 13.4717 19.2957 13.1901 18.897 13.0081C18.507 12.8174 18.078 12.7221 17.61 12.7221C17.3934 12.7221 17.1984 12.7481 17.025 12.8001C16.8604 12.8434 16.6914 12.9084 16.518 12.9951L16.687 10.9931H19.807V9.73206H15.387L15.14 13.8141L15.855 14.2691C16.1064 14.1044 16.323 13.9874 16.505 13.9181C16.6957 13.8401 16.921 13.8011 17.181 13.8011C17.6317 13.8011 17.9957 13.9354 18.273 14.2041C18.559 14.4727 18.702 14.8541 18.702 15.3481C18.702 15.6774 18.6327 15.9634 18.494 16.2061C18.3554 16.4401 18.1647 16.6221 17.922 16.7521C17.688 16.8734 17.415 16.9341 17.103 16.9341C16.687 16.9341 16.323 16.8474 16.011 16.6741C15.699 16.4921 15.4174 16.2841 15.166 16.0501L14.477 17.0121C14.6764 17.2114 14.9104 17.4021 15.179 17.5841C15.4477 17.7574 15.7554 17.8961 16.102 18.0001Z'
      />
    </svg>
  );
}

export function IconPlay({ className }: IconProps) {
  return (
    <svg
      className={classNames(baseCls, className)}
      {...StaticProps}
      width='35'
      height='42'
      viewBox='0 0 35 42'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M32.8735 18.3327L4.75464 1.14829C4.28101 0.858661 3.73876 0.700457 3.1837 0.689952C2.62863 0.679447 2.08079 0.81702 1.59654 1.08852C1.11229 1.36001 0.709116 1.75563 0.42851 2.23466C0.147903 2.71369 -5.98024e-06 3.25883 1.81348e-10 3.814V38.1859C0.000451718 38.7409 0.148646 39.2858 0.429359 39.7645C0.710072 40.2433 1.11318 40.6387 1.59728 40.9101C2.08137 41.1815 2.62899 41.3192 3.18388 41.3089C3.73877 41.2986 4.28092 41.1408 4.75464 40.8517L32.8735 23.6672C33.3303 23.3879 33.7077 22.9959 33.9696 22.5289C34.2314 22.0618 34.3689 21.5354 34.3689 21C34.3689 20.4645 34.2314 19.9381 33.9696 19.4711C33.7077 19.004 33.3303 18.6121 32.8735 18.3327Z' />
    </svg>
  );
}

export function IconHeart({ className }: IconProps) {
  return (
    <svg
      className={classNames(baseCls, className)}
      {...StaticProps}
      width='20'
      height='20'
      viewBox='0 0 20 20'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M13.752 2.50001C13.0244 2.49876 12.3065 2.66731 11.6555 2.99226C11.0045 3.31722 10.4383 3.7896 10.002 4.37184C9.41099 3.58555 8.58772 3.005 7.64865 2.71235C6.70957 2.4197 5.70225 2.42977 4.76922 2.74114C3.83618 3.0525 3.02469 3.64938 2.44955 4.44733C1.87442 5.24529 1.56479 6.20389 1.56445 7.18751C1.56445 12.8018 9.36452 17.2344 9.69655 17.4203C9.78986 17.4726 9.89501 17.5 10.002 17.5C10.1089 17.5 10.214 17.4726 10.3074 17.4203C11.7419 16.5801 13.0884 15.5977 14.3263 14.488C17.0556 12.0316 18.4395 9.57539 18.4395 7.18751C18.438 5.94474 17.9437 4.75327 17.065 3.8745C16.1862 2.99573 14.9947 2.50142 13.752 2.50001Z' />
    </svg>
  );
}
