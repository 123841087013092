export const sizeOptions = [
  {
    label: '소형 (-5)',
    value: 'xs',
    min: 0,
    max: 5,
  },
  {
    label: '중형 (5-12)',
    value: 'md',
    min: 5,
    max: 12,
  },
  {
    label: '중대형 (12-20)',
    value: 'mdlg',
    min: 12,
    max: 20,
  },
  {
    label: '대형 (20-)',
    value: 'lg',
    min: 20,
  },
];
