import { WatchQueryFetchPolicy } from '@apollo/client';
import produce from 'immer';
import { orderBy, sample, size } from 'lodash';
import { useEffect } from 'react';
import { atom, useRecoilState } from 'recoil';
import appConfig from '../../../app/appConfig';
import { usePartnerPostsQuery } from '../../../generated/graphql';
import { persistAtom } from '../../../libs/recoil';
import { convList } from '../../../types/gql-enhanced-types';

const PartnerPostState = atom<{
  lastFetchedMs?: number;
  currRandomSample?: number | null;
}>({
  key: 'PartnerPostState',
  default: {
    lastFetchedMs: 0,
    currRandomSample: null,
  },
  effects: [persistAtom],
});

export function usePartnerPostState({
  fetchPolicy,
}: {
  fetchPolicy?: WatchQueryFetchPolicy;
} = {}) {
  const [partnerPostState, setPartnerPostState] =
    useRecoilState(PartnerPostState);

  const { lastFetchedMs, currRandomSample } = partnerPostState;

  const { refetch } = usePartnerPostsQuery({
    fetchPolicy: fetchPolicy || 'cache-only',
  });

  function resetRandomSample() {
    setPartnerPostState((st) =>
      produce(st, (dr) => {
        if (dr.currRandomSample == null) {
          dr.currRandomSample = sample([0, 1, 2]);
        } else {
          dr.currRandomSample = (dr.currRandomSample + 1) % 3;
        }
      }),
    );
  }

  function tryRefetchPP() {
    const diffMs = Date.now() - (lastFetchedMs || 0);

    // every 1 mins
    if (diffMs < appConfig.post.refetchPP) {
      // if (diffMs < 1000 * 3) {
      return;
    }

    refetch().then(() => {
      setPartnerPostState((st) =>
        produce(st, (dr) => {
          if (dr.currRandomSample == null) {
            dr.currRandomSample = sample([0, 1, 2]);
          } else {
            dr.currRandomSample = (dr.currRandomSample + 1) % 3;
          }

          dr.lastFetchedMs = Date.now();
        }),
      );
    });
  }

  useEffect(() => {
    if (currRandomSample == null) {
      resetRandomSample();
    }
  }, [currRandomSample]);

  return {
    partnerPostState,
    setPartnerPostState,
    resetRandomSample,
    tryRefetchPP,
  } as const;
}

export function usePartnerPosts({ numberFromTop }: { numberFromTop: number }) {
  const { partnerPostState } = usePartnerPostState();

  const { currRandomSample } = partnerPostState;

  const { data: ppData } = usePartnerPostsQuery({
    fetchPolicy: 'cache-only',
  });

  const pps = convList(ppData?.partnerPosts?.data);
  const sortedPps = orderBy(pps, ['partnerPostRank'], ['asc']);

  // console.log('pps', pps);
  // console.log('sortedPps', sortedPps);
  // const temp = sortedPps.map((i) => pick(i, ['id', 'partnerPostRank']));
  // console.log('temp', temp);

  const post =
    sortedPps?.[(numberFromTop + (currRandomSample || 0)) % size(sortedPps)];

  return {
    ppPost: post,
  } as const;
}
