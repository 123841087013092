import { Link } from 'react-router-dom';
import LogoCopy from '../../components/LogoCopy';

function NavLogo() {
  return (
    <Link to='/' className=''>
      <LogoCopy className='pl-2 h-10 py-1' />
    </Link>
  );
}

export default NavLogo;
