import classNames from 'classnames';
import produce from 'immer';
import { HiOutlineMagnifyingGlass } from 'react-icons/hi2';
import { IoMdCloseCircle } from 'react-icons/io';
import { RiFilter2Fill } from 'react-icons/ri';

import { first } from 'lodash';
import useSearchState from '../../common/hooks/use-search-state';
import NotiButton from '../../modules/noti/NotiButton';
import ModalPostSearch from './ModalPostSearch';
import NavLogo from './NavLogo';

function PostSearchNav() {
  const [
    { hasSearchKey, searchModalState, searchState },
    { setSearchModalState, setSearchState },
  ] = useSearchState();
  const { keywords } = searchState.searchMap;
  const keyword = first(keywords) || '';

  return (
    <div
      className={classNames(
        'navbar border-b bg-base-100 max-w-2xl',
        'flex justify-between w-full fixed top-0 p-0 z-40 pointer-events-none border-b',
      )}
    >
      <div className='flex-1 flex justify-center gap-1 px-2 pointer-events-auto'>
        {hasSearchKey ? (
          <div className='flex-1'>
            <div className='relative'>
              <input
                type='text'
                className='input h-[2.4rem] w-full rounded-full'
                value={keyword}
                placeholder='Search...'
                onChange={() => {}}
                onFocus={() => {
                  setSearchModalState({
                    openSearchModal: true,
                  });
                }}
              />
              <div
                className='absolute right-2 top-[6px] cursor-pointer'
                onClick={() => {
                  setSearchState((st) =>
                    produce(st, (dr) => {
                      dr.searchMap = {};
                    }),
                  );
                }}
              >
                <IoMdCloseCircle size={28} className='opacity-50' />
              </div>
            </div>
          </div>
        ) : (
          <div className='flex-1'>
            <NavLogo />
          </div>
        )}

        <span className='relative items-center flex'>
          <NotiButton />

          <button
            className='btn btn-ghost px-2'
            onClick={() => {
              setSearchModalState({
                openSearchModal: !searchModalState.openSearchModal,
              });
            }}
          >
            {hasSearchKey ? (
              <RiFilter2Fill size={24} className='text-primary' />
            ) : (
              <HiOutlineMagnifyingGlass size={24} />
            )}
          </button>
        </span>
      </div>

      {searchModalState.openSearchModal && (
        <ModalPostSearch
          searchMap={searchState.searchMap}
          onFilter={(nMap) => {
            setSearchState((st) =>
              produce(st, (dr) => {
                dr.searchMap = nMap;
              }),
            );
          }}
          onClose={() => {
            setSearchModalState({
              openSearchModal: false,
            });
          }}
        />
      )}
    </div>
  );
}

export default PostSearchNav;
