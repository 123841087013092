import {
  ApolloQueryResult,
  FetchMoreQueryOptions,
  OperationVariables,
} from '@apollo/client';
import { floor } from 'lodash';
import { useState } from 'react';

export function usePageLoadMore<TData>({
  loading,
  pageSize,
  fetchMore,
  listLength,
}: {
  loading?: boolean;
  pageSize: number;
  fetchMore: (
    options: FetchMoreQueryOptions<OperationVariables>,
  ) => Promise<ApolloQueryResult<TData>>;
  listLength: number;
}) {
  const [loadingMore, setLoadingMore] = useState(false);
  const initPage = floor(listLength / pageSize) || 1;
  const [page, setPage] = useState(initPage);

  async function loadMore() {
    if (!loading || !loadingMore) {
      setLoadingMore(true);

      const nPage = page + 1;

      await fetchMore({
        variables: {
          pagination: {
            page: nPage,
            pageSize,
          },
        },
      });

      setLoadingMore(false);

      setPage(nPage);
    }
  }

  const hasMore = page * pageSize <= listLength;

  return [
    { hasMore, page, loadingMore },
    { setPage, loadMore },
  ] as const;
}
