import classNames from 'classnames';
import { memo } from 'react';
import { useNavigate } from 'react-router';
import StickyHeader from '../../modules/post/StickyHeader';
import { H3 } from '../typographic/Headers';

import { MdChevronLeft } from 'react-icons/md';
import confusedFish from '../../assets/artwork/confused-fish.svg';

function DeletedPage() {
  const nav = useNavigate();

  return (
    <div
      className='flex flex-col flex-1'
      onKeyDown={(e) => {
        e.stopPropagation();
      }}
    >
      <StickyHeader>
        <div
          className={classNames(
            'navbar border-b bg-base-100 max-w-2xl',
            'flex justify-between w-full p-0 border-b gap-2',
          )}
        >
          <span
            className='px-2 cursor-pointer'
            onClick={() => {
              nav(-1);
            }}
          >
            <MdChevronLeft className='' size={24} />
          </span>
          <H3
            className='flex-1 flex flex-center-y gap-3 '
            onClick={() => {
              nav(-1);
            }}
          ></H3>
          <div className='flex-none'></div>
        </div>
      </StickyHeader>

      <div className='flex-1 flex-center flex-col gap-3'>
        <div>
          <img src={confusedFish} alt='confused fish' className='w-24' />
        </div>
        <div>삭제되었습니다.</div>
        <div>
          <button
            className='btn btn-ghost'
            onClick={() => {
              nav(-1);
            }}
          >
            뒤로가기
          </button>
        </div>

        <div className='h-20'></div>
      </div>
    </div>
  );
}

export default memo(DeletedPage);
